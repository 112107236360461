import React, { useEffect, useState } from 'react';
import { Card, Button, Form, Row, Col } from 'react-bootstrap';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { Korean } from 'flatpickr/dist/l10n/ko.js';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter_paging';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination_paging';
import data from 'safedb/SA/sampledata/sampledataLogAdmin';
import CheckOutModal from 'components/modal/CheckOutModal';
import { fetchManageLog } from 'hooks/CallApi';
import { formatDate_log } from 'components/common/SunnyUtils';


const columns = [
  { accessorKey: 'num', header: '순번', meta: { headerProps: { className: 'text-900' } }, enableSorting: false },
  { accessorKey: 'jobdate', header: '작업일시', meta: { maxlength: 19, headerProps: { className: 'text-900' } } },
  // { accessorKey: 'org', header: '소속', meta: { headerProps: { className: 'text-900' } } },
  { accessorKey: 'Drole', header: '역할', meta: { maxlength: 7, headerProps: { className: 'text-900' } } },
  { accessorKey: 'uid', header: '아이디', meta: { maxlength: 20, headerProps: { className: 'text-900' } } },
  { accessorKey: 'job', header: '작업내역', meta: { maxlength: 20, headerProps: { className: 'text-900' } } },
  { accessorKey: 'result', header: '결과', meta: { headerProps: { className: 'text-900' } } }

];

function AdvanceTableExample() {
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [requestDateRange, setRequestDateRange] = useState([]);
  const [policyId, setPolicyId] = useState('');
  const [org, setOrg] = useState('');
  const [role, setRole] = useState('');
  const [uid, setUid] = useState('');
  const [job, setJob] = useState('');

  const [result, setResult] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalReShow, setModalReShow] = useState(false);
  const [roles, setRoles] = useState([]); // 역할 데이터 상태 추가.
  const roleMapping = {
    '시스템 관리자': 'SA',
    '최고 관리자': 'ADMIN',
    '운영 관리자': 'MANAGER',
  };
  const [roleOptions, setRoleOptions] = useState(Object.keys(roleMapping)); // 셀렉트 박스에 표시할 역할 목록


  const [totalElements, setTotalElements] = useState(0);
  const [currentPage, setCurrentPage] = useState(0); // 현재 페이지
  const [perPage, setPerPage] = useState(10); // 페이지당 항목 수 (size)

  const table = useAdvanceTable({
    data: filteredData,
    columns,
    sortable: true,
    selection: false,
    pagination: true,
    perPage,
    selectionColumnWidth: 30
  });

  // API 호출 함수
  const loadData = async (page, size) => {
    console.log("Fetching data with page:", page, "and size:", size);

    try {
      const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      // const response = await fetchManageLog(page, size); // API 호출
      const response = await fetchManageLog({
        page,
        size,
        searchStartTime: requestDateRange[0] ? requestDateRange[0].toISOString() : null, // ISO 형식
        searchEndTime: requestDateRange[1] ? requestDateRange[1].toISOString() : null,  // ISO 형식
        timeZone: clientTimeZone,
        userId: uid || null,
        userRole: role || null,
        taskHistory: job || null,
        result: result || null,
        insertTime: 'desc'

      });

      const logData = response.data.content.map((item, index) => ({
        // num: index + 1 + page * size, // 순번 계산
        num: response.data.page.totalElements - (page * size + index), // 역순으로 순번 계산
        jobdate: item.insertTime
          ? formatDate_log(item.insertTime) // 포맷 함수 호출
          : '-',
        role: item.userRole,
        Drole: item.userRole === 'SA' ? '시스템 관리자' : item.userRole === 'ADMIN' ? '최고 관리자' : '운영 관리자',

        uid: item.userId,
        job: item.taskHistory,
        result: ['성공', '클라이언트 에러', '서버 에러', '기타'][item.result] || '기타',
      }));
      setTotalElements(response.data.page.totalElements);
      setTableData(logData);
      setFilteredData(logData);

      // 역할 데이터 추출 및 중복 제거
      const uniqueRoles = [...new Set(response.data.content.map(item => item.userRole))];
      setRoles(uniqueRoles);

    } catch (error) {
      console.error('API 데이터 로드 에러:', error);
    }
  };



  // 페이지 변경 시 데이터 다시 로드
  useEffect(() => {
    console.log("Current page or perPage changed:", currentPage, perPage);
    loadData(currentPage, perPage);
  }, [currentPage, perPage]);


  const handlePageChange = async (newPage) => {
    setCurrentPage(newPage);
    table.resetRowSelection(); // 선택된 행 초기화
    try {
      await loadData(newPage, perPage);
    } catch (error) {
      console.error('페이지 변경 중 에러 발생:', error);
    }
  };
  const handlePerPageChange = (newPerPage) => {
    console.log("Changing perPage to:", newPerPage);
    setPerPage(newPerPage); // 페이지 크기 업데이트
    setCurrentPage(0); // 첫 페이지로 이동
    loadData(0, newPerPage); // 첫 페이지 데이터 다시 로드
  };

  const handleSearch = async () => {
    const [startDate, endDate] = requestDateRange;

    if (startDate && endDate && startDate > endDate) {
      alert('시작 날짜는 종료 날짜 이전이어야 합니다.');
      return;
    }
    try {
      // API 호출
      const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const formatToLocalISO = (date) => {
        if (!date) return null;
        const offset = date.getTimezoneOffset() * 60000; // 분 단위 오프셋을 밀리초로 변환
        const localISO = new Date(date.getTime() - offset).toISOString().slice(0, -1); // UTC로 보정 후 'Z' 제거
        return localISO;
      };
      const response = await fetchManageLog({
        page: 0, // 검색 시 첫 페이지부터
        size: perPage,
        searchStartTime: startDate ? formatToLocalISO(startDate) : null,
        searchEndTime: endDate ? formatToLocalISO(endDate) : null,
        timeZone: clientTimeZone,

        userId: uid || null,
        // userRole: role || null,
        taskHistory: job || null,
        userRole: role ? roleMapping[role] : null, // 한글 역할을 영어로 변환
        result: result || null,
        insertTime: 'desc',
        order_userId: 'asc'
      });
      console.log("logmanage response", response);
      const logData = response.data.content.map((item, index) => ({
        num: response.data.page.totalElements - index, // 역순 순번 계산
        jobdate: item.insertTime ? formatDate_log(item.insertTime) : '-',
        role: item.userRole,
        Drole: item.userRole === 'SA' ? '시스템 관리자' : item.userRole === 'ADMIN' ? '최고 관리자' : '운영 관리자',
        uid: item.userId,
        job: item.taskHistory,
        result: ['성공', '클라이언트 에러', '서버 에러', '기타'][item.result] || '기타',
      }));

      setTotalElements(response.data.page.totalElements);
      setTableData(logData);
      setFilteredData(logData);
    } catch (error) {
      console.error('검색 중 에러 발생:', error);
    }
  };


  const handleDefault = () => {
    setRequestDateRange([]);
    setOrg('');
    setRole('');
    setUid('');
    setJob('');
    setResult('');
    const datepicker_start = document.querySelector('#timepicker_start')._flatpickr;
    if (datepicker_start) {
      datepicker_start.clear();  // 기존 날짜 선택 제거
      datepicker_start.setDate([]);  // 날짜 범위 초기화
    }
    const datepicker_end = document.querySelector('#timepicker_end')._flatpickr;
    if (datepicker_end) {
      datepicker_end.clear();  // 기존 날짜 선택 제거
      datepicker_end.setDate([]);  // 날짜 범위 초기화
    }
  };

  useEffect(() => {
    const todayStart = new Date();
    todayStart.setHours(0, 0, 0, 0); // 오늘 00시 00분 00초

    const todayEnd = new Date();
    todayEnd.setHours(23, 59, 59, 999); // 오늘 23시 59분 59초

    flatpickr('#timepicker_start', {
      mode: 'single',
      dateFormat: 'Y-m-d H:i',
      enableTime: true,
      time_24hr: true,
      disableMobile: true,
      position: 'below',
      locale: Korean,
      minDate: new Date().fp_incr(-180),
      // defaultDate: todayStart,
      // onChange: (selectedDates) => setRequestDateRange(selectedDates)
      onChange: (selectedDates) => {
        console.log("Start Date Selected:", selectedDates[0]);
        setRequestDateRange((prev) => [selectedDates[0], prev[1] || null]);
      },
    });

    flatpickr('#timepicker_end', {
      mode: 'single',
      dateFormat: 'Y-m-d H:i',
      enableTime: true,
      time_24hr: true,
      disableMobile: true,
      position: 'below',
      locale: Korean,
      minDate: new Date().fp_incr(-180),
      // defaultDate: todayEnd,
      // onChange: (selectedDates) => setRequestDateRange(selectedDates)
      onChange: (selectedDates) => {
        console.log("End Date Selected:", selectedDates[0]);
        setRequestDateRange((prev) => [prev[0] || null, selectedDates[0]]);
      },
    });
  }, []);


  return (
    <AdvanceTableProvider {...table}>
      <div className="d-flex flex-column flex-md-row align-items-center mb-1" style={{ gap: '1px' }}>
        {/* 전체 레코드 수와 페이지네이션 셀렉트 */}
        <div className="d-flex align-items-center">
          {/* <AdvanceTableFooter rowsPerPageSelection /> */}
          <AdvanceTableFooter
            rowsPerPageSelection
            onPageChange={(page, newPageSize) => {
              handlePerPageChange(newPageSize); // 페이지 크기 변경
              setCurrentPage(page); // 현재 페이지 설정
            }}
          />
        </div>
        {/* 검색 필터 */}
        <Row className="w-100 align-items-center">
          <Col xxl={2} xl={2} className="mb-4 ps-4 fs-10">
            <label htmlFor="timepicker_start" className="form-label"> &nbsp;</label>

            <input
              id="timepicker_start"
              className="form-control datetimepicker"
              type="text"
              placeholder="2024-01-01 00:00"
              title="작업일시"
              style={{ height: '29px', fontSize: '1rem' }}
            />
          </Col>
          <Col xxl={2} xl={2} className="mb-4 ps-4 fs-10">
            <label htmlFor="timepicker_end" className="form-label"> &nbsp;</label>

            <input
              id="timepicker_end"
              className="form-control datetimepicker"
              type="text"
              placeholder="2024-12-31 00:00"
              title="작업일시"
              style={{ height: '29px', fontSize: '1rem' }}
            />
          </Col>

          <Col xxl={2} xl={2} className="mb-4 fs-10 px-1 ">
            <Form.Label>&nbsp;</Form.Label>
            <Form.Select size="sm" value={role} title="역할" onChange={e => setRole(e.target.value)} style={{ height: '29px' }}>
              <option value="" disabled hidden >역할</option>
              {roleOptions.map((roleName, index) => (
                <option key={index} value={roleName}>{roleName}</option>
              ))}
            </Form.Select>
          </Col>
          <Col xxl={3} xl={3} className="mb-4 fs-10">
            <Form.Label>&nbsp;</Form.Label>
            <Form.Control size="sm" type="text" title="아이디" value={uid} placeholder="아이디" onChange={e => setUid(e.target.value)} style={{ height: '29px' }} />

          </Col>
          <Col xxl={3} xl={3} className="mb-4 fs-10 pe-4">
            <Form.Label >&nbsp;</Form.Label>
            <Form.Control size="sm" type="text" title="작업내역" value={job} placeholder="작업내역" onChange={e => setJob(e.target.value)} style={{ height: '29px' }} />

          </Col>

        </Row>

        {/* 구분선 */}



        <div className="d-flex justify-content-end align-items-center" style={{ gap: '0.2rem' }}>
          <Button variant="outline-secondary" onClick={handleDefault} className="me-2" style={{ whiteSpace: 'nowrap', width: '5rem' }} title="초기화">초기화</Button>
          <Button variant="outline-secondary" onClick={handleSearch} className="me-2" style={{ whiteSpace: 'nowrap', width: '4rem' }}>검색</Button>
          <Button variant="outline-secondary" style={{ whiteSpace: 'nowrap', width: '4rem' }}>CSV</Button>
        </div>
      </div>

      <AdvanceTable
        headerClassName="bg-200 text-nowrap align-middle"
        rowClassName="align-middle white-space-nowrap"
        tableProps={{ striped: true, className: 'fs-10 mb-0 overflow-hidden' }}
      />
      <p className="mb-0" style={{ fontWeight: 700, lineHeight: '29px' }}>총 {totalElements.toLocaleString()}건</p>
      {/* <AdvanceTablePagination /> */}
      <AdvanceTablePagination
        currentPage={currentPage}
        totalItems={totalElements}
        itemsPerPage={perPage}
        onChange={(newPage) => handlePageChange(newPage)}
      />

    </AdvanceTableProvider>
  );
}

const logManage = () => (
  <div className="container mt-5">
    <Card className="mypage-card">
      <Card.Header as="h4">관리 로그</Card.Header>
      <Card.Body>
        <AdvanceTableExample />
      </Card.Body>
    </Card>
  </div>
);

export default logManage;
