import React, { useState, useEffect } from 'react';

import { Card, Button, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
// import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
// import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter_paging';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination_paging';
import { useNavigate } from 'react-router-dom'; // useNavigate 추가
import data from 'safedb/common/sampledata/sampledataSiteAdminAccount'; // data.js 파일에서 데이터 가져오기
import CheckOutModal from 'components/modal/CheckOutModal';
import paths from 'routes/paths';
import { fetchAdminList_Site, deleteMultiAdminInfo_Site } from 'hooks/CallApi';
import { formatDate } from 'components/common/SunnyUtils';

const columns = [
  { accessorKey: 'DuserRole', header: '역할', meta: { maxlength: 7, headerProps: { className: 'text-900' } } },
  { accessorKey: 'userId', header: '아이디', meta: { maxlength: 20, headerProps: { className: 'text-900' } } },
  { accessorKey: 'username', header: '이름', meta: { maxlength: 20, headerProps: { className: 'text-900' } } },
  { accessorKey: 'active', header: '상태', meta: { maxlength: 2, headerProps: { className: 'text-900' } } },
  { accessorKey: 'createdAt', header: '등록일시', meta: { headerProps: { className: 'text-900' } } },
  { accessorKey: 'lastLoginAt', header: '최근접속일시', meta: { headerProps: { className: 'text-900' } } }
];

function AdvanceTableExample() {
  const navigate = useNavigate(); // useNavigate 훅 사용

  const [tableData, setTableData] = useState([]); // 데이터 상태 추가
  const [modalShow, setModalShow] = useState(false); // 모달 상태
  const [modalReShow, setModalReShow] = useState(false); // 모달 상태
  const [loading, setLoading] = useState(true); // 로딩 상태
  const [showSpinner, setShowSpinner] = useState(false); // 스피너 상태 추가
  const [totalElements, setTotalElements] = useState(0);
  const [currentPage, setCurrentPage] = useState(0); // 현재 페이지
  const [perPage, setPerPage] = useState(10); // 페이지당 항목 수 (size)
  const [EcompanyId, setEcompanyId] = useState(''); // 페이지당 항목 수 (size)
  const [ErrorCode, setErrorCode] = useState('');
  const [ErrorMsg, setErrorMsg] = useState('');
  const [modalErrorShow, setModalErrorShow] = useState(false);

  const table = useAdvanceTable({
    // data, // 전체 데이터를 전달
    data: tableData, // 상태에서 데이터 가져오기
    columns,
    sortable: true,
    selection: true,
    pagination: true,
    perPage,
    selectionColumnWidth: 30
  });

  // API 호출 함수
  const loadData = async (page, size) => {
    // console.log("Fetching data with page:", page, "and size:", size);
    const spinnerTimer = setTimeout(() => setShowSpinner(true), 300);

    try {
      const response = await fetchAdminList_Site(page, size); // API 호출 
      const logData = response.data.content.map((item, index) => ({

        // num: index + 1 + page * size, // 순번 계산
        num: (page * size) + index + 1, // 역순으로 순번 계산
        userId: item.userId,
        username: item.username,
        userRole: item.userRole,
        DuserRole: item.userRole === 'SA' ? '시스템 관리자' : item.userRole === 'ADMIN' ? '최고 관리자' : '운영 관리자',

        // userIds: item.userIds,
        userUuid: item.userIds.userUuid,
        companyId: item.userIds.companyId,
        active: item.active ? '정상' : '비정상',
        activestatus: item.active,
        description: item.userDescription,
        companyName: item.companyName,
        imgUrl: item.imgUrl,
        // createdAt: item.insertTimeUtc ? new Date(item.insertTimeUtc).toLocaleString('ko-KR', {
        //   year: 'numeric',
        //   month: '2-digit',
        //   day: '2-digit',
        //   hour: '2-digit',
        //   minute: '2-digit',
        //   second: '2-digit',
        //   hour12: false, // 24시간제
        // }) : '',
        // createdAt: item.insertTimeUtc
        //   ? new Date(item.insertTimeUtc)
        //     .toISOString() // ISO 포맷으로 변환
        //     .replace('T', ' ') // 'T'를 공백으로 대체
        //     .slice(0, 19) // 초 단위까지만 표시
        //   : '-',
        // createdAt: item.insertTimeUtc
        //   ? new Date(item.insertTimeUtc).toLocaleString(undefined, {
        //     year: 'numeric',
        //     month: '2-digit',
        //     day: '2-digit',
        //     hour: '2-digit',
        //     minute: '2-digit',
        //     second: '2-digit',
        //     hour12: false // 24시간 형식
        //   }) 
        //   : '-',
        createdAt: item.insertTimeUtc
          ? formatDate(item.insertTimeUtc) // 포맷 함수 호출
          : '-',
        // lastLoginAt: item.loginTimeUtc ? new Date(item.loginTimeUtc).toLocaleString('ko-KR', {
        //   year: 'numeric',
        //   month: '2-digit',
        //   day: '2-digit',
        //   hour: '2-digit',
        //   minute: '2-digit',
        //   second: '2-digit',
        //   hour12: false, // 24시간제
        // }) : ''
        // lastLoginAt: item.loginTimeUtc
        //   ? new Date(item.loginTimeUtc)
        //     .toISOString() // ISO 포맷으로 변환
        //     .replace('T', ' ') // 'T'를 공백으로 대체
        //     .slice(0, 19) // 초 단위까지만 표시
        //   : '-',
        lastLoginAt: item.loginTimeUtc
          ? formatDate(item.loginTimeUtc) // 포맷 함수 호출
          : '-',
      }));
      setTotalElements(response.data.page.totalElements);
      setTableData(logData);

      setEcompanyId(logData[0].companyId);
    } catch (error) {
      console.error('API 데이터 로드 에러:', error);
    } finally {
      clearTimeout(spinnerTimer); // 데이터 로딩이 끝나면 스피너 타이머 제거
      setLoading(false);
      setShowSpinner(false); // 로딩 완료 후 스피너를 숨김
    }
  };

  // 페이지 변경 시 데이터 다시 로드
  useEffect(() => {
    console.log("Current page or perPage changed:", currentPage, perPage);
    loadData(currentPage, perPage);
  }, [currentPage, perPage]);

  // 페이지네이션 이벤트 핸들러
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    table.resetRowSelection(); // 선택된 행 초기화
  };

  const handlePerPageChange = (newPerPage) => {
    console.log("Changing perPage to:", newPerPage);
    setPerPage(newPerPage); // 페이지 크기 업데이트
    setCurrentPage(0); // 첫 페이지로 이동
  };
  const handleRowClick = (row) => {
    // const { id, name, role, org, status } = row.original; // 필요한 데이터 추출
    const { userId, username, userRole, userUuid, companyId, activestatus, active, createdAt, lastLoginAt, companyName, description, imgUrl } = row.original;
    navigate(`/safedb/common/SiteadminAccount/edit`, {
      state: { userId, username, userRole, userUuid, companyId, activestatus, active, createdAt, lastLoginAt, companyName, description, imgUrl } // 전달할 데이터
    });
    // navigate(`/safedb/common/SiteadminAccount/edit?id=${id}&name=${name}&role=${role}&org=${org}&status=${status}`); // 데이터와 함께 이동
  };


  const handleClick = () => {


    navigate(`/safedb/common/SiteadminAccount/add`, {
      state: { EcompanyId } // 전달할 데이터
    });

  };


  const handleDeleteClick = () => {
    setModalShow(true); // 관리자 비밀번호 모달 열기
  };



  const selectedRowCount = table.getSelectedRowModel().rows.length;

  const handleDelete = async () => {
    setModalShow(false);
    const selecteduserUuids = table
      .getSelectedRowModel()
      .rows.map(row => row.original.userUuid); // 선택된 행의 companyId 가져오기
    try {
      await deleteMultiAdminInfo_Site(selecteduserUuids); // 삭제 API 호출
      setModalReShow(true);
      table.resetRowSelection();
    } catch (error) {
      setErrorCode(error.response.data.code);
      setErrorMsg(error.response.data.message);
      setModalErrorShow(true);

    }
  };

  const handleDeleteRe = () => {
    // const selectedRowIds = table
    //   .getSelectedRowModel()
    //   .rows.map(row => row.original.id);
    // const newData = tableData.filter(row => !selectedRowIds.includes(row.id)); // 선택된 행 제외
    // setTableData(newData); // 데이터 업데이트
    // table.resetRowSelection();
    setModalReShow(false);
    window.location.reload(); // 등록 완료 후 페이지 새로고침
  };




  return (

    <AdvanceTableProvider {...table}>
      {/* <BulAction rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} /> */}
      <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }} className="mb-2">

        {/* <AdvanceTableFooter rowsPerPageSelection /> */}
        <AdvanceTableFooter
          rowsPerPageSelection
          onPageChange={(page, newPageSize) => {
            handlePerPageChange(newPageSize); // 페이지 크기 변경
            setCurrentPage(page); // 현재 페이지 설정
          }}
        />
        {selectedRowCount > 0 ? (
          <Button
            variant="outline-danger"
            style={{
              position: 'absolute',
              right: 10,
              top: -8,
              width: '100px'
            }}
            onClick={handleDeleteClick}
          >
            삭제
          </Button>
        ) : (
          // <Link to={{
          //   pathname: paths.AddSiteAdminAccount,
          //   state: {
          //     companyId: EcompanyId, // 첫 번째 요소의 companyId를 전달
          //   },
          // }} style={{ textDecoration: 'none' }}>
          <Button
            variant="outline-success"
            style={{
              position: 'absolute',
              right: 10,
              top: -8,
              width: '100px'
            }}
            onClick={handleClick}
          >
            등록
          </Button>
          // </Link>
        )}
      </div>
      {loading ? (
        showSpinner ? (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <p></p>
        )
      ) : (
        <AdvanceTable
          headerClassName="bg-200 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            striped: true,
            className: 'fs-10 mb-0 overflow-hidden'
          }}
          onRowClick={handleRowClick} // 이벤트 핸들러 전달

        />
      )}
      <div className="mt-3">
        <p style={{ margin: 0, fontWeight: 700 }}>총 {totalElements.toLocaleString()}건</p>
        {/* <AdvanceTablePagination /> */}
        <AdvanceTablePagination
          currentPage={currentPage}
          totalItems={totalElements}
          itemsPerPage={perPage}
          onChange={(newPage) => handlePageChange(newPage)}
        />
      </div>

      <CheckOutModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onConfirm={handleDelete}
        title="경고" // 제목
        body="삭제된 관리자 정보는 복구할 수 없습니다. 정말 삭제하시겠습니까?" // 본문 내용
        cancelText="취소" // 취소 버튼 텍스트
        confirmText="삭제" // 확인 버튼 텍스트
        variant="warning" // 원하는 variant
        CancelButton={true}
        SubmitButton={true}
      />
      <CheckOutModal
        show={modalReShow}
        onHide={() => setModalReShow(false)}
        onConfirm={handleDeleteRe}
        title="완료" // 제목
        body="삭제가 완료 되었습니다." // 본문 내용
        // cancelText="취소" // 취소 버튼 텍스트
        confirmText="확인" // 확인 버튼 텍스트
        variant="success" // 원하는 variant
        SubmitButton={true}
      />
      <CheckOutModal
        show={modalErrorShow}
        onHide={() => setModalErrorShow(false)}
        onConfirm={() => setModalErrorShow(false)}
        title="경고"
        // body={`에러코드 : ${ErrorCode}, 에러 메시지 : ${ErrorMsg}`}
        body={`${ErrorMsg}`}
        cancelText="취소"
        confirmText="확인"
        variant="warning"

        SubmitButton={true}
      />
    </AdvanceTableProvider >

  );
}

const SiteAdminAccount = () => {
  return (
    <div className="container mt-5">
      <Card className="mypage-card">
        <Card.Header as="h4">관리자</Card.Header>
        <Card.Body>
          <AdvanceTableExample />
        </Card.Body>
      </Card>
    </div>
  );
};

export default SiteAdminAccount;
