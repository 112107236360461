// mypage.js
import React, { useState, useEffect } from 'react';
import { Form, Button, InputGroup, FormControl, Card, Row, Col } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone'; // React Dropzone import
import Avatar from 'components/common/Avatar';
import avatarImg_COMMON from 'assets/img/team/avatar.png';
import avatarImg_SA from 'assets/img/initech_ci.png';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { useAuthWizardContext } from 'providers/AuthWizardProvider';
import Flex from 'components/common/Flex';
import { useAppContext } from 'providers/AppProvider';
import ChangePasswordModal from './changePasswordModal';
import AdminPasswordAlertModal from 'components/modal/CheckOutModal';
import CheckOutModal from 'components/modal/CheckOutModal';
import { toast } from 'react-toastify'; // 추가
import { updateAdminInfo, getAdminInfo, ImagesUpload } from 'hooks/CallApi';


const MyPage = ({ setValue, handleClose = () => { }, onHide, show }) => {
  const {
    config: {
      userAuth,
      userId,
      userName,
      userUuid,
      userAvatar
    }, setConfig
  } = useAppContext();
  const [modalShow, setModalShow] = useState(false);
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [confirmCheckShow, setConfirmCheckShow] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false); // 비밀번호 변경 모달 상태 추가
  const handleClosePasswordModal = () => setShowPasswordModal(false);
  const handleShowPasswordModal = () => setShowPasswordModal(true);
  const [isChanged, setIsChanged] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [avatarImg, setavatarImg] = useState('');



  useEffect(() => {
    if (userAuth === 'SA') {
      setavatarImg(avatarImg_SA);
    } else {
      setavatarImg(avatarImg_COMMON);
    }
  }, []);



  const handleMouseEnter = () => {
    if (userAuth === 'SA' || userAuth === 'ADMIN') {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => setIsHovered(false);

  const handleCheckOutModal = () => {
    handleClose(); // 창을 닫는 함수 호출
    setModalShow(false); // 관리자 모달 닫기
  };
  const handleCancelClick = () => {
    // if (isChanged) {
    //   setModalShow(true);
    // } else {
    //   setModalShow(false); // 변경 사항이 없을 때 창 닫기
    //   handleClose();
    // }
    setModalShow(true);
  };

  const [formData, setFormData] = useState({
    id: userId,
    password: '********',
    name: userName,
    description: '',
    imgUrl: userAvatar
  });
  const { user } = useAuthWizardContext();

  // const [avatar, setAvatar] = useState([
  //   ...(user.avatar ? user.avatar : []),
  //   { src: avatarImg }
  // ]);
  const [avatar, setAvatar] = useState([{ src: userAvatar || avatarImg }]); // userAvatar를 초기화에 사용


  const [initialData, setInitialData] = useState({ ...formData });

  const [NameError, setNameError] = useState('');
  const [DescError, setDescError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'name' && value.length >= 30) {
      setNameError(`이름은 최대 30자까지 입력 가능합니다.`);
    } else {
      setNameError('');
    }

    if (name === 'description' && value.length >= 800) {
      setDescError(`설명은 최대 800자까지 입력 가능합니다.`);
    } else {
      setDescError('');
    }

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const dataChanged =
      formData.name !== initialData.name ||
      formData.description !== initialData.description ||
      formData.imgUrl !== initialData.imgUrl

    setIsChanged(dataChanged);
  }, [formData, initialData]);



  useEffect(() => {
    const fetchAdminInfo = async () => {
      try {
        const { adminInfo } = await getAdminInfo();
        console.log("계정 정보 불러온 데이터 , ", adminInfo);
        const imgUrl = adminInfo.data.imgUrl && adminInfo.data.imgUrl.trim() !== ""
          ? adminInfo.data.imgUrl
          : userAvatar; // 로그인 시 설정된 기본 이미지 사용



        // const { userId, username, userDescription, imgUrl } = adminInfo.data; // adminInfo에서 data를 추출
        const updatedData = {
          id: adminInfo.data.userId,
          name: adminInfo.data.username,
          description: adminInfo.data.userDescription || '', // 설명이 없는 경우 빈 문자열로 설정
          password: '********',
          // imgUrl: imgUrl && imgUrl.trim() !== "" ? imgUrl : avatarImg,
          imgUrl: imgUrl
        };
        setFormData(updatedData);
        // setAvatar([{ src: imgUrl && imgUrl.trim() !== "" ? imgUrl : avatarImg, }]); // Avatar 상태도 업데이트
        setAvatar([{ src: imgUrl }]); // Avatar 상태도 업데이트
        setInitialData(updatedData);

      } catch (error) {
        toast.error('계정 정보를 불러오는데 실패했습니다.');
      }
    };

    fetchAdminInfo();
  }, [show, userAvatar]);


  const handleSubmit = (e) => {
    e.preventDefault();
    setConfirmModalShow(true);
  };
  const handleConfirmCheck = async () => {
    // 업데이트 처리
    try {
      const response = await updateAdminInfo(userUuid, {
        imgUrl: formData.imgUrl,
        username: formData.name,
        active: true,
        userDescription: formData.description,
      });
      // console.log("API 응답:", response);
      if (response) {

        setConfirmModalShow(false);
        setConfirmCheckShow(true);
        setConfig('userAvatar', formData.imgUrl);
      } else {
        toast.success("매니저 정보 업데이트가 실패했습니다."); // Toast Notification 추가
      }
    } catch (error) {
      console.error('매니저 정보 업데이트 에러:', error);
      toast.error("매니저 정보 업데이트 중 오류가 발생했습니다.");
    }
  }


  // const onDrop = (acceptedFiles) => {
  //   if (userAuth === 'SA' || userAuth === 'ADMIN') {

  //     if (acceptedFiles.length > 0) {
  //       const imageFile = acceptedFiles[0];
  //       const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
  //       // 파일 크기 확인
  //       if (imageFile.size > MAX_FILE_SIZE) {
  //         toast.error('이미지 크기는 최대 5MB까지 허용됩니다.');
  //         return;
  //       }
  //       const imageUrl = URL.createObjectURL(imageFile);
  //       setAvatar([{ src: imageUrl }]);
  //       setValue('avatar', imageFile);
  //     }
  //   }
  // };

  // Handle file drop
  const onDrop = async (acceptedFiles) => {
    if (userAuth === 'SA' || userAuth === 'ADMIN') {
      if (acceptedFiles.length > 0) {
        const imageFile = acceptedFiles[0];
        const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
        if (imageFile.size > MAX_FILE_SIZE) {
          toast.error('이미지 크기는 최대 5MB까지 허용됩니다.');
          return;
        }

        try {
          // Call ImagesUpload API
          const uploadResponse = await ImagesUpload(imageFile, 'manager'); // 'manager'로 업로드

          if (uploadResponse && uploadResponse.data.imageUrl) {

            setAvatar([{ src: uploadResponse.data.imageUrl }]); // Update avatar with uploaded URL
            setFormData((prev) => ({
              ...prev,
              imgUrl: uploadResponse.data.imageUrl, // Set the uploaded URL in formData
            }));
            setIsChanged(true); // Mark as changed
          } else {
            throw new Error('이미지 업로드에 실패했습니다.');
          }
        } catch (error) {
          console.error('이미지 업로드 에러:', error);
        }
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'image/gif': ['.gif'],
      'image/svg+xml': ['.svg']
    },
    disabled: !(userAuth === 'SA' || userAuth === 'ADMIN') // Dropzone 비활성화

  });

  return (
    <div className="container mt-5">
      <Card className="mypage-card">

        <Card.Body>
          <Form>
            <Row>
              {/* Left Column (Profile Image) */}
              <Col md={4} className="text-center profile-section">
                <div {...getRootProps({ className: 'dropzone' })}
                  style={{
                    // cursor: 'pointer',
                    cursor: userAuth === 'SA' || userAuth === 'ADMIN' ? 'pointer' : 'not-allowed',

                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '200px',
                    // border: '2px dashed #ccc',
                    // borderRadius: '8px'
                  }}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <input {...getInputProps()} />
                  <Flex justifyContent="center" >
                    <img
                      src={avatar.length > 0 ? avatar[0].src : cloudUpload}
                      alt=""
                      style={{
                        borderRadius: '50%', // 동그란 형태
                        // border: 'solid',
                        width: '150px', // 원하는 너비
                        height: '150px', // 원하는 높이
                        objectFit: 'contain',

                      }}
                    />
                    {isHovered && ( // hover 시 텍스트 표시
                      <div style={{
                        position: 'absolute',
                        backgroundColor: 'rgba(0, 0, 0, 1)',
                        color: 'white',
                        padding: '10px',
                        borderRadius: '50%', // 동그란 형태
                        width: '150px', // 원하는 너비
                        height: '150px', // 원하는 높이
                        pointerEvents: 'none', // 클릭 방지
                        objectFit: 'cover', // 이미지 비율 유지
                        display: 'flex', // Flexbox 사용
                        justifyContent: 'center', // 수평 중앙 정렬
                        alignItems: 'center', // 수직 중앙 정렬
                        opacity: isHovered ? 0.8 : 1, // 투명도 적용
                      }}>
                        이미지 변경
                      </div>
                    )}
                  </Flex>
                  {/* <p>여기에 이미지를 드래그하거나 클릭하여 업로드하세요.</p> */}
                </div>

              </Col>

              {/* Right Column (ID, Password, Name) */}
              <Col md={8}>
                {/* ID */}
                <Form.Group className="mb-3">
                  <Form.Label>아이디</Form.Label>
                  <Form.Control
                    type="text"
                    name="id"
                    value={formData.id}
                    onChange={handleChange}
                    readOnly
                    disabled
                    className="input-field"
                  />
                </Form.Group>

                {/* Password */}
                <Form.Group className="mb-3">
                  <Form.Label>비밀번호</Form.Label>
                  <InputGroup>
                    <FormControl
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      readOnly
                      disabled
                      className="input-field"
                    />
                    {(userAuth === 'SA' || userAuth === 'ADMIN') && (
                      // <Button variant="outline-secondary" onClick={handleShowPasswordModal}>변경</Button>
                      <Button variant="outline-info"
                        style={{
                          cursor: 'pointer',
                          position: 'absolute',
                          right: '10px',
                          top: '15%',
                          // transform: 'transformY(-50%)',
                          zIndex: 9,
                          padding: '5px 10px',
                          fontSize: '0.6rem',
                        }}
                        onClick={handleShowPasswordModal}
                      >
                        변경
                      </Button>
                    )}
                  </InputGroup>
                </Form.Group>

                {/* Name */}
                <Form.Group className="mb-3">
                  <Form.Label>이름</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="input-field"
                    maxLength='30'
                  />
                  {NameError &&
                    <div style={{ color: 'red' }}>
                      {NameError}
                    </div>} {/* 에러 메시지 표시 */}
                </Form.Group>
              </Col>
            </Row>

            {/* Description (Below Both Columns) */}
            <Row className="mt-4">
              <Col>
                <Form.Group>
                  <Form.Label>설명</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    rows={8}
                    value={formData.description}
                    onChange={handleChange}
                    className="input-field"
                    maxLength='800'
                  />
                  {DescError &&
                    <div style={{ color: 'red' }}>
                      {DescError}
                    </div>} {/* 에러 메시지 표시 */}
                </Form.Group>
              </Col>
            </Row>

            {/* Cancel and Confirm Buttons */}
            <div className="d-flex justify-content-end mt-3">
              <Button variant="secondary" className="me-2" onClick={handleCancelClick}>
                취소
              </Button>
              {isChanged ? (
                <Button
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={NameError || DescError}
                >
                  변경
                </Button>
              ) : (
                <Button variant="primary" onClick={handleClose}>
                  확인
                </Button>
              )}
            </div>
          </Form>
        </Card.Body>
      </Card>
      <CheckOutModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onConfirm={handleCheckOutModal}
        title="확인" // 제목
        variant="warning"
        body="변경을 취소하시겠습니까?" // 본문 내용
        cancelText="취소" // 취소 버튼 텍스트
        confirmText="확인" // 확인 버튼 텍스트
        CancelButton={true}
        SubmitButton={true}
      />

      <CheckOutModal
        show={confirmModalShow}
        onHide={() => {
          setConfirmModalShow(false)
          setModalShow(true)
        }}
        onConfirm={handleConfirmCheck}
        title="확인" // 제목
        body="변경하신 사항으로 계정 정보를 수정하시겠습니까?" // 본문 내용
        cancelText="취소" // 취소 버튼 텍스트
        confirmText="확인" // 확인 버튼 텍스트
        variant="info"
        CancelButton={true}
        SubmitButton={true}
      />

      <CheckOutModal
        show={confirmCheckShow}
        onHide={() => {
          setConfirmCheckShow(false); // 새로운 비밀번호 확인 모달 닫기
        }}
        onConfirm={() => {
          setConfirmCheckShow(false); // 모달 닫기
          handleClose();
        }}
        title="확인" // 제목
        body="변경하신 사항이 해당 계정 정보에 저장되었습니다." // 본문 내용
        cancelText="취소" // 취소 버튼 텍스트
        confirmText="확인" // 확인 버튼 텍스트
        variant="info"
        SubmitButton={true}
      />

      <ChangePasswordModal
        show={showPasswordModal}
        onHide={handleClosePasswordModal}
      />

    </div>


  );
};

export default MyPage;
