import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react'; // useState 추가
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown, InputGroup, Modal, Button } from 'react-bootstrap'; // Button 추가
import team3 from 'assets/img/team/3.jpg';
import Avatar from 'components/common/Avatar';
import paths from 'routes/paths';
import Flex from 'components/common/Flex';
import MyPage from 'safedb/common/mypage';
import ChangePasswordModal from 'safedb/common/changePasswordModal'; // ChangePasswordModal 임포트
import avatarImg from 'assets/img/team/avatar.png';

import { useAppContext } from 'providers/AppProvider';

const ProfileDropdown = () => {
  const [showModal, setShowModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false); // 비밀번호 변경 모달 상태 추가

  const {
    config: { isNavbarVerticalCollapsed, userAuth, userAuth_K, userId, userAvatar },
    setConfig
  } = useAppContext();
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleClosePasswordModal = () => setShowPasswordModal(false);
  const handleShowPasswordModal = () => setShowPasswordModal(true);
  const navigate = useNavigate();

  const handleLogout = () => {
    // 세션 스토리지 비우기
    sessionStorage.clear();

    navigate(paths.cardLogin);
  };
  useEffect(() => {
    // userAvatar가 변경될 때마다 실행 (이미 구독 중이므로 추가 작업은 필요하지 않음)
    console.log('userAvatar has changed:', userAvatar);
  }, [userAvatar]);
  return (
    <>
      {/* <InputGroup>
        <InputGroup.Text id="inputGroup-sizing-sm" style={{ width: '100px', justifyContent: 'center' }}>  {userAuth}  </InputGroup.Text>
      </InputGroup > */}
      <InputGroup>
        {/* 사용자 권한 */}
        <InputGroup.Text
          id="inputGroup-sizing-sm"
          style={{
            width: '100px',
            justifyContent: 'center',
            fontSize: '16px', // 텍스트 크기
            // fontWeight: 'bold', // 텍스트 강조
            // backgroundColor: '#f5f5f5', // 배경색
          }}
        >
          {userAuth}
        </InputGroup.Text>
      </InputGroup>
      <Dropdown navbar={true} as="li">
        <Dropdown.Toggle
          bsPrefix="toggle"
          as={Link}
          to="#!"
          className="pe-0 ps-2 nav-link"
        >
          {/* <Flex className="mb-2">
            <img
              src={userAvatar}

              style={{
                borderRadius: '50%', // 동그란 형태
                height: '30px', width: '30px', objectFit: 'contain'
              }}
            />
            <i className="fas fa-user"></i>

            <label className='mt-2' style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>{userId}</label>

          </Flex> */}
          <Flex
            alignItems="center"
            className="mb-2"
            style={{
              gap: '10px', // 아이템 간격 조정
            }}
          >
            {/* 사용자 프로필 이미지 */}
            <img
              src={userAvatar}
              alt="프로필 이미지"
              style={{
                borderRadius: '50%', // 동그란 모양
                height: '40px', // 높이
                width: '40px', // 너비
                objectFit: 'contain', // 비율 유지
                border: '1px solid #ccc', // 이미지 테두리(선택 사항)
              }}
            />

            {/* 사용자 ID */}
            <span
              style={{
                fontSize: '16px', // 텍스트 크기
                // fontWeight: 'bold', // 텍스트 강조
                whiteSpace: 'nowrap', // 줄바꿈 방지
                // color: '#333', // 텍스트 색상
              }}
            >
              {userId}
            </span>
          </Flex>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
          <div className="bg-white rounded-2 py-2 dark__bg-1000">
            <Dropdown className="fw-bold text-warning" >
              <FontAwesomeIcon icon="crown" className="me-2 ms-3" />
              <span>{userAuth_K}</span>
            </Dropdown>
            <Dropdown.Divider />
            {/* <Dropdown.Item as={Link} to={paths.myPage}>내 정보</Dropdown.Item> */}
            <Dropdown.Item onClick={handleShow}>
              내 정보
            </Dropdown.Item>
            {/* <Dropdown.Item onClick={handleShowPasswordModal}>
              비밀번호 변경
            </Dropdown.Item> */}
            {/* <Dropdown.Item href="#!">Feedback</Dropdown.Item> */}
            <Dropdown.Divider />
            {/* <Dropdown.Item as={Link} to={paths.userSettings}>
              Settings
            </Dropdown.Item> */}
            <Dropdown.Item onClick={handleLogout}>
              로그아웃
            </Dropdown.Item>
          </div>
        </Dropdown.Menu>
      </Dropdown>


      {/* 모달 컴포넌트 추가 */}
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>내 정보</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MyPage setValue={() => { }} handleClose={handleClose} /> {/* handleClose를 전달 */}
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            닫기
          </Button> */}
        </Modal.Footer>
      </Modal>


      <ChangePasswordModal
        show={showPasswordModal}
        onHide={handleClosePasswordModal}
      />
    </>
  );
};

export default ProfileDropdown;
