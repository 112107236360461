import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import { useAppContext } from 'providers/AppProvider';
import { Card, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  TooltipComponent,
  GridComponent
} from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import ReactEchart from 'components/common/ReactEchart';
import { totalAlgoRequest } from 'hooks/CallApi';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer
]);

const AlgorithmCompare = () => {
  const { getThemeColor } = useAppContext();
  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // 로딩 상태 추가

  const encryptionChartRef = useRef(null);
  const decryptionChartRef = useRef(null);

  const {
    config: { dashboardDate, dashboardDateValue, Chart_startdate, Chart_enddate },
    setConfig
  } = useAppContext();

  const [dateRange, setDateRange] = useState({
    start: Chart_startdate,
    end: Chart_enddate
  });

  useEffect(() => {
    // Chart_startdate 또는 Chart_enddate가 변경될 때 상태 업데이트
    setDateRange({
      start: Chart_startdate,
      end: Chart_enddate
    });
  }, [Chart_startdate, Chart_enddate]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // 데이터 로드 시작 시 로딩 상태 활성화 

      try {
        const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const response = await totalAlgoRequest({
          period: dashboardDateValue, // or 'week', 'month', 'custom'
          start: dateRange.start,
          end: dateRange.end,
          timeZone: clientTimeZone
        });

        // API 데이터를 상태로 설정
        setChartData(response.data.algoInfoList);
      } catch (error) {
        console.error('전체 알고리즘 요청 데이터 가져오기 에러:', error);
      } finally {
        setIsLoading(false); // 데이터 로드 완료 시 로딩 상태 비활성화
      }
    };

    fetchData();
  }, [dashboardDateValue, dateRange]);
  // 암호화 및 복호화에 대한 성공 및 실패 처리량 계산
  // const firstValue = Math.round(TotalValue * firstRatio);
  // const secondValue = Math.round(TotalValue * secondRatio);
  // const thirdValue = Math.round(TotalValue * thirdRatio);

  const getOption = () => {
    if (!chartData) {
      return {};
    }

    const colorMapping = {
      AES: '#FFFEAC',
      ARIA: '#FA8072',
      SEED: '#00FF00'
    };

    const formattedData = chartData.map((item) => ({
      value: item.requestCnt,
      name: item.algoName,
      itemStyle: {
        color: colorMapping[item.algoName] || '#CCCCCC' // 기본 색상은 회색으로 설정
      }
    }));

    return {
      legend: {
        orient: 'horizontal',
        bottom: 0,
        left: 'center',
        itemWidth: 10,
        itemHeight: 10,
        textStyle: {
          color: getThemeColor('gray-600'),
          fontSize: 12
        }
      },
      series: [
        {
          type: 'pie',
          radius: ['50%', '70%'],
          center: ['50%', '45%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 3,
            borderColor: getThemeColor('gray-100'),
            borderWidth: 2
          },
          label: {
            show: true,
            position: 'center',
            fontSize: 10,
            fontWeight: 'bold',
            color: getThemeColor('gray-700'),
            formatter: `{b|총 요청량}\n{c|${chartData.reduce(
              (acc, item) => acc + item.requestCnt,
              0
            ).toLocaleString()}건}`,
            rich: {
              b: {
                fontSize: 14,
                lineHeight: 12,
                color: getThemeColor('gray-700')
              },
              c: {
                fontSize: 14,
                lineHeight: 16,
                fontWeight: 'bold',
                color: getThemeColor('gray-900')
              }
            }
          },
          labelLine: { show: false },
          data: formattedData

        }
      ],
      tooltip: {
        trigger: 'item',
        padding: [7, 10],
        backgroundColor: getThemeColor('gray-100'),
        borderColor: getThemeColor('gray-300'),
        textStyle: { color: getThemeColor('gray-1100') },
        borderWidth: 1,
        transitionDuration: 0,
        axisPointer: { type: 'none' }
      }
    };
  };

  return (
    <Card className="h-md-100">
      <Card.Header className="pb-0 d-flex align-items-center">
        <h5 style={{ margin: 0 }}>전체 알고리즘별 요청률</h5>
        <span

          style={{
            marginLeft: '1px',
            marginTop: 0,
            marginBottom: 0,
            padding: '2px 8px',
            backgroundColor: '#A1C4E3', // 배경색 파란색
            color: 'white', // 텍스트 색상 흰색
            borderRadius: '4px',
            fontSize: '12px',
            fontWeight: 'bold',
            whiteSpace: 'nowrap'
          }}
        >
          {dashboardDate}

        </span>
      </Card.Header>

      <Card.Body as={Flex} className="pt-0" alignItems="center" justifyContent="space-between">
        {isLoading ? (
          // 로딩 중일 때 카드 내부에 스피너 표시
          <div
            style={{
              width: '100%',
              height: '15rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <ReactEchart
            echarts={echarts}
            option={getOption()}
            ref={encryptionChartRef}
            style={{ width: '100%', height: '15rem' }}
          />
        )}
      </Card.Body>
    </Card>
  );
};

AlgorithmCompare.propTypes = {
  totalRequests: PropTypes.number,
  encryptionValue: PropTypes.number,
  decryptionValue: PropTypes.number,
  encryptionSuccessRatio: PropTypes.number,
  decryptionSuccessRatio: PropTypes.number
};

export default AlgorithmCompare;

