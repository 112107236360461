import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Form, Nav, ProgressBar } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import AccountForm from './AccountForm';
import SelectForm from './SelectForm';
import MailForm from './MailForm';
import SuccessForm from './SuccessForm';
import { useAuthWizardContext } from 'providers/AuthWizardProvider';
import IconButton from 'components/common/IconButton';
import WizardModal from './WizardModal';
import Flex from 'components/common/Flex';
import { useAppContext } from 'providers/AppProvider';
import Success from 'components/wizard/Success';
import { sendAuthCode } from 'hooks/CallApi';

const WizardLayout = ({ variant, validation, progressBar }) => {
  const { isRTL } = useAppContext();
  const { user, setUser, step, setStep } = useAuthWizardContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
    clearErrors
  } = useForm();

  const [formData, setFormData] = useState(null); // 입력 데이터를 저장할 상태
  // const handleDataChange = (data, phonePrefix) => {
  const handleDataChange = (data) => {
    const combinedData = {
      name: data.name,
      // tel: phonePrefix + '-' + data.tel,
      tel: data.tel,
      email: data.email,
      org: data.org
    }
    setFormData(combinedData); // 입력된 데이터를 상태에 저장
  };
  const [modal, setModal] = useState(false);
  // 초기는 무조건 한개고 선택된 효과 요청해서 바꿈
  const [selectedButton, setSelectedButton] = useState('service'); // 선택된 버튼 상태 관리 STEP1
  const [selected, setSelected] = useState('service'); // 선택된 카드 상태 관리 STEP1

  const [isFormComplete, setIsFormComplete] = useState(false); // 폼 완성 상태 관리 STEP2

  const [isAuthenticated, setIsAuthenticated] = useState(false); // 인증 상태 추가 STEP3

  const handleFormComplete = isComplete => {
    setIsFormComplete(isComplete);
  }; // STEP2

  const isNextButtonDisabled = () => {
    if (step === 1) {
      // return !selectedButton; // STEP 1일 때 선택 버튼이 없으면 비활성화
    }
    if (step === 2) {
      return !isFormComplete; // STEP 2일 때의 조건
    }
    if (step === 3) {
      return !isAuthenticated;
    }

    return false; // 다른 단계에서는 비활성화 하지 않음
  };

  const navItems = [
    {
      icon: 'table',
      label: '서비스 소개'
    },
    {
      icon: 'user',
      label: '사용자 정보 입력'
    },
    {
      icon: 'envelope',
      label: '이메일 인증'
    },
    {
      icon: 'thumbs-up',
      label: '완료'
    }
  ];
  const handleSelect = id => {
    setSelectedButton(id); // 선택된 버튼 ID 업데이트
  };

  const handlePrevStep = () => {
    if (step > 1) {
      setStep(step - 1); // 이전 단계로 이동
      if (step === 3) {
        setIsFormComplete(false); // Step 3에서 Step 2로 돌아올 때 폼 완성 상태 초기화
        reset({ agreedToTerms: false }); // Step 2로 돌아올 때 체크박스 초기화
      }
      if (step === 2) {
        setIsFormComplete(false); // STEP2로 돌아갈 때 폼 완성 상태 초기화
      }
    }
  };

  const buttontaker = async (data) => {

    if (step === 2) {
      // API 호출 관련 로직
      try {
        const response = await sendAuthCode({
          applyName: formData.name,
          applyTel: formData.tel,
          applyEmail: formData.email,
          companyName: formData.org
        });

        if (response) {

          setStep(3); // 다음 단계로 이동
        }
      } catch (error) {
        console.error('API 호출 에러:', error);

      }
    }
  }

  const onSubmitData = async (data) => {

    if (!selectedButton) {
      alert(
        'SafeDB 제품 서비스를 어떻게 사용하실지 옵션을 선택해주세요! \n추후 담당자와의 상담을 통해 변경하실 수도 있습니다.'
      );
      return;
    }
    setUser({ ...user, ...data });
    setStep(step + 1);

    if (step === 1) {
      // STEP이 1일 때만 설정되는 것이 아니라, STEP을 2로 설정

      reset(); // STEP1로 돌아오면 입력값 초기화
      setIsFormComplete(false); // 폼 완성 상태도 초기화
    }
  };

  const onError = () => {
    if (!validation) {
      clearErrors();
      setStep(step + 1);
    }
  };

  const toggle = () => setModal(!modal);

  const handleNavs = targetStep => {
    if (step !== 4) {
      if (targetStep < step) {
        setStep(targetStep);
      } else {
        handleSubmit(onSubmitData, onError)();
      }
    } else {
      toggle();
    }
  };

  return (
    <>
      <WizardModal modal={modal} setModal={setModal} />
      <Card
        as={Form}
        noValidate
        onSubmit={handleSubmit(onSubmitData, onError)}
        className="theme-wizard"
        style={{ minHeight: '45rem' }}
      >
        <Card.Header
          className={classNames('bg-body-tertiary', {
            'px-4 py-3': variant === 'pills',
            'pb-2': !variant
          })}
        >
          <Nav className="justify-content-center" variant={variant}>
            {variant === 'pills'
              ? navItems.map((item, index) => (
                <NavItemPill
                  key={item.label}
                  index={index + 1}
                  step={step}
                  handleNavs={handleNavs}
                  icon={item.icon}
                  label={item.label}
                />
              ))
              : navItems.map((item, index) => (
                <NavItem
                  key={item.label}
                  index={index + 1}
                  step={step}
                  handleNavs={handleNavs}
                  icon={item.icon}
                  label={item.label}
                />
              ))}
          </Nav>
        </Card.Header>
        {progressBar && <ProgressBar now={step * 25} style={{ height: 2 }} />}
        {/* STEP1 */}
        <Card.Body className="fw-normal px-md-6 py-4">
          {step === 1 && (
            <div style={{ display: 'flex' }}>
              <SelectForm
                register={register}
                errors={errors}
                watch={watch}
                onSelect={handleSelect} // 선택 상태 업데이트 함수 전달
                selected={selected} // 현재 선택된 카드 전달
                setSelected={setSelected} // 선택 상태 변경 함수 전달
                selectedButton={selectedButton} // 현재 선택된 버튼 전달
                setSelectedButton={setSelectedButton} // 현재 선택된 버튼 전달
              />
              {/* <SelectForm
                register={register}
                errors={errors}
                watch={watch}
              /> */}
            </div>
          )}
          {/* STEP2 */}
          {step === 2 && (
            <AccountForm
              register={register}
              errors={errors}
              watch={watch}
              onFormComplete={handleFormComplete} // 폼 완성 상태 업데이트 함수 전달
              onDataChange={handleDataChange}
            />
          )}
          {/* STEP3 */}
          {step === 3 && (
            <MailForm
              email={formData.email}
              register={register}
              errors={errors}
              setValue={setValue}
              setIsAuthenticated={setIsAuthenticated} // 함수 전달
            />
          )}
          {/* STEP4 */}
          {step === 4 && <Success email={formData.email} reset={reset} />}
        </Card.Body>
        <Card.Footer
          className={classNames('px-md-6 bg-body-tertiary', {
            'd-none': step === 4,
            ' d-flex': step < 4
          })}
        >
          <IconButton
            variant="link"
            icon={isRTL ? 'chevron-right' : 'chevron-left'}
            iconAlign="left"
            transform="down-1 shrink-4"
            className={classNames('px-0 fw-semibold', {
              'd-none': step === 1
            })}
            onClick={handlePrevStep} // 이전 버튼 클릭 핸들러 추가
          >
            이전
          </IconButton>

          <IconButton
            variant="primary"
            className="ms-auto px-5"
            type="submit"
            icon={isRTL ? 'chevron-left' : 'chevron-right'}
            iconAlign="right"
            transform="down-1 shrink-4"
            onClick={buttontaker}
            // disabled={step === 2 ? !isFormComplete : step === 3 ? !isAuthenticated : false} // STEP2랑 3에서 인증이 필요함
            disabled={isNextButtonDisabled()} // 함수 호출로 disabled 상태 결정
          >
            다음
          </IconButton>
        </Card.Footer>
      </Card>
    </>
  );
};

const NavItem = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semibold', {
          done: index < 4 ? step > index : step > 3,
          active: step === index
        })}
        style={{ cursor: 'default' }}
      // onClick={() => handleNavs(index)}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <FontAwesomeIcon icon={icon} />
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs-10">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

const NavItemPill = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semibold', {
          done: step > index,
          active: step === index
        })}
      // onClick={() => handleNavs(index)}
      >
        <Flex alignItems="center" justifyContent="center">
          <FontAwesomeIcon icon={icon} />
          <span className="d-none d-md-block mt-1 fs-10 ms-2">{label}</span>
        </Flex>
      </Nav.Link>
    </Nav.Item>
  );
};

WizardLayout.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool
};

NavItemPill.propTypes = {
  index: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  handleNavs: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

NavItem.propTypes = NavItemPill.propTypes;

export default WizardLayout;
