import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import { Card, Spinner } from 'react-bootstrap';
import { hourlyAvg } from 'hooks/CallApi'; // API 함수 import


const processingTimeThreshold = 0.5;
const SiteAverageApiProcess = () => {
  const chartRef = useRef(null); // 차트를 렌더링할 DOM 참조

  const [chart, setChart] = useState(null); // 차트 인스턴스 상태
  const [data, setData] = useState([]); // API 데이터를 저장할 상태
  const [isLoading, setIsLoading] = useState(true); // 로딩 상태



  // 데이터 로드
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const response = await hourlyAvg({ timeZone: clientTimeZone, type: 'api' });
        console.log("[hourlyAvg] RESPONSE", response);
        const apiData = response.data;

        // 데이터 포맷팅
        const formattedData = apiData.map((company) => ({
          name: company.companyName,
          hourlyData: Array.from({ length: 24 }, (_, hour) => {
            const hourData = company.hourlyAvgDataList.find((item) => item.hour === hour);
            return hourData ? hourData.hourlyAvgDuration : null;
          }),
        }));
        console.log("formattedData", formattedData);
        setData(formattedData);
      } catch (error) {
        console.error('API 데이터 호출 실패:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // 차트 옵션 설정 및 업데이트
  useEffect(() => {
    if (!chartRef.current || isLoading) return;
    const chart = echarts.init(chartRef.current);

    // 시리즈 생성
    const generateSeries = () =>
      data.map((company, index) => ({
        name: company.name,
        type: 'line',
        smooth: true,
        data: company.hourlyData,
        lineStyle: {
          color: ['#5cb85c', '#B0B57E', '#F6C6B1', '#800080'][index % 4],
        },
        markPoint: {
          data: company.hourlyData
            .map((value, hour) =>
              value > 0.5
                ? { value, xAxis: hour, yAxis: value }
                : null
            )
            .filter(Boolean),
          symbol: 'circle',
          symbolSize: 7,
          itemStyle: { color: 'yellow' },
          label: { show: false },
        },
      })).concat({
        name: '지연 기준선',
        type: 'line',
        data: Array(24).fill(processingTimeThreshold), // 고정된 배열 생성
        symbol: 'none',
        lineStyle: {
          color: '#0000FF',
          type: 'dashed',
          width: 2
        }
      });

    // 차트 옵션
    const option = {
      tooltip: {
        trigger: 'axis',
        valueFormatter: (value) => `${value.toFixed(3)} 초`,
      },
      legend: {
        data: data.map((company) => company.name),
        bottom: '5%',
      },
      xAxis: {
        type: 'category',
        data: Array.from({ length: 24 }, (_, hour) => `${hour.toString().padStart(2, '0')}시`),
      },
      yAxis: {
        type: 'value',
        min: 0,
        axisLabel: { formatter: '{value} 초' },
      },
      series: generateSeries(),
      grid: {
        left: '10%',
        right: '5%',
        bottom: '25%',
        top: '5%',
      },

    };

    chart.setOption(option, true);

    // 리사이즈 이벤트 핸들러 등록
    const resizeChart = () => chart.resize();
    window.addEventListener('resize', resizeChart);

    // 컴포넌트 언마운트 시 차트 제거
    return () => {
      window.removeEventListener('resize', resizeChart);
      chart.dispose();
    };
  }, [data, isLoading]);

  return (
    <Card>
      <Card.Header className="pb-0 d-flex align-items-center">
        <h5 style={{ margin: 0 }}>시간별 API 평균 처리속도</h5>
        <span
          style={{
            marginLeft: '8px',
            marginTop: 0,
            marginBottom: 0,
            padding: '2px 8px',
            backgroundColor: '#A1C4E3',
            color: 'white',
            borderRadius: '4px',
            fontSize: '12px',
            fontWeight: 'bold',
          }}
        >
          최근 1주일        </span>
      </Card.Header>
      <Card.Body>
        {isLoading ? (
          <div
            style={{
              width: '100%',
              height: '15rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div ref={chartRef} style={{ width: '100%', height: '20rem' }} />
        )}
      </Card.Body>
    </Card>
  );
};

export default SiteAverageApiProcess;
