import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import WizardForm from './Initech_Wizard';
import Section from 'components/common/Section';
import IconButton from 'components/common/IconButton';
import Form from 'react-bootstrap/Form';
import FalconComponentCard from 'components/common/FalconComponentCard';

const FormTitle = ({ icon, title }) => {
  return (
    <Flex className="mb-4">
      <span className="fa-stack me-2 ms-n1">
        {/* <FontAwesomeIcon icon="circle" className="fa-stack-2x text-300" />
        <FontAwesomeIcon
          icon={icon}
          className="fa-inverse fa-stack-1x text-primary"
        /> */}
      </span>
      <div className="flex-1">
        <h5 className="mb-0 text-primary position-relative">
          <span className="bg-200 dark__bg-1100 pe-3">{title}</span>
          <span className="border position-absolute top-50 translate-middle-y w-100 start-0 z-n1"></span>
        </h5>
        <p className="mb-0">
          You can easily show your stats content by using these cards.
        </p>
      </div>
    </Flex>
  );
};

const WizardForms = () => {
  return (
    <>
      <Section bg="dark" className="pt-8 pb-4 " data-bs-theme="light">
        <Row className='g-0 h-100'>
          {/* 왼쪽 폼 */}
          <Col
            md={4}
            lg={3} // 큰 화면에서는 반반씩 배치

            style={{ padding: '0px' }}
            className="mb-3 h-250 bg-secondary text-white text-center"
          >
            <div className="position-relative pt-md-5 pb-md-7">
              <div className="bg-holder bg-auth-card-shape"></div>
              <div className="z-index-1 position-relative mb-0">
                <img className="mt-3" style={{ width: '120px' }} src="/img/INISAFE_SAFEDB.png" />
                <div className="light mx-3 py-5 bg-dark  mb-3 mt-3 sticky-bottom">
                  <strong className='fs-8'>SafeDB Cloud</strong>
                  <p className="fs-10 fw-lighter pt-2 text-white">
                    SafeDB Cloud는 원천적으로 <br />DB 속 데이터 유출을 방지하기 위한<br />  SaaS형 데이터 암호화 솔루션입니다.
                    <br /><br />
                    오랜 기간 다수의 고객사에서 선택한<br />스테디셀러 제품인 SafeDB를 <br />부담없는 가격과 간편한 도입으로 만나보세요!</p>
                  <a className="fs--1 btn btn-outline-light px-4" href="https://www.initech.com/html/sub/solu/solu_sdcd.html">제품 소개 상세보기</a>
                </div>
              </div>
            </div>
            {/* <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100%'
              }}
            >
              <div
                style={{

                  borderRadius: '50%',
                  width: '150px', // 원하는 크기로 조정
                  height: '150px', // 원하는 크기로 조정
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  overflow: 'hidden',
                  marginBottom: '3rem',
                  marginTop: '3rem'
                }}
              >
                <img
                  src="/img/INISAFE_SAFEDB.png"
                  alt="SafeDB Logo"
                  style={{ width: '100%', height: 'auto' }}
                />
              </div>

              <Card
                className="bg-dark text-white"
                data-bs-theme="light"
                style={{
                  textAlign: 'center',
                  maxWidth: '30rem',
                  padding: '2rem'
                }}
              >
                <Card.Text>
                  <strong>SafeDB SaaS</strong>
                  <br />

                  SafeDB SaaS는 원천적으로 <br />
                  DB 속 데이터 유출을 방지하기 위한 SaaS형 데이터 암호화 솔루션입니다.
                  <br />
                  오랜 기간 다수의 고객사에서 선택한 스테디셀러 제품인 SafeDB를 부담없는 가격과 간편한 도입으로 만나보세요!
                </Card.Text>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <a href="https://www.initech.com/html/sub/solu/solu_safe.html">
                    <IconButton
                      variant="primary"
                      type="button"
                      transform="down-1 shrink-4"
                    >
                      제품 소개<br />
                      상세보기
                    </IconButton>
                  </a>
                </div>
              </Card>

              <></>
            </div> */}

          </Col>

          {/* /오른쪽 폼/ */}
          <Col
            md={8}
            lg={9}

            style={{ padding: '0px' }}
            className="mb-3 h-250"
          >
            {/* <FormTitle icon="spinner" title="Progress Tab" /> */}
            <WizardForm />
          </Col>
        </Row>
      </Section>
    </>
  );
};

FormTitle.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default WizardForms;
