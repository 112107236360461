import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { Button, ButtonGroup, Card } from 'react-bootstrap';
import * as echarts from 'echarts/core';
import { BarChart, PieChart } from 'echarts/charts';
import {
  TooltipComponent,
  GridComponent,
  LegendComponent,
  TitleComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import ReactEchart from 'components/common/ReactEchart';
import { useAppContext } from 'providers/AppProvider';
import { fetchEncKeyComp } from 'hooks/CallApi';
import { formatCountStyle2 } from 'components/common/SunnyUtils';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  PieChart,
  CanvasRenderer,
  LegendComponent
]);

export default function PolicyCharts() {
  const { getThemeColor } = useAppContext();
  const [view, setView] = useState('top'); // 'top' or 'bottom'
  const [view2, setView2] = useState('cf'); // 'top' or 'bottom'

  const [chartData, setChartData] = useState({ pieData: [], barData: [] });
  const pieChartRef = useRef(null);
  const barChartRef = useRef(null);

  const {
    config: { dashboardDate, dashboardDateValue, Chart_startdate, Chart_enddate }
  } = useAppContext();

  const [dateRange, setDateRange] = useState({
    start: Chart_startdate,
    end: Chart_enddate
  });
  useEffect(() => {
    // Chart_startdate 또는 Chart_enddate가 변경될 때 상태 업데이트
    setDateRange({
      start: Chart_startdate,
      end: Chart_enddate
    });
  }, [Chart_startdate, Chart_enddate]);


  const fetchData = async (view) => {
    try {
      const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const response = await fetchEncKeyComp({
        period: dashboardDateValue,
        start: dateRange.start,
        end: dateRange.end,
        timeZone: clientTimeZone,
        order: view,
        type: view2
      });
      const data = response.data[0];

      // Pie chart data
      const pieData = [
        { value: data.top1_total_cnt, name: data.top1 || 'N/A' },
        { value: data.top2_total_cnt, name: data.top2 || 'N/A' },
        { value: data.top3_total_cnt, name: data.top3 || 'N/A' },
        { value: data.top4_total_cnt, name: data.top4 || 'N/A' },
        { value: data.top5_total_cnt, name: data.top5 || 'N/A' }
      ].filter((item) => item.value > 0);

      // Bar chart data
      const barData = [
        {
          name: data.top1 || 'N/A',
          decrypt: data.top1_decrypt,
          encrypt: data.top1_encrypt
        },
        {
          name: data.top2 || 'N/A',
          decrypt: data.top2_decrypt,
          encrypt: data.top2_encrypt
        },
        {
          name: data.top3 || 'N/A',
          decrypt: data.top3_decrypt,
          encrypt: data.top3_encrypt
        },
        {
          name: data.top4 || 'N/A',
          decrypt: data.top4_decrypt,
          encrypt: data.top4_encrypt
        },
        {
          name: data.top5 || 'N/A',
          decrypt: data.top5_decrypt,
          encrypt: data.top5_encrypt
        }
      ].filter((item) => item.decrypt > 0 || item.encrypt > 0);

      setChartData({ pieData, barData });
    } catch (error) {
      console.error('데이터 조회 에러:', error);
      setChartData({ pieData: [], barData: [] }); // 데이터가 없을 때 빈 차트로 표시
    }
  };

  useEffect(() => {
    fetchData(view === 'top' ? 'DESC' : 'ASC'); // 초기 데이터 불러오기
  }, [view, dashboardDateValue, dateRange, view2]);

  const getPieOption = () => ({
    legend: {
      orient: 'horizontal',
      top: '75%',
      bottom: 3,
      data: chartData.pieData.map(d => d.name),
      textStyle: {
        color: getThemeColor('gray-600')
      },
      formatter: name => name.length > 6
        ? `${name.slice(0, 3)}...${name.slice(-2)}`
        : name
    },
    series: [
      {
        type: 'pie',
        radius: ['30%', '55%'],
        center: ['50%', '35%'],
        data: chartData.pieData.length ? chartData.pieData : [{ value: 1, name: '데이터 없음', itemStyle: { color: '#f0f0f0' } }],
        itemStyle: {
          borderRadius: 5,
          borderColor: getThemeColor('gray-100'),
          borderWidth: 2
        },
        label: { show: false }
      }
    ],
    // tooltip: {
    //   trigger: 'item',
    //   formatter: '{b}: {c} ({d}%)'
    // },
    tooltip: {
      trigger: 'item',
      formatter: (params) => {
        // pieData에서 해당 데이터의 원래 정보를 가져옵니다.
        const total = chartData.pieData.reduce((sum, item) => sum + item.value, 0);
        const percentage = ((params.value / total) * 100).toFixed(1);

        return `
          <strong>암호키 명 </strong>: ${params.name}<br />
          <strong>총 요청량 </strong>: ${formatCountStyle2(params.value)}건<br />
          
        `;
      }
    },
    grid: { bottom: '5%', top: '5%' }
  });

  const getBarOption = () => {
    const dataCount = chartData.barData.length;

    const totalDecrypt = chartData.barData.reduce((sum, d) => sum + d.decrypt, 0);
    const totalEncrypt = chartData.barData.reduce((sum, d) => sum + d.encrypt, 0);

    return {
      title: {
        text: view === 'top'
          ? `상위 ${dataCount}개 암호키 요청량`
          : `하위 ${dataCount}개 암호키 요청량`,
        left: 'left',
        top: '5%',
        textStyle: {
          color: getThemeColor('gray-700'),
          fontSize: 16,
          fontWeight: 'bold'
        }
      },
      color: [getThemeColor('primary'), getThemeColor('info')],
      // tooltip: {
      //   trigger: 'axis',
      //   axisPointer: { type: 'shadow' },
      //   formatter: params => {
      //     return params.map(param => {
      //       const originalName = chartData.barData[param.dataIndex]?.name || 'N/A';
      //       const total = param.seriesName === '복호화 처리량' ? totalDecrypt : totalEncrypt;
      //       const percentage = total ? ((param.value / total) * 100).toFixed(1) : 0;
      //       return `${originalName} <br />${param.seriesName}: ${param.value} (${percentage}%)`;
      //     }).join('<br />');
      //   }
      // },
      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'shadow' },
        formatter: (params) => {
          const originalName = chartData.barData[params[0]?.dataIndex]?.name || 'N/A';

          let tooltipText = `암호키명 : ${originalName}<br />`; // 재가공 전 이름 표시
          let totalRequests = 0;

          params.forEach(param => {
            totalRequests += param.value;
          });

          tooltipText += `총 요청량: ${totalRequests.toLocaleString()}건<br />`;

          params.forEach(param => {
            const originalName = chartData.barData[param.dataIndex]?.name || 'N/A';
            const total = param.seriesName === '복호화 처리량' ? totalDecrypt : totalEncrypt;
            const percentage = total ? ((param.value / total) * 100).toFixed(1) : 0;
            tooltipText += `${param.marker} ${param.seriesName}: ${formatCountStyle2(param.value)}건 (${percentage}%)<br />`;
          });

          return tooltipText;
        }
      },

      legend: {
        data: ['복호화 처리량', '암호화 처리량'],
        bottom: 10,
        textStyle: { color: getThemeColor('gray-600') }
      },
      xAxis: {
        type: 'value',
        axisLabel: { color: getThemeColor('gray-500') },
        splitLine: {
          lineStyle: {
            type: 'dashed',
            color: getThemeColor('gray-200')
          }
        }
      },
      yAxis: {
        type: 'category',
        data: chartData.barData.map(d =>
          d.name.length > 6 ? `${d.name.slice(0, 3)}...${d.name.slice(-2)}` : d.name
        ),
        axisLabel: { color: getThemeColor('gray-500') }
      },
      series: [
        {
          name: '복호화 처리량',
          type: 'bar',
          data: chartData.barData.map(d => d.decrypt),
          itemStyle: { borderRadius: [0, 3, 3, 0] }
        },
        {
          name: '암호화 처리량',
          type: 'bar',
          data: chartData.barData.map(d => d.encrypt),
          itemStyle: { borderRadius: [0, 3, 3, 0] }
        }
      ]
    };
  };

  return (
    <Card>
      <Card.Header className="pb-0 d-flex align-items-center">
        <h5 style={{ margin: 0 }}>암호키 요청량 비교</h5>
        <span
          style={{
            marginLeft: '8px',
            marginTop: 0,
            marginBottom: 0,
            padding: '2px 8px',
            backgroundColor: '#A1C4E3',
            color: 'white',
            borderRadius: '4px',
            fontSize: '12px',
            fontWeight: 'bold'
          }}
        >
          {dashboardDate}
        </span>
      </Card.Header>
      <Card.Body>
        <ButtonGroup
          className="mb-3"
          style={{

            position: 'absolute',
            top: '10px',
            right: '10px', // or left: '10px' to align with the left side
            zIndex: 10
          }}
        >
          <Button
            style={{ fontSize: '0.7rem' }}
            variant={view2 === 'cf' ? 'primary' : 'outline-primary'}
            onClick={() => setView2('cf')}
          >
            CF
          </Button>
          <Button
            style={{ fontSize: '0.7rem' }}
            variant={view2 === 'api' ? 'primary' : 'outline-primary'}
            onClick={() => setView2('api')}
          >
            API
          </Button>
          <Button
            style={{ fontSize: '0.7rem' }}
            variant={view2 === 'sdk' ? 'primary' : 'outline-primary'}
            onClick={() => setView2('sdk')}
            disabled
          >
            SDK
          </Button>
        </ButtonGroup>

        <ButtonGroup
          className="mb-3"
          style={{
            position: 'absolute',
            top: '50px',
            right: '10px',
            zIndex: 10
          }}
        >
          <Button
            style={{ fontSize: '0.7rem' }}
            variant={view === 'top' ? 'primary' : 'outline-primary'}
            onClick={() => setView('top')}
          >
            상위
          </Button>
          <Button
            style={{ fontSize: '0.7rem' }}
            variant={view === 'bottom' ? 'primary' : 'outline-primary'}
            onClick={() => setView('bottom')}
          >
            하위
          </Button>
        </ButtonGroup>
      </Card.Body>
      <ChartContainer>

        <ChartsWrapper>
          <ReactEchart
            ref={pieChartRef}
            echarts={echarts}
            option={getPieOption()}
            style={{ width: '45%', height: '20rem' }}
          />
          <ReactEchart
            ref={barChartRef}
            echarts={echarts}
            option={getBarOption()}
            style={{ width: '55%', height: '20rem' }}
          />
        </ChartsWrapper>
      </ChartContainer>
    </Card>
  );
}

const ChartContainer = styled.div`
  padding: 20px;
`;

const ChartsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
