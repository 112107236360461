import React, { useState, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';

const initialData = [
  { id: 1, optionName: "MD", description: "[복호화검증] 암호문 복호화가 성공적으로 수행되었는지 검증합니다." },
  { id: 2, optionName: "NOBASE64", description: "[Base64 미사용] 암호화 시 Base64 인코딩을 사용하지 않습니다." },
  { id: 3, optionName: "랜덤문자열 적용", description: "[랜덤문자열 추가] 암호화 시 원문에 랜덤문자열을 추가하여 동일한 암호문 생성을 방지합니다." },
  { id: 4, optionName: "이중 암호화 방지", description: "이미 암호화된 데이터를 다시 암호화하지 않도록 방지합니다." },
  { id: 5, optionName: "부분 암호화", description: "[일부 데이터만 암호화] 시작 순번(최소값 1)부터 지정된 크기만큼의 데이터를 암호화 합니다. (Byte 단위)" }
];

const EncryptOptionModal = ({ show, onHide, onConfirm, clear, onClearComplete }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isPartialEncryptionSelected, setIsPartialEncryptionSelected] = useState(false);
  const [startPosition, setStartPosition] = useState('');
  const [encryptionSize, setEncryptionSize] = useState('');
  const [showGuideTextStart, setShowGuideTextStart] = useState(false);
  const [showGuideTextSize, setShowGuideTextSize] = useState(false);

  useEffect(() => {
    if (clear) {
      setSelectedOptions([]);
      setIsPartialEncryptionSelected(false);
      setStartPosition('');
      setEncryptionSize('');
      onClearComplete();
    }
  }, [clear, onClearComplete]);

  const handleOptionSelect = (option) => {
    const updatedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter(o => o !== option)
      : [...selectedOptions, option];

    setSelectedOptions(updatedOptions);
    setIsPartialEncryptionSelected(updatedOptions.includes("부분 암호화"));
  };

  const handleInputChange = (value, type) => {
    if (type === 'start') {
      setStartPosition(value);
      setShowGuideTextStart(value < 1);
    } else {
      setEncryptionSize(value);
      setShowGuideTextSize(value < 1);
    }
  };

  const handleConfirm = () => {
    const binaryRepresentation = initialData.map(item =>
      selectedOptions.includes(item.optionName) ? '1' : '0'
    ).join('');

    const partialEncryptionSettings = isPartialEncryptionSelected
      ? { startPosition, encryptionSize }
      : null;

    onConfirm(selectedOptions, binaryRepresentation, partialEncryptionSettings);
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} size="xl" centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>암호화 옵션 설정</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        각 옵션을 선택하면 암호화 방식이 적용됩니다.
        <table className="table table-responsive fs--1 mb-0 overflow-hidden">
          <thead className="bg-200 text-nowrap align-middle">
            <tr>
              <th>선택</th>
              <th>옵션명</th>
              <th>설명</th>
            </tr>
          </thead>
          <tbody>
            {initialData.map(item => (
              <tr key={item.id} className="align-middle">
                <td>
                  <Form.Check
                    type="checkbox"
                    checked={selectedOptions.includes(item.optionName)}
                    onChange={() => handleOptionSelect(item.optionName)}
                  />
                </td>
                <td>{item.optionName}</td>
                <td>{item.description}</td>
              </tr>
            ))}
            {isPartialEncryptionSelected && (
              <tr className="align-middle">
                <td></td>
                <td >
                  부분 암호화 설정값
                </td>
                <td>


                  {/* <Form.Group controlId="partialEncryptionStart" className="mb-3">
                    <div className="d-flex">
                      <Form.Control
                        type="text"
                        value={startPosition}
                        onChange={(e) => handleInputChange(e.target.value.replace(/[^0-9]/g, ''), 'start')}
                        placeholder="시작 위치"
                        className="me-3"
                      />
                      {showGuideTextStart && (
                        <p className="text-danger" style={{ fontSize: '0.9rem' }}>
                          양수만 입력 가능합니다.
                        </p>
                      )}
                      <Form.Control
                        type="text"
                        value={encryptionSize}
                        onChange={(e) => handleInputChange(e.target.value.replace(/[^0-9]/g, ''), 'size')}
                        placeholder="크기"
                      />
                      {showGuideTextSize && (
                        <p className="text-danger" style={{ fontSize: '0.9rem' }}>
                          양수만 입력 가능합니다.
                        </p>
                      )}
                    </div>
                  </Form.Group> */}
                  <Form.Group controlId="partialEncryptionStart" className="mb-3">
                    <div className="d-flex">
                      <div className="me-3">
                        <Form.Control
                          type="text"
                          value={startPosition}
                          onChange={(e) => handleInputChange(e.target.value.replace(/[^0-9]/g, ''), 'start')}
                          placeholder="시작 위치"
                        />
                        {showGuideTextStart && (
                          <p className="text-danger" style={{ fontSize: '0.9rem' }}>
                            양수만 입력 가능합니다.
                          </p>
                        )}
                      </div>
                      <div>
                        <Form.Control
                          type="text"
                          value={encryptionSize}
                          onChange={(e) => handleInputChange(e.target.value.replace(/[^0-9]/g, ''), 'size')}
                          placeholder="크기"
                        />
                        {showGuideTextSize && (
                          <p className="text-danger" style={{ fontSize: '0.9rem' }}>
                            양수만 입력 가능합니다.
                          </p>
                        )}
                      </div>
                    </div>
                  </Form.Group>

                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleConfirm} disabled={showGuideTextStart || showGuideTextSize}>추가</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EncryptOptionModal;
