import { Card } from 'react-bootstrap';

const apiGuide = () => {
  // HTML 파일 경로 지정 (public 폴더 기준)
  const htmlPath = '/guides/SafeDB Cloud API Docs_20241205.html';

  return (
    <Card className="mypage-card">
      <Card.Header className="px-3" as="h4">API 가이드</Card.Header>
      <Card.Body>
        {/* HTML 파일을 iframe으로 로드 */}
        <iframe
          src={htmlPath}
          title="API Guide"
          style={{
            width: '100%', // iframe의 너비를 부모 요소에 맞춤
            height: '55rem', // iframe의 높이를 지정
            border: 'none', // 불필요한 테두리 제거
          }}
        />
      </Card.Body>
    </Card>
  );
};

export default apiGuide;
