import React, { useState, useEffect } from 'react';
import { Form, Button, InputGroup, FormControl, Card, Row, Col } from 'react-bootstrap';
import avatarImg from 'assets/img/team/avatar.png';
import { useAuthWizardContext } from 'providers/AuthWizardProvider';
import Flex from 'components/common/Flex';
import { Link } from 'react-router-dom';

import "safedb/common/css/IniCustomCss.css"
import CheckOutModal from 'components/modal/CheckOutModal';
import { AiFillCloseCircle } from 'react-icons/ai';
import { handleValueDelete, goToParentPath } from 'components/common/SunnyUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretSquareLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone'; // React Dropzone import 
import { useAppContext } from 'providers/AppProvider';

import { getCompanyList, addAdminUsers, ImagesUpload } from 'hooks/CallApi';

const AddAdminAccount = ({ setValue, handleClose }) => {
  const {
    config: {
      userAuth
    },
  } = useAppContext();
  const [modalShow, setModalShow] = useState(false);
  const [cancelmodalShow, setCancelModalShow] = useState(false);
  const [confirmCheckShow, setConfirmCheckShow] = useState(false);

  const [NameError, setNameError] = useState('');
  const [DescError, setDescError] = useState('');
  const [IdError, setIdError] = useState('');

  const [passwordError, setPasswordError] = useState('');  // 비번관련 에러 처리용
  const [showpasswordError, setShowPasswordError] = useState('');  // 비번관련 에러 처리용
  const [showError, setShowError] = useState(false); // 에러 메시지 표시 상태 및 서버 에러 상태 처리용
  const [isArrowHovered, setIsArrowHovered] = useState(false);
  const [companies, setCompanies] = useState([]); // 회사 목록 상태
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    if (userAuth === 'SA' || userAuth === 'ADMIN') {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => setIsHovered(false);

  const handleCheckOutModal = async () => {

    // 비밀번호 유효성 검사
    const passwordPattern = /^(?=.*[a-z]{1,})(?=.*\d{1,})(?=.*[!@#$%^_\-\+=\{\}\[\]:;"'?.,/~]{1,})[a-zA-Z\d!@#$%^_\-\+=\{\}\[\]:;"'?.,/~]+$/;
    const allowedSpecialChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/; // 허용하는 특수문자

    // 비밀번호에 허용되지 않는 특수문자가 포함되어 있는지 체크
    const hasInvalidSpecialChar = /[^a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

    if (hasInvalidSpecialChar.test(formData.password)) {
      setPasswordError('비밀번호에는 허용되지 않는 특수문자가 포함되어 있습니다.');
      return;
    }
    if (!passwordPattern.test(formData.password)) {
      setPasswordError('비밀번호는 영문자, 숫자 및 특수 문자를 포함해야 합니다.');
      return;
    }
    try {
      const adminData = {
        userIds: { companyId: formData.companyId },
        userId: formData.id,
        userPassword: formData.password,
        userRole: formData.role,
        username: formData.name,
        imgUrl: formData.imgUrl, // 이미지 URL 필요 시 추가
        userDescription: formData.description,
      };
      console.log("adminData , ", adminData);

      const response = await addAdminUsers(adminData);
      console.log('관리자 계정 등록 성공:', response);

      setConfirmCheckShow(true);
    } catch (error) {
      console.error('관리자 계정 등록 중 에러:', error);
      alert('관리자 계정 등록 중 문제가 발생했습니다.');
    }
  };

  const handleCancelModal = () => {
    setCancelModalShow(false);
    navigate('/safedb/SA/adminAccount');
  }

  const handleConfirmClick = () => {
    setModalShow(true);
  };

  const handleCancelClick = () => {
    setCancelModalShow(true);
  };

  const [formData, setFormData] = useState({
    id: '',
    password: '',
    passwordConfirm: '',
    name: '',
    role: 'ADMIN',
    description: '',
    companyId: '',
    org: '',
    imgUrl: ''
  });
  const { user } = useAuthWizardContext();

  const [avatar, setAvatar] = useState([
    ...(user.avatar ? user.avatar : []),
    { src: avatarImg }
  ]);



  useEffect(() => {
    // 회사 목록을 가져오는 API 호출
    const fetchCompanies = async () => {
      try {
        const response = await getCompanyList(); // getCompanyList 호출
        console.log("companyList ", response);
        if (response) {
          const companyList = response.data.map((company) => ({
            companyId: company.companyId,
            org: company.name,
          }));
          console.log("companyList2 ", companyList);
          setCompanies(companyList); // 회사 목록 상태 업데이트
        }
      } catch (error) {
        console.error('회사 목록을 가져오는 중 에러 발생:', error);
      }
    };

    fetchCompanies();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;


    if (name === 'id' && value.length >= 30) {
      setIdError(`아이디는 최대 30자까지 입력 가능합니다.`);
    } else {
      setIdError('');
    }

    if (name === 'name' && value.length >= 30) {
      setNameError(`이름은 최대 30자까지 입력 가능합니다.`);
    } else {
      setNameError('');
    }

    if (name === 'description' && value.length >= 800) {
      setDescError(`관리자 계정 설명란 입력은 최대 800자까지 입력 가능합니다.`);
    } else {
      setDescError('');
    }

    const passwordPattern = /^(?=(?:[^a-zA-Z]*[a-zA-Z]){3})(?=.*\d)(?=.*[!@#$%^_\-\+=\{\}\[\]:;"'?.,/~])[a-zA-Z\d!@#$%^_\-\+=\{\}\[\]:;"'?.,/~]{9,16}$/;
    // 영문 최소 3개 숫자 , 특문 최소 1개, 길이는 9~16자, 특수문자 가능 문자 -> (! @ # $ % ^ _ - + = { } [ ] : ; " ' ? , . / ~)

    if (name === 'password') {
      const allowedSpecialChars = "! @ # $ % ^ _ - + = { } [ ] : ; \" ' ? , . / ~";
      if (!passwordPattern.test(value)) {
        setPasswordError(
          `비밀번호는 영문 대문자, 소문자, 숫자, 다음 허용된 특수문자 중 하나 이상을 포함해야 합니다: ${allowedSpecialChars}`

        );
      } else {
        setPasswordError('');
      }
    }


    // 비밀번호 일치 검증
    if (name === 'passwordConfirm') {
      if (value !== formData.password) {
        setPasswordError('입력하신 비밀번호와 다릅니다.');
      } else {
        setPasswordError('');
      }
    }

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleComboChange = ({ target }) => {
    const { name, value } = target;

    if (name === "org") {
      // 소속 변경 처리
      const selectedCompany = companies.find(company => company.companyId === value);
      setFormData({
        ...formData,
        companyId: value, // 회사 ID 저장
        org: selectedCompany ? selectedCompany.org : '' // 회사 이름 저장
      });
    } else if (name === "role") {
      // 역할 변경 처리
      setFormData({
        ...formData,
        role: value // 역할 저장
      });
    } else {
      // 기타 필드 처리
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setShowError(false); // 이전 에러 메시지 초기화

    if (passwordError) {
      setErrorMsg('비밀번호가 비밀번호 정책에 위반됩니다.')
      setShowError(true);
      return;
    }

    if (formData.password != formData.passwordConfirm) {
      setErrorMsg('입력하신 비밀번호와 다릅니다.')
      setShowError(true);
      return;
    }
  };
  const handleValueDeleteWrapper = (field) => {
    // 외부 handleValueDelete 호출
    handleValueDelete(field, formData, setFormData);

    // 비밀번호 관련 필드가 초기화될 경우 passwordError도 초기화
    if (field === 'password' || field === 'passwordConfirm') {
      setPasswordError('');
    }
  };
  // const onDrop = (acceptedFiles) => {
  //   if (userAuth === 'SA' || userAuth === 'ADMIN') {
  //     if (acceptedFiles.length > 0) {
  //       const imageFile = acceptedFiles[0];
  //       const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
  //       // 파일 크기 확인
  //       if (imageFile.size > MAX_FILE_SIZE) {
  //         toast.error('이미지 크기는 최대 5MB까지 허용됩니다.');
  //         return;
  //       }
  //       const imageUrl = URL.createObjectURL(imageFile);
  //       setAvatar([{ src: imageUrl }]);
  //       setValue('avatar', imageFile);
  //     }
  //   }
  // };

  const onDrop = async (acceptedFiles) => {
    if (userAuth === 'SA' || userAuth === 'ADMIN') {
      if (acceptedFiles.length > 0) {
        const imageFile = acceptedFiles[0];
        const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
        if (imageFile.size > MAX_FILE_SIZE) {
          toast.error('이미지 크기는 최대 5MB까지 허용됩니다.');
          return;
        }

        try {
          // Call ImagesUpload API
          const uploadResponse = await ImagesUpload(imageFile, 'manager'); // 'manager'로 업로드

          if (uploadResponse && uploadResponse.data.imageUrl) {

            setAvatar([{ src: uploadResponse.data.imageUrl }]); // Update avatar with uploaded URL
            setFormData((prev) => ({
              ...prev,
              imgUrl: uploadResponse.data.imageUrl, // Set the uploaded URL in formData
            }));
            // setIsChanged(true); // Mark as changed
          } else {
            throw new Error('이미지 업로드에 실패했습니다.');
          }
        } catch (error) {
          console.error('이미지 업로드 에러:', error);
        }
      }
    }
  };



  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'image/gif': ['.gif'],
      'image/svg+xml': ['.svg']
    },
    disabled: !(userAuth === 'SA' || userAuth === 'ADMIN') // Dropzone 비활성화

  });
  const isFormValid = () => {
    const { id, name, password, passwordConfirm } = formData;
    return id && name && password && passwordConfirm;
  };

  return (
    <div className="container mt-5">
      <Card className="mypage-card" >
        <Card.Header className="px-3" as="h4">
          <Link to={goToParentPath()}
            style={{ textDecoration: 'none', color: 'inherit', }}
            onMouseEnter={() => setIsArrowHovered(true)}
            onMouseLeave={() => setIsArrowHovered(false)} >
            <FontAwesomeIcon
              icon={faCaretSquareLeft}
              className="me-2"
              style={
                isArrowHovered
                  ? {} // Hover 시 스타일 제거
                  : { color: "#869fca" } // 기본 스타일
              }
            />
          </Link>
          관리자 계정</Card.Header>
        <Card.Title className="border border-bottom-0 px-3 pt-3">  등록</Card.Title>
        <Card.Body className="border border-top-0">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={4} className="text-center profile-section">
                <div {...getRootProps({ className: 'dropzone' })}
                  style={{
                    cursor: userAuth === 'SA' || userAuth === 'ADMIN' ? 'pointer' : 'not-allowed',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '150px',
                    height: '150px',
                    position: 'relative',
                    margin: '0 auto',
                  }}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <input {...getInputProps()} />
                  <Flex justifyContent="center" >
                    <img
                      src={avatar.length > 0 ? avatar[0].src : avatar[0].src}
                      alt=""
                      style={{
                        borderRadius: '50%', // 동그란 형태
                        width: '150px', // 원하는 너비
                        height: '150px', // 원하는 높이
                        objectFit: 'contain',

                      }}
                    />
                    {isHovered && ( // hover 시 텍스트 표시
                      <div style={{
                        position: 'absolute',
                        backgroundColor: 'rgba(0, 0, 0, 1)',
                        color: 'white',
                        padding: '10px',
                        borderRadius: '50%', // 동그란 형태
                        width: '150px', // 원하는 너비
                        height: '150px', // 원하는 높이
                        pointerEvents: 'none', // 클릭 방지
                        objectFit: 'cover', // 이미지 비율 유지
                        display: 'flex', // Flexbox 사용
                        justifyContent: 'center', // 수평 중앙 정렬
                        alignItems: 'center', // 수직 중앙 정렬
                        opacity: isHovered ? 0.8 : 1, // 투명도 적용

                      }}>

                        이미지 변경
                      </div>
                    )}
                  </Flex>
                </div>

              </Col>



              {/* Right Column (ID, Password, Name) */}
              <Col md={8}>


                <Row>
                  <Col>
                    <Form.Group className="mb-3" style={{ position: 'relative' }}>
                      <Form.Label>소속<span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Select
                        name="org"
                        onChange={handleComboChange}
                        value={formData.companyId}

                      >
                        <option value="" disabled>소속을 선택하세요</option>
                        {companies.map((company) => (
                          <option key={company.companyId} value={company.companyId}>
                            {company.org}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>

                    <Form.Group className="mb-3" style={{ position: 'relative' }}>
                      <Form.Label>아이디<span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control
                        type="text"
                        name="id"
                        value={formData.id}
                        onChange={handleChange}
                        className="input-hover"
                        maxLength='30'
                        placeholder="아이디를 입력해주세요."
                      />
                      {IdError &&
                        <div style={{ color: 'red' }}>
                          {IdError}
                        </div>} {/* 에러 메시지 표시 */}
                      {formData.id && (
                        <span
                          onClick={(e) => handleValueDelete('id', formData, setFormData)}
                          style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'transformY(-50%)',
                            zIndex: 9
                          }}
                        >
                          <AiFillCloseCircle />
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>


                {/* Password */}

                <Row>
                  <Col>
                    <Form.Group className="mb-3" style={{ position: 'relative' }}>
                      <Form.Label>비밀번호<span style={{ color: 'red' }}>*</span></Form.Label>
                      <InputGroup>
                        <FormControl
                          type="password"
                          name="password"
                          value={formData.password}
                          onChange={handleChange}
                          className="input-hover"
                          placeholder="비밀번호를 입력해주세요."
                        />

                      </InputGroup>
                      {formData.password && (
                        <span
                          onClick={() => handleValueDeleteWrapper('password')}
                          style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'transformY(-50%)',
                            zIndex: 9
                          }}
                        >
                          <AiFillCloseCircle />
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" style={{ position: 'relative' }}>
                      <Form.Label>비밀번호 확인<span style={{ color: 'red' }}>*</span></Form.Label>
                      <InputGroup>
                        <FormControl
                          type="password"
                          name="passwordConfirm"
                          value={formData.passwordConfirm}
                          onChange={handleChange}
                          className="input-hover"
                          placeholder="확인을 위해 비밀번호를 다시 입력해주세요."
                        />

                      </InputGroup>
                      {formData.passwordConfirm && (
                        <span
                          onClick={() => handleValueDeleteWrapper('passwordConfirm')}
                          style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'transformY(-50%)',
                            zIndex: 9
                          }}
                        >
                          <AiFillCloseCircle />
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                  {passwordError && (
                    <p style={{ color: 'red', fontSize: '0.8rem' }}>{passwordError}</p>
                  )}
                  <p style={{ fontSize: '0.8rem' }}>
                    비밀번호는 최소 [9]자 이상 ~ [16]자 이하의 영문 문자 [3]개와
                    숫자[1], 다음 특수문자 중 [1]자 이상을 포함해야 합니다.
                    <br />
                    ! @ # $ % ^ _ - + = { } [ ] : ; " ' ? , . / ~

                  </p>
                </Row>

                {/* Name */}
                <Row>
                  <Col>
                    <Form.Group className="mb-3" style={{ position: 'relative' }}>
                      <Form.Label>이름<span style={{ color: 'red' }}>*</span></Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="input-hover"
                        maxLength='30'
                        placeholder="이름을 입력해주세요."
                      />
                      {NameError &&
                        <div style={{ color: 'red' }}>
                          {NameError}
                        </div>} {/* 에러 메시지 표시 */}
                      {formData.name && (
                        <span
                          onClick={(e) => handleValueDelete('name', formData, setFormData)}
                          style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'transformY(-50%)',
                            zIndex: 9
                          }}
                        >
                          <AiFillCloseCircle />
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>역할</Form.Label>
                      <Form.Select
                        name="role"
                        onChange={handleComboChange}
                        value={formData.role}
                      >
                        {formData.org === "이니텍" && <option value="SA">시스템 관리자</option>}

                        <option value="ADMIN">최고 관리자</option>
                        <option value="MANAGER">운영 관리자</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="mb-3" style={{ position: 'relative' }}>
                  <Form.Label>설명</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    rows={3}
                    value={formData.description}
                    onChange={handleChange}
                    className="input-hover"
                    maxLength='800'
                  />
                  {DescError &&
                    <div style={{ color: 'red' }}>
                      {DescError}
                    </div>} {/* 에러 메시지 표시 */}
                  {formData.description && (
                    <span
                      onClick={(e) => handleValueDelete('description', formData, setFormData)}
                      style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'transformY(-50%)',
                        zIndex: 9
                      }}
                    >
                      <AiFillCloseCircle />
                    </span>
                  )}
                </Form.Group>

              </Col>
            </Row>

            {/* Cancel and Confirm Buttons */}
            <div className="d-flex justify-content-end mt-3">
              <Button variant="secondary" className="me-2" onClick={handleCancelClick}>
                취소
              </Button>
              <Button variant="primary" type="submit" disabled={!isFormValid()} onClick={handleConfirmClick}>
                등록
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>

      <CheckOutModal
        show={cancelmodalShow}
        onHide={() => setCancelModalShow(false)}
        onConfirm={handleCancelModal}
        title="확인" // 제목
        body="진행 중인 작업을 취소하시겠습니까?" // 본문 내용
        cancelText="취소" // 취소 버튼 텍스트
        confirmText="확인" // 확인 버튼 텍스트
        variant="info" // 원하는 variant
        CancelButton={true}
        SubmitButton={true}
      />


      <CheckOutModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onConfirm={handleCheckOutModal}
        title="확인" // 제목
        body="관리자 계정을 등록하시겠습니까?" // 본문 내용
        cancelText="취소" // 취소 버튼 텍스트
        confirmText="확인" // 확인 버튼 텍스트
        variant="info" // 원하는 variant
        CancelButton={true}
        SubmitButton={true}
      />

      <CheckOutModal
        show={confirmCheckShow}
        onHide={() => {
          setConfirmCheckShow(false); // 첫 번째 상태 변경
          setModalShow(false);        // 두 번째 상태 변경
        }}
        onConfirm={() => {
          setConfirmCheckShow(false); // 첫 번째 상태 변경
          setModalShow(false);        // 두 번째 상태 변경
          navigate('/safedb/SA/adminAccount');
        }}
        title="완료" // 제목
        body="관리자 계정 등록이 완료되었습니다." // 본문 내용
        cancelText="취소" // 취소 버튼 텍스트
        confirmText="확인" // 확인 버튼 텍스트
        variant="success" // 원하는 variant

        SubmitButton={true}
      />
    </div >


  );
};

export default AddAdminAccount;
