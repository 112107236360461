import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import { useAppContext } from 'providers/AppProvider';
import { Card, Button, ButtonGroup, } from 'react-bootstrap';
import * as echarts from 'echarts/core';
import { TitleComponent, TooltipComponent, GridComponent } from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import ReactEchart from 'components/common/ReactEchart';
import { fetchAlgoComp } from 'hooks/CallApi';
import { formatCountStyle2 } from 'components/common/SunnyUtils';

echarts.use([TitleComponent, TooltipComponent, GridComponent, PieChart, CanvasRenderer]);

const AlgorithmCompare = () => {
  const { getThemeColor } = useAppContext();
  const encryptionChartRef = useRef(null);
  const {
    config: { dashboardDate, dashboardDateValue, Chart_startdate, Chart_enddate },
  } = useAppContext();

  const [chartData, setChartData] = useState({
    algoName: [],
    requestCnt: [],
    requestPer: [],
    totalCnt: 0,
  });
  const [view, setView] = useState('cf'); // 'top' or 'bottom'

  const [dateRange, setDateRange] = useState({
    start: Chart_startdate,
    end: Chart_enddate
  });
  useEffect(() => {
    // Chart_startdate 또는 Chart_enddate가 변경될 때 상태 업데이트
    setDateRange({
      start: Chart_startdate,
      end: Chart_enddate
    });
  }, [Chart_startdate, Chart_enddate]);

  useEffect(() => {
    const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const fetchData = async () => {
      try {
        const data = await fetchAlgoComp({
          period: dashboardDateValue,
          start: dateRange.start,
          end: dateRange.end,
          timeZone: clientTimeZone,
          type: view
        });


        const { algoInfoList = [], totalCnt = 0 } = data.data || {};

        // 차트 데이터 설정
        setChartData({
          algoName: algoInfoList.map((item) => item.algoName),
          requestCnt: algoInfoList.map((item) => item.requestCnt),
          requestPer: algoInfoList.map((item) => item.requestPer),
          totalCnt,
        });

      } catch (error) {
        console.error('Error fetching algorithm comparison data:', error);
      }
    };

    fetchData();
  }, [dashboardDate, dateRange, view]);

  const isDataEmpty = chartData.totalCnt === 0;

  const getOption = (titleText, algoName, requestCnt, requestPer) => ({
    legend: {
      orient: 'horizontal',
      bottom: 0,
      left: 'center',
      itemWidth: 10,
      itemHeight: 10,
      textStyle: {
        color: getThemeColor('gray-600'),
        fontSize: 12,
      },
      data: algoName, // Always show legend items
    },
    series: [
      {
        type: 'pie',
        radius: ['50%', '70%'],
        center: ['50%', '45%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 3,
          borderColor: getThemeColor('gray-100'),
          borderWidth: 2,
        },
        label: {
          show: true,
          position: 'center',
          fontSize: 10,
          fontWeight: 'bold',
          color: getThemeColor('gray-700'),
          formatter: isDataEmpty ? `{b|${titleText}}\n{c|0건}` : `{b|${titleText}}\n{c|${formatCountStyle2(chartData.totalCnt)}건}`,
          rich: {
            b: {
              fontSize: 14,
              lineHeight: 12,
              color: getThemeColor('gray-700'),
            },
            c: {
              fontSize: 14,
              lineHeight: 16,
              fontWeight: 'bold',
              color: getThemeColor('gray-900'),
            },
          },
        },
        labelLine: { show: false },
        data: isDataEmpty
          ? [
            { value: 1, itemStyle: { color: '#f0f0f0' } },

          ]
          : algoName.map((name, index) => ({
            value: requestCnt[index],
            name,
            itemStyle: {
              color: getThemeColor(
                index === 0 ? 'primary' : index === 1 ? 'success' : 'info'
              ),
            },
          })),
      },
    ],
    tooltip: isDataEmpty
      ? { show: false } // 빈 데이터일 때는 툴팁 비활성화
      : {
        show: true,
        trigger: 'item',
        padding: [7, 10],
        backgroundColor: getThemeColor('gray-100'),
        borderColor: getThemeColor('gray-300'),
        textStyle: { color: getThemeColor('gray-1100') },
        borderWidth: 1,
        transitionDuration: 0,
        axisPointer: { type: 'none' },
        formatter: ({ name, value, percent }) =>
          `${name}<br/>요청 건수: ${formatCountStyle2(value)}건<br/>요청 비율: ${percent.toFixed(
            2
          )}%`,

      },
  });

  return (
    <Card className="h-md-100">
      <Card.Header className="pb-0 d-flex align-items-center">
        <h5 style={{ margin: 0 }}>알고리즘 요청 비교</h5>
        <span
          style={{
            marginLeft: '8px',
            marginTop: 0,
            marginBottom: 0,
            padding: '2px 8px',
            backgroundColor: '#A1C4E3', // 배경색 파란색
            color: 'white', // 텍스트 색상 흰색
            borderRadius: '4px',
            fontSize: '12px',
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
          }}
        >
          {dashboardDate}
        </span>
      </Card.Header>
      <Card.Body as={Flex} className="pt-0" alignItems="center" justifyContent="space-between">
        <ButtonGroup
          className="mb-3"
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px', // or left: '10px' to align with the left side
            zIndex: 10
          }}
        >
          <Button
            style={{ fontSize: '0.7rem' }}
            variant={view === 'cf' ? 'primary' : 'outline-primary'}
            onClick={() => setView('cf')}
          >
            CF
          </Button>
          <Button
            style={{ fontSize: '0.7rem' }}
            variant={view === 'api' ? 'primary' : 'outline-primary'}
            onClick={() => setView('api')}
          >
            API
          </Button>
          <Button
            style={{ fontSize: '0.7rem' }}
            variant={view === 'sdk' ? 'primary' : 'outline-primary'}
            onClick={() => setView('sdk')}
            disabled
          >
            SDK
          </Button>
        </ButtonGroup>
        <ReactEchart
          echarts={echarts}
          option={getOption(
            '알고리즘',
            chartData.algoName,
            chartData.requestCnt,
            chartData.requestPer
          )}
          ref={encryptionChartRef}
          style={{ width: '100%', height: '15rem' }}
        />
      </Card.Body>
    </Card>
  );
};

AlgorithmCompare.propTypes = {
  totalRequests: PropTypes.number,
};

export default AlgorithmCompare;
