import axios from 'axios';
import { toast } from 'react-toastify';


// 기본 설정으로 `api.safedb.biz` 사용
const api = axios.create({
    baseURL: 'https://api.safedb.biz' // 기본 API URL
});

// 토큰 발급 및 재발급용 인스턴스 (auth.safedb.biz 사용)
const authApi = axios.create({
    baseURL: 'https://auth.safedb.biz' // 인증용 API URL
});

// 로그인 API 호출 함수
export const loginUser = async ({ username, password }) => {
    try {
        const response = await authApi.post('/auth/login', { username, password });
        return response.data; // 토큰 및 리프레시 토큰을 반환
    } catch (error) {
        console.error('로그인 요청 에러:', error);
        throw error;
    }
};

// 가입 신청 시 인증번호 보내는 API
export const sendAuthCode = async ({ applyName, applyTel, applyEmail, companyName }) => {
    try {
        const response = await api.post('/apply/sendAuthCode', {
            applyName,
            applyTel,
            applyEmail,
            companyName,
        });
        return response.data; // {boolean} 타입의 응답을 반환
    } catch (error) {
        console.error('가입 신청 에러:', error);
        throw error;
    }
};

// 가입 신청 시 유효 인증코드 확인 API
export const checkAuthCode = async ({ email, code }) => {
    try {
        const response = await api.post('/apply/checkAuthCode', {
            email,
            code
        });
        return response.data; // {boolean} 타입의 응답을 반환
    } catch (error) {
        console.error('유효 인증코드 확인 에러:', error);
        throw error;
    }
};

// 이미지 업로드 API
export const ImagesUpload = async (imageFile, type) => {

    const headers = {};

    const token = sessionStorage.getItem('token');
    headers.Authorization = `Bearer ${token}`;
    try {
        // FormData 객체 생성
        const formData = new FormData();
        formData.append('type', type); // 'manager' 또는 'company'
        formData.append('image', imageFile); // 업로드할 이미지 파일

        // 요청 설정
        const response = await api.post('/images/upload', formData, {
            headers: {
                ...headers,
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        // 에러 처리

        if (error.response) {
            console.error('CALL APIs 응답 데이터:', error.response.data);
        }
        throw error;
    }
};

// 관리자의 사이트 이미지 업로드  / 고객사의 이미지 업로드 API
export const ImagesUpload_Site = async (imageFile, type) => {

    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');

    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    try {
        // FormData 객체 생성
        const formData = new FormData();
        formData.append('type', type); // 'manager' 또는 'company'
        formData.append('image', imageFile); // 업로드할 이미지 파일

        // 요청 설정
        const response = await api.post('/images/upload', formData, {
            headers: {
                ...headers,
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    } catch (error) {
        // 에러 처리

        if (error.response) {
            console.error('CALL APIs 응답 데이터:', error.response.data);
        }
        throw error;
    }
};

// 회사 목록 조회 API 
export const fetchCompanyList = async (page = 0, size = 10) => {
    try {
        const response = await api.get('/companies', {
            params: { page, size },
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}` // 토큰을 세션 스토리지에서 가져와 헤더에 포함
            }

        });
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('회사 목록 조회 에러:', error);
        throw error;
    }
};

// 회사 생성 API
export const createCompany = async (data) => {
    try {
        const response = await api.post(`/companies`, data, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`, // 토큰을 세션 스토리지에서 가져옴 
            },
        });
        return response.data;
    } catch (error) {
        console.error('회사 생성 에러:', error);
        throw error;
    }
};

// 회사 정보 변경 API
export const updateCompanyInfo = async (companyId, data) => {
    try {
        const response = await api.put(`/companies/${companyId}`, data, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}` // 토큰을 세션 스토리지에서 가져옴
            }
        });
        return response.data;
    } catch (error) {
        console.error('회사 정보 업데이트 에러:', error);
        throw error;
    }
};
// 회사 삭제 API
export const deleteCompanyInfo = async (companyId) => {
    try {
        const response = await api.delete(`/companies/${companyId}`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}` // 토큰을 세션 스토리지에서 가져옴
            }
        });
        return response.data;
    } catch (error) {
        console.error('회사 정보 삭제 에러:', error);
        throw error;
    }
};
// 회사 다중 삭제 API
export const deleteMultiCompanyInfo = async (companyIds) => {
    console.log("companyIds", companyIds);
    try {
        const response = await api.delete(`/companies`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}` // 토큰을 세션 스토리지에서 가져옴
            },
            data: { companyIds }, // 요청 본문에 `companyIds` 전달

        });

        return response.data;
    } catch (error) {
        console.error('회사 정보 삭제 에러:', error);
        throw error;
    }
};

// 회사 조회 API 
export const fetchCompanyInfo = async (companyId) => {
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    try {
        const response = await api.get(`/companies/${companyId}`, {

            headers

        });
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('관리자 계정 목록 조회 에러:', error);
        throw error;
    }
};


// 관리자 계정 목록 조회 API  (관리자)
export const fetchAdminList = async (page = 0, size = 10) => {

    try {
        const response = await api.get('/users', {
            params: { page, size },
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}` // 토큰을 세션 스토리지에서 가져와 헤더에 포함
            }

        });
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('관리자 계정 목록 조회 에러:', error);
        throw error;
    }
};

// 관리자 계정 목록 조회 API  (고객사)
export const fetchAdminList_Site = async (page = 0, size = 10) => {

    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');

    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }


    try {
        const response = await api.get('/users', {
            params: { page, size },
            headers

        });
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('관리자 계정 목록 조회 에러:', error);
        throw error;
    }
};


// 관리자 삭제 API
export const deleteAdminInfo = async (userUuid) => {
    const headers = {};

    const token = sessionStorage.getItem('token');

    headers.Authorization = `Bearer ${token}`;
    // }

    try {
        const response = await api.delete(`/users/${userUuid}`, {
            headers
        });
        if (response.status === 204) {
            return { success: true }; // 성공 응답 반환
        }
        return response.data;
    } catch (error) {
        console.error('관리자 정보 삭제 에러:', error);
        throw error;
    }
};
// 관리자 다중 삭제 API
export const deleteMultiAdminInfo = async (userUuids) => {
    const headers = {};

    const token = sessionStorage.getItem('token');

    headers.Authorization = `Bearer ${token}`;

    try {
        const response = await api.delete(`/users`, {
            headers,
            data: { userUuids }, // 요청 본문에 `companyIds` 전달

        });
        if (response.status === 204) {
            return { success: true }; // 성공 응답 반환
        }
        return response.data;
    } catch (error) {
        console.error('회사 정보 삭제 에러:', error);
        throw error;
    }
};

// 관리자 삭제 API_Site
export const deleteAdminInfo_Site = async (userUuid) => {
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    console.log("삭제 uuid ", userUuid);
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    try {
        const response = await api.delete(`/users/${userUuid}`, {
            headers
        });
        if (response.status === 204) {
            return { success: true }; // 성공 응답 반환
        }
        return response.data;
    } catch (error) {
        console.error('관리자 정보 삭제 에러:', error);
        throw error;
    }
};
// 관리자 다중 삭제 API_Site
export const deleteMultiAdminInfo_Site = async (userUuids) => {
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');

    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    try {
        const response = await api.delete(`/users`, {
            headers,
            data: { userUuids }, // 요청 본문에 `companyIds` 전달

        });
        if (response.status === 204) {
            return { success: true }; // 성공 응답 반환
        }
        return response.data;
    } catch (error) {
        console.error('회사 정보 삭제 에러:', error);
        throw error;
    }
};


// 로그인 정보 조회 API 
export const getAdminInfo = async () => {
    try {
        const response = await api.get('/users/login', {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}` // 토큰을 세션 스토리지에서 가져와 헤더에 포함
            }
        });
        return { adminInfo: response.data, isPasswordChangeNeeded: false, changeReasonCode: null };
    } catch (error) {
        if (error.response && (error.response.data.code === -5 || error.response.data.code === -6)) {
            // 비밀번호 만료 에러 -5, 비밀번호 변경 필요 -6
            const changeReasonCode = error.response.data.code;
            return { adminInfo: null, isPasswordChangeNeeded: true, changeReasonCode }; // 비밀번호 변경 필요 표시와 원인 코드 반환
        }

        // 기타 에러가 발생한 경우 에러를 던짐
        return { adminInfo: null, isPasswordChangeNeeded: false, error };
    }
};

// 관리자 정보 변경 API
export const updateAdminInfo = async (userUuid, data) => {
    try {
        const response = await api.put(`/users/${userUuid}`, data, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}` // 토큰을 세션 스토리지에서 가져옴
            }
        });
        return response.data;
    } catch (error) {
        console.error('관리자 정보 업데이트 에러:', error);
        throw error;
    }
};

// 관리자 계정 정보 변경 시 회사 리스트 호출 API
export const getCompanyList = async () => {
    try {
        const response = await api.get('/companies/list', {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}` // 토큰을 세션 스토리지에서 가져와 헤더에 포함
            }

        });

        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.log("에러 발생", error);
        throw error;
    }
};

// 관리자 등록 API
export const addAdminUsers = async (data) => {
    try {
        const response = await api.post(`/users`, data, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}` // 토큰을 세션 스토리지에서 가져옴
            }
        });

        return response;
    } catch (error) {
        console.log("에러 발생", error);
        throw error;
    }
};

// 관리자 정보 변경 API (SITE)
export const updateAdminInfo_Site = async (userUuid, data) => {
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    try {
        const response = await api.put(`/users/${userUuid}`, data, {
            headers
        });
        return response.data;
    } catch (error) {
        console.error('관리자 정보 업데이트 에러:', error);
        throw error;
    }
};

// 고객사 토큰 발급 API 호출 함수
export const requestSiteToken = async ({ username, refreshToken, companyId }) => {
    try {
        const response = await authApi.post('/auth/impersonation', {
            username,
            refreshToken,
            companyId
        });

        return response.data; // 발급된 토큰 반환

    } catch (error) {
        console.log("에러 발생", error);
        throw error;
    }
};

// 비밀번호 변경 API
export const changePassword = async (data) => {
    try {
        const response = await api.put(`/users/password`, data, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}` // 토큰을 세션 스토리지에서 가져옴
            }
        });
        return response.data;
    } catch (error) {
        console.log("에러 발생", error);
        throw error;
    }
};

// 비밀번호 변경 API for 관리자
export const changePassword_forAdmin = async (userUuid, data) => {

    const headers = {};

    const token = sessionStorage.getItem('token');
    headers.Authorization = `Bearer ${token}`;

    try {
        const response = await api.put(`/users/${userUuid}/password`, data, {
            headers
        });

        return response.status;
    } catch (error) {
        console.error(' 관ㄹ지ㅏ용비밀번호 변경 에러:', error);
        throw error;
    }
};

// // API KEY 목록 조회 API 
export const fetchApiKeyList = async (page = 0, size = 10) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    try {
        const response = await api.get('/apikeys', {
            params: { page, size },
            headers

        });
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('관리자 계정 목록 조회 에러:', error);
        throw error;
    }
};


// API 인증키  정보 변경 API
export const updateApiKeyInfo = async (apikeyUuid, data) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    try {
        const response = await api.put(`/apikeys/${apikeyUuid}`, data, {
            headers
        });
        return response.data;
    } catch (error) {
        console.error('API 인증키 정보 업데이트 에러:', error);
        throw error;
    }
};

// // 인증키 삭제 API 
export const deleteApiKeyInfo = async (apikeyUuid) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    try {
        const response = await api.delete(`/apikeys/${apikeyUuid}`, {

            headers

        });
        return response; // API 응답 데이터를 반환
    } catch (error) {
        console.log("에러 발생", error);
        throw error;
    }
};

// API 인증키 UUID 재발급 API
export const remakeApiKeyInfo = async (apikeyUuid) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    try {
        const response = await api.put(`/apikeys/${apikeyUuid}/reissuance`, {}, {
            headers
        });
        return response.data;
    } catch (error) {
        console.error('API 키 재발급 에러:', error);
        throw error;
    }
};
// API 인증키 추가 API
export const addApiKey = async (data) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    try {
        const response = await api.post('/apikeys', data, {
            headers
        });
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('그룹목록 조회 에러:', error);
        throw error;
    }
};




// // 그룹관리 목록 조회 API 
export const fetchGroupList = async (page = 0, size = 10) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    try {
        const response = await api.get('/groups', {
            params: { page, size },
            headers

        });
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('그룹목록 조회 에러:', error);
        throw error;
    }
};

// // 그룹관리 항목 삭제 API 
export const deleteGroupList = async (apigroup_uuid) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    try {
        const response = await api.delete(`/groups/${apigroup_uuid}`, {

            headers

        });

        return response; // API 응답 데이터를 반환
    } catch (error) {
        console.log("에러 발생", error);
        throw error;
    }
};

// // 그룹 목록 단순 조회 API 
export const SelectGroupList = async () => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');

    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    try {
        const response = await api.get('/groups/list', {
            headers

        });
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('그룹 목록 단순 조회 에러:', error);
        throw error;
    }
};


// 그룹 등록 API
export const addGroup = async ({ groupName, groupDescription }) => {
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');

    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    try {
        const response = await api.post('/groups', {
            groupName,
            groupDescription
        }, { headers });
        return response.data; // {boolean} 타입의 응답을 반환
    } catch (error) {
        console.error('그룹 추가 에러:', error);
        throw error;
    }
};







// // 암호키 목록 조회 API 
export const fetchEncKeyList = async (page = 0, size = 10) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    try {
        const response = await api.get('/policy', {
            params: { page, size },
            headers

        });
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('암호키 목록 조회 에러:', error);
        throw error;
    }
};

// // 암호키 삭제 API 
export const deleteEncKey = async (policyUuid) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    try {
        const response = await api.delete(`/policy/${policyUuid}`, {

            headers

        });

        return response; // API 응답 데이터를 반환
    } catch (error) {
        console.log("에러 발생", error);
        throw error;
    }
};

// // 암호키 상세 조회 API 
export const detailEncKey = async (policyUuid) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    try {
        const response = await api.get(`/policy/${policyUuid}`, {
            headers
        });
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('암호키 상세 조회 에러:', error);
        throw error;
    }
};

// // 암호키 등록 세팅 값 조회 API 
export const settingEncKey = async () => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    try {
        const response = await api.get('/policy/settings', {
            headers
        });
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('암호키 세팅 값 조회 에러:', error);
        throw error;
    }
};


// // 암호키 상세내 적용대상목록 조회 API 
// export const fetchEncPolicyList = async (policyUuid) => {
//     // 기본 헤더 구성
//     const headers = {};

//     // siteToken이 존재하면 사용하고, 없으면 token을 사용
//     const siteToken = sessionStorage.getItem('siteToken');
//     const token = sessionStorage.getItem('token');
//     if (siteToken) {
//         headers.Authorization = `Bearer ${siteToken}`;
//     } else if (token) {
//         headers.Authorization = `Bearer ${token}`;
//     }
//     try {
//         const response = await api.get(`/policy/${policyUuid}/apikeys`, {
//             headers
//         });
//         //console.log(response);
//         return response.data; // API 응답 데이터를 반환
//     } catch (error) {
//         console.error('암호키 상세 적용대상목록 조회 에러:', error);
//         throw error;
//     }
// };

export const fetchEncPolicyList = async (policyUuid, page = 0, size = 10) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    try {
        const response = await api.get(`/policy/${policyUuid}/apikeys`, {
            params: { page, size }, // 페이지네이션 파라미터 추가
            headers
        });

        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.log("에러 발생", error);
        throw error;
    }
};

// // 암호키 등록 시  적용대상목록 조회 API 
export const addEncPolicyList = async () => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    try {
        const response = await api.get('policy/settings/apikeys', {
            headers
        });
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.log("에러 발생", error);
        throw error;
    }
};

// 암호화 키 정책 변경 API
export const updateEncryptKeyPolicy = async (policyUuid, apikeyPolicies) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    try {
        const response = await api.put(`/policy/${policyUuid}`, { apikeyPolicies }, {
            headers
        });
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('암호화 키 정책 변경 에러:', error);
        throw error;
    }
};

// 암호화 키 정책 추가 API
export const addEncryptKeyPolicy = async (policyData) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    try {
        const response = await api.post('/policy', policyData, {
            headers
        });

        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.log("에러 발생", error);
        throw error;
    }
};

//////////////////////////////////////////////////////////////////////////////////////// 공유키 관련

// // 공유키 목록 조회 API (암호키 등록 사용)
export const showPubKeyList = async () => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    try {
        const response = await api.get('policy/shared/list', {

            headers
        });

        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.log("에러 발생", error);
        throw error;
    }
};

// // 공유키 목록 조회 API 
export const fetchPubKeyList = async (page = 0, size = 10) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    try {
        const response = await api.get('policy/shared', {
            params: { page, size },
            headers
        });

        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.log("에러 발생", error);
        throw error;
    }
};


// 공유키 등록 API 
export const addPubKey = async (data) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    try {
        const response = await api.post('/policy/shared', data, {
            headers
        });

        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.log("에러 발생", error);
        throw error;
    }
};

// 공유키 변경 API
export const updatePubKey = async (sharedKeyUuid, description) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    try {
        const response = await api.put(`/policy/shared/${sharedKeyUuid}`, { description }, {
            headers
        });
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('공유키 변경 에러:', error);
        throw error;
    }
};

// // 공유키 삭제 API 
export const deletePubKey = async (sharedKeyUuid) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    try {
        const response = await api.delete(`/policy/shared/${sharedKeyUuid}`, {

            headers

        });

        return response; // API 응답 데이터를 반환
    } catch (error) {
        console.log("에러 발생", error);
        throw error;
    }
};
//////////////////////////////////////////////////////////////////////////////////////// 대시보드 관련
// 고객사  대시보드 요약
export const fetchSummaryCounts = async ({ period, start, end, timeZone, type }) => {
    // 기본 헤더 구성
    const headers = {};
    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    try {
        const response = await api.post('/chart/allCnt',
            { period, start, end, timeZone, type },
            {
                headers
            }
        );

        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.log("에러 발생", error);
        throw error;
    }
};
// 고객사 대시보드 지난 처리량
export const fetchDataSize = async ({ period, start, end, timeZone, type }) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    try {
        const response = await api.post('/chart/dataSize',
            { period, start, end, timeZone, type },
            {
                headers
            }
        );
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.log("에러 발생", error);
        throw error;
    }
};

// 고객사 대시보드 지난 암복호화 요청량
export const fetchRecentlyEncDec = async ({ period, start, end, timeZone, type }) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    try {
        const response = await api.post('/chart/encdecCnt',
            { period, start, end, timeZone, type },
            {
                headers
            }
        );
        // //console.log("encdecCnt chart ", response);
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('요약 데이터 조회 에러:', error);
        throw error;
    }
};

// 고객사 대시보드 인증키 타입별 요청 비교
export const fetchKeyTypeComp = async ({ period, start, end, timeZone }) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    try {
        const response = await api.post('/chart/type',
            { period, start, end, timeZone },
            {
                headers
            }
        );
        // //console.log("type chart ", response);
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('요약 데이터 조회 에러:', error);
        throw error;
    }
};

// 고객사 대시보드 알고리즘 요청 비교
export const fetchAlgoComp = async ({ period, start, end, timeZone, type }) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    try {
        const response = await api.post('/chart/algo',
            { period, start, end, timeZone, type },
            {
                headers
            }
        );
        //console.log("algo chart ", response);
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('요약 데이터 조회 에러:', error);
        throw error;
    }
};

// 고객사 대시보드 암호키 요청량 비교
export const fetchEncKeyComp = async ({ period, start, end, timeZone, order, type }) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    try {
        //console.log("api period", period);
        //console.log("api timeZone", timeZone);
        //console.log("api order", order);
        const response = await api.post('/chart/topPolicy',
            { period, start, end, timeZone, order, type },
            {
                headers
            }

        );
        //console.log("topPolicy chart ", response);
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('요약 데이터 조회 에러:', error);
        throw error;
    }
};


// 고객사 대시보드 6개월간 요금 청구량
export const fetchSixMonthPay = async () => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    try {
        const response = await api.post('/chart/billing', {}, { headers });
        //console.log("six chart ", response);
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('요약 데이터 조회 에러:', error);
        throw error;
    }
};

//////////////////////////////////////////////////////////////////////////////////////// 총괄대시보드 관련
// 총괄대시보드 요약 차트
export const allReqSummary = async ({ period, start, end, timeZone }) => {
    // 기본 헤더 구성
    const headers = {};
    const token = sessionStorage.getItem('token');
    headers.Authorization = `Bearer ${token}`;


    try {

        const startTime = performance.now(); // 시작 시간 기록

        const response = await api.post('/inichart/allReqSummary',
            { period, start, end, timeZone },
            { headers });
        const endTime = performance.now(); // 끝나는 시간 기록

        //console.log(`allReqSummary 호출 소요 시간: ${(endTime - startTime).toFixed(2)}ms`); // 소요 시간 계산 및 출력
        //console.log('SA 요약 데이터', response.data);
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('[SA]요약 데이터 조회 에러:', error);
        throw error;
    }
};

// 고객사 이상 순위 차트
export const anomaliesRank = async ({ period, start, end, timeZone, type }) => {
    // 기본 헤더 구성
    const headers = {};
    const token = sessionStorage.getItem('token');
    headers.Authorization = `Bearer ${token}`;


    try {

        const startTime = performance.now(); // 시작 시간 기록

        const response = await api.post('/inichart/anomaliesRank',
            { period, start, end, timeZone, type },
            { headers });
        const endTime = performance.now(); // 끝나는 시간 기록

        //console.log(`anomaliesRank 호출 소요 시간: ${(endTime - startTime).toFixed(2)}ms`); // 소요 시간 계산 및 출력
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('[SA]고객사 이상 순위 차트 데이터 조회 에러:', error);
        throw error;
    }
};

// 고객사 활동 순위 차트
export const activeRank = async ({ period, start, end, timeZone, type }) => {
    // 기본 헤더 구성
    const headers = {};
    const token = sessionStorage.getItem('token');
    headers.Authorization = `Bearer ${token}`;


    try {

        const startTime = performance.now(); // 시작 시간 기록

        const response = await api.post('/inichart/activeRank',
            { period, start, end, timeZone, type },
            { headers });
        const endTime = performance.now(); // 끝나는 시간 기록

        //console.log(`activeRank 호출 소요 시간: ${(endTime - startTime).toFixed(2)}ms`); // 소요 시간 계산 및 출력
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('[SA]고객사 활동 순위 차트 데이터 조회 에러:', error);
        throw error;
    }
};

// 평균 요청량 순위 차트
export const avgRequest = async ({ timeZone, type }) => {
    // 기본 헤더 구성
    const headers = {};
    const token = sessionStorage.getItem('token');
    headers.Authorization = `Bearer ${token}`;


    try {
        //console.log("시작!");
        const startTime = performance.now(); // 시작 시간 기록

        const response = await api.post('/inichart/avgRequest',
            { timeZone, type },
            { headers });
        const endTime = performance.now(); // 끝나는 시간 기록

        //console.log(`avgRequest 호출 소요 시간: ${(endTime - startTime).toFixed(2)}ms`); // 소요 시간 계산 및 출력
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('[SA]평균 요청량 순위 차트 데이터 조회 에러:', error);
        throw error;
    }
};


// 일별 최대 처리량 시간 분포  차트
export const timeDistribution = async ({ timeZone, type }) => {
    // 기본 헤더 구성
    const headers = {};
    const token = sessionStorage.getItem('token');
    headers.Authorization = `Bearer ${token}`;


    try {
        console.log(" timeDistribution 시작!");
        const startTime = performance.now(); // 시작 시간 기록

        const response = await api.post('/inichart/timeDistribution',
            { timeZone, type },
            { headers });
        const endTime = performance.now(); // 끝나는 시간 기록
        console.log("timeDistribution Response", response);
        console.log(`timeDistribution 호출 소요 시간: ${(endTime - startTime).toFixed(2)}ms`); // 소요 시간 계산 및 출력
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('[SA]평균 요청량 순위 차트 데이터 조회 에러:', error);
        throw error;
    }
};

// 시간별 평균 처리 속도  차트
export const hourlyAvg = async ({ timeZone, type }) => {
    // 기본 헤더 구성
    const headers = {};
    const token = sessionStorage.getItem('token');
    headers.Authorization = `Bearer ${token}`;


    try {
        //console.log("hourlyAvg 시작!");
        const startTime = performance.now(); // 시작 시간 기록

        const response = await api.post('/inichart/hourlyAvg',
            { timeZone, type },
            { headers });
        const endTime = performance.now(); // 끝나는 시간 기록
        //console.log('hourlyAvg response', response);
        //console.log(`hourlyAvg 호출 소요 시간: ${(endTime - startTime).toFixed(2)}ms`); // 소요 시간 계산 및 출력
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('[SA]시간별 평균 요청량 순위 차트 데이터 조회 에러:', error);
        throw error;
    }
};



// 고객사별 암호키 유휴율 차트
export const policyPerRank = async ({ timeZone }) => {
    // 기본 헤더 구성
    const headers = {};
    const token = sessionStorage.getItem('token');
    headers.Authorization = `Bearer ${token}`;


    try {
        //console.log(" 전체 알고리즘별 요청량 차트 시작!");
        const startTime = performance.now(); // 시작 시간 기록
        const response = await api.post('/inichart/policyPerRank',
            { timeZone },
            { headers });
        const endTime = performance.now(); // 끝나는 시간 기록 
        //console.log(`전체 알고리즘별 요청량 API 호출 소요 시간: ${(endTime - startTime).toFixed(2)}ms`); // 소요 시간 계산 및 출력
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('[SA]고객사별 암호키 유휴율 차트 데이터 조회 에러:', error);
        throw error;
    }
};



// 고객사별 암호키 유휴 기간 차트
export const policyPeriodRank = async ({ timeZone }) => {
    // 기본 헤더 구성
    const headers = {};
    const token = sessionStorage.getItem('token');
    headers.Authorization = `Bearer ${token}`;


    try {
        //console.log(" 전체 알고리즘별 요청량 차트 시작!");
        const startTime = performance.now(); // 시작 시간 기록
        const response = await api.post('/inichart/policyPeriodRank',
            { timeZone },
            { headers });
        const endTime = performance.now(); // 끝나는 시간 기록 
        //console.log(`전체 알고리즘별 요청량 API 호출 소요 시간: ${(endTime - startTime).toFixed(2)}ms`); // 소요 시간 계산 및 출력
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('[SA]고객사별 암호키 유휴 기간 차트 데이터 조회 에러:', error);
        throw error;
    }
};

export const totalAlgoRequest = async ({ period, start, end, timeZone }) => {
    // 기본 헤더 구성
    const headers = {};
    const token = sessionStorage.getItem('token');
    headers.Authorization = `Bearer ${token}`;


    try {
        //console.log(" 전체 알고리즘별 요청량 차트 시작!");
        const startTime = performance.now(); // 시작 시간 기록
        const response = await api.post('/inichart/totalAlgoRequest',
            { period, start, end, timeZone },
            { headers });
        const endTime = performance.now(); // 끝나는 시간 기록 
        //console.log(`전체 알고리즘별 요청량 API 호출 소요 시간: ${(endTime - startTime).toFixed(2)}ms`); // 소요 시간 계산 및 출력
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('[SA]전체 알고리즘별 요청량 차트 데이터 조회 에러:', error);
        throw error;
    }
};

//////////////////////////////////////////////////////////////////////////////////////// 모니터링 관련
// // 관리 로그 조회 API 
// export const fetchManageLog = async (page = 0, size = 10, data) => {
//     // 기본 헤더 구성
//     const headers = {};

//     // siteToken이 존재하면 사용하고, 없으면 token을 사용
//     const siteToken = sessionStorage.getItem('siteToken');
//     const token = sessionStorage.getItem('token');
//     if (siteToken) {
//         headers.Authorization = `Bearer ${siteToken}`;
//     } else if (token) {
//         headers.Authorization = `Bearer ${token}`;
//     }

//     try {
//         const response = await api.post('/logs/management', {
//             params: { page, size, data },
//             headers: {
//                 Authorization: `Bearer ${sessionStorage.getItem('token')}` // 토큰을 세션 스토리지에서 가져와 헤더에 포함
//             }

//         });
//         //console.log("관리로그", response);
//         return response.data; // API 응답 데이터를 반환
//     } catch (error) {
//         console.error('관리로그 조회 에러:', error);
//         throw error;
//     }
// };
export const fetchManageLog = async ({
    page = 0,
    size = 10,
    searchStartTime,
    searchEndTime,
    timeZone,
    userId,
    userRole,
    taskHistory,
    result,
    insertTime = 'desc'
}) => {
    const headers = {};

    // 토큰 설정 (siteToken 우선 사용)
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    // API 요청 본문 구성
    const requestBody = {
        searchStartTime,
        searchEndTime,
        timeZone,
        userId,
        userRole,
        taskHistory,
        result,
        order: {
            'insertTime': insertTime
        },
    };
    const params = { page, size };

    //console.log("관리 로그 요청 본문:", JSON.stringify(requestBody, null, 2)); // 요청 본문 출력

    try {
        // API 호출
        const response = await api.post('/logs/management', requestBody, {
            headers,
            params
        });

        //console.log('관리 로그 데이터:', response.data);
        return response.data; // API 응답 데이터 반환
    } catch (error) {
        console.error('관리 로그 조회 에러:', error);
        throw error;
    }
};
// // 암복호화 로그 조회 API 
export const fetchEncDecLog = async ({
    page = 0,
    size = 10,
    searchStartTime,
    searchEndTime,
    timeZone,
    policyName,
    algo,
    cryptoMethod,
    startTime = 'desc',
    endTime = 'desc',
    requestTotalCnt = 'desc',
    successCnt = 'desc',
    failCnt = 'desc'
}) => {
    // 기본 헤더 구성
    const headers = {};

    // siteToken이 존재하면 사용하고, 없으면 token을 사용
    const siteToken = sessionStorage.getItem('siteToken');
    const token = sessionStorage.getItem('token');
    if (siteToken) {
        headers.Authorization = `Bearer ${siteToken}`;
    } else if (token) {
        headers.Authorization = `Bearer ${token}`;
    }
    const requestBody = {
        searchStartTime,
        searchEndTime,
        timeZone,
        policyName,
        algo,
        cryptoMethod,
        order: {
            'startTime': startTime,
            'endTime': endTime,
            'requestTotalCnt': requestTotalCnt,
            'successCnt': successCnt,
            'failCnt': failCnt,
        },
    };
    const params = { page, size };

    //console.log("암복호화 로그 요청 본문:", JSON.stringify(requestBody, null, 2)); // 요청 본문 출력

    try {
        const response = await api.post('/logs/crypto', requestBody, {
            headers,
            params
        });

        //console.log("암복호화 로그", response);
        return response.data; // API 응답 데이터를 반환
    } catch (error) {
        console.error('암복호화 조회 에러:', error);
        throw error;
    }
};


/////////////////////////////////////////////////////////////////////////////////////// 토큰 유지관련
// 요청 인터셉터
api.interceptors.request.use(
    (config) => {
        const siteToken = sessionStorage.getItem('siteToken');
        const token = sessionStorage.getItem('token');

        // siteToken이 없을 때만 일반 token을 설정
        if (!siteToken && token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => Promise.reject(error)
);

// 응답 인터셉터
api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        // 토큰 만료 시 재발급 시도
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                const refreshToken = sessionStorage.getItem('refreshToken');
                const response = await authApi.post('/auth/refresh', { refreshToken });

                const newToken = response.data.token;
                sessionStorage.setItem('token', newToken);
                //console.log('토큰 재발급 완료');
                originalRequest.headers.Authorization = `Bearer ${newToken}`;
                return api(originalRequest); // 실패했던 요청 재시도
            } catch (refreshError) {
                console.error('토큰 재발급 실패:', refreshError);
                sessionStorage.removeItem('token');
                sessionStorage.removeItem('refreshToken');
                window.location.href = '/login'; // 로그인 페이지로 이동
            }
        }

        return Promise.reject(error);
    }
);

export { api, authApi }; // 필요한 곳에서 `api`와 `authApi` 둘 다 사용할 수 있게 export
