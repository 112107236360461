import React, { useRef, useState, useEffect } from 'react';
import Flex from 'components/common/Flex';
import { useAppContext } from 'providers/AppProvider';
import { Card, Button, ButtonGroup, Spinner } from 'react-bootstrap';
import team1 from 'assets/img/team/1.jpg';
import team2 from 'assets/img/team/2.jpg';
import team3 from 'assets/img/team/3.jpg';
import Avatar from 'components/common/Avatar';
import styled from 'styled-components';
import { activeRank } from 'hooks/CallApi';
import { formatCountStyle1, formatCountStyle3 } from 'components/common/SunnyUtils';

const SiteActiveRank = () => {

  const {
    config: { dashboardDate, dashboardDateValue, Chart_startdate, Chart_enddate },
    setConfig
  } = useAppContext();

  const [view, setView] = useState('cf');
  const [isLoading, setIsLoading] = useState(true);
  const [rankData, setRankData] = useState({
    ReqCompanies: [],
    ProcCompanies: [],
    SpeedCompanies: [],

  });
  const [dateRange, setDateRange] = useState({
    start: Chart_startdate,
    end: Chart_enddate
  });
  useEffect(() => {
    // Chart_startdate 또는 Chart_enddate가 변경될 때 상태 업데이트
    setDateRange({
      start: Chart_startdate,
      end: Chart_enddate
    });
  }, [Chart_startdate, Chart_enddate]);

  useEffect(() => {
    const fetchRankData = async () => {
      setIsLoading(true);
      try {
        const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const response = await activeRank({
          period: dashboardDateValue, // 기간 설정 (day, week, month)
          start: dateRange.start,
          end: dateRange.end,
          timeZone: clientTimeZone,
          type: view, // 'cf', 'api', 'sdk'
        });
        // 데이터 가공
        setRankData({
          ReqCompanies: [
            { name: response.data.top1ReqCompany, value: response.data.top1RequestSize || 0 },
            { name: response.data.top2ReqCompany, value: response.data.top2RequestSize || 0 },
            { name: response.data.top3ReqCompany, value: response.data.top3RequestSize || 0 },
            // ].filter((item) => !(item.name === null && item.value === 0)), // 이름이 null이고 값이 0인 항목만 제외
          ],
          ProcCompanies: [
            { name: response.data.top1ProcCompany, value: response.data.top1ProcSize || 0 },
            { name: response.data.top2ProcCompany, value: response.data.top2ProcSize || 0 },
            { name: response.data.top3ProcCompany, value: response.data.top3ProcSize || 0 },
            // ].filter((item) => !(item.name === null && item.value === 0)), // 이름이 null이고 값이 0인 항목만 제외
          ],
          SpeedCompanies: [
            { name: response.data.top1SpeedCompany, speed: response.data.top1Speed || 0, size: response.data.top1SpeedSize || 0, },
            { name: response.data.top2SpeedCompany, speed: response.data.top2Speed || 0, size: response.data.top2SpeedSize || 0, },
            { name: response.data.top3SpeedCompany, speed: response.data.top3Speed || 0, size: response.data.top3SpeedSize || 0, },
            // ].filter((item) => !(item.name === null && item.speed === 0 && item.size === 0)) // 이름, 속도, 사이즈 모두 없어야 제외
          ]
        });
      } catch (error) {
        console.error('고객사 활동 순위 데이터 조회 실패:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRankData();
  }, [view, dashboardDateValue, dateRange]);


  return (

    <Card className="h-md-100">
      <Card.Header className="pb-0 d-flex align-items-center">
        <h5 style={{ margin: 0 }}>고객사 활동 상위</h5>
        <span
          style={{
            marginLeft: '8px',
            marginTop: 0,
            marginBottom: 0,
            padding: '2px 8px',
            backgroundColor: '#A1C4E3', // 배경색 파란색
            color: 'white', // 텍스트 색상 흰색
            borderRadius: '4px',
            fontSize: '12px',
            fontWeight: 'bold'
          }}
        >
          {dashboardDate}
        </span>
      </Card.Header>
      <Card.Body alignItems="center" justifyContent="space-between">

        <ButtonGroup
          className="mb-3"
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px', // or left: '10px' to align with the left side
            zIndex: 10
          }}
        >
          <Button
            style={{ fontSize: '0.7rem' }}
            variant={view === 'cf' ? 'primary' : 'outline-primary'}
            onClick={() => setView('cf')}
          >
            CF
          </Button>
          <Button
            style={{ fontSize: '0.7rem' }}
            variant={view === 'api' ? 'primary' : 'outline-primary'}
            onClick={() => setView('api')}
          >
            API
          </Button>
          <Button
            style={{ fontSize: '0.7rem' }}
            variant={view === 'sdk' ? 'primary' : 'outline-primary'}
            onClick={() => setView('sdk')}
            disabled
          >
            SDK
          </Button>
        </ButtonGroup>
        {/* <RateTop3Section>
          <GridSection>
            요청량
            <Cell>
              <Flex alignItems="center">
                <Avatar src={team1} size='xl' className="me-1" />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <strong>고객사 1</strong>
                  <p className="mb-1">요청량 수치</p>
                </div>
              </Flex>
            </Cell>
            <Cell>
              <Flex alignItems="center">
                <Avatar src={team2} size='xl' className="me-1" />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <strong>고객사 2</strong>
                  <p className="mb-1">요청량 수치</p>
                </div>
              </Flex>

            </Cell>
            <Cell>
              <Flex alignItems="center">
                <Avatar src={team3} size='xl' className="me-1" />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <strong>고객사 3</strong>
                  <p className="mb-1">요청량 수치</p>
                </div>
              </Flex>
            </Cell>
          </GridSection>
          <GridSection>
            처리량
            <Cell>
              <Flex alignItems="center">
                <Avatar src={team1} size='xl' className="me-1" />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <strong>고객사 1</strong>
                  <p className="mb-1">처리량</p>
                </div>
              </Flex>
            </Cell>
            <Cell>
              <Flex alignItems="center">
                <Avatar src={team2} size='xl' className="me-1" />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <strong>고객사 2</strong>
                  <p className="mb-1">처리량</p>
                </div>
              </Flex>

            </Cell>
            <Cell>
              <Flex alignItems="center">
                <Avatar src={team3} size='xl' className="me-1" />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <strong>고객사 3</strong>
                  <p className="mb-1">처리량</p>
                </div>
              </Flex>
            </Cell>
          </GridSection>
          <GridSection>
            평균 처리 시간
            <Cell>
              <Flex alignItems="center">
                <Avatar src={team1} size='xl' className="me-1" />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <strong>고객사 1</strong>
                  <p className="mb-1">평균 시간</p>
                </div>
              </Flex>
            </Cell>
            <Cell>
              <Flex alignItems="center">
                <Avatar src={team2} size='xl' className="me-1" />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <strong>고객사 2</strong>
                  <p className="mb-1">평균 시간</p>
                </div>
              </Flex>

            </Cell>
            <Cell>
              <Flex alignItems="center">
                <Avatar src={team3} size='xl' className="me-1" />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <strong>고객사 3</strong>
                  <p className="mb-1">평균 시간</p>
                </div>
              </Flex>
            </Cell>
          </GridSection>
        </RateTop3Section> */}

        {isLoading ? (
          <div
            style={{
              width: '100%',
              height: '15rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <RateTop3Section>
            <GridSection>
              <h6>요청량</h6>
              {rankData.ReqCompanies.map((company, index) => (
                <Cell
                  key={index}
                  style={{
                    visibility: company.name ? 'visible' : 'hidden', // 이름이 없으면 보이지 않게 설정
                  }}
                >
                  <div>{company.name}</div> {/* 회사명 */}
                  <div>{formatCountStyle1(company.value)}건</div> {/* 데이터 */}
                </Cell>
              ))}
            </GridSection>
            <GridSection>
              <h6>처리량</h6>
              {rankData.ProcCompanies.map((company, index) => (
                <Cell
                  key={index}
                  style={{
                    visibility: company.name ? 'visible' : 'hidden', // 이름이 없으면 보이지 않게 설정
                  }}
                >
                  <div>{company.name}</div> {/* 회사명 */}
                  <div>{formatCountStyle3(company.value)}</div> {/* 데이터 */}
                </Cell>
              ))}
            </GridSection>
            <GridSection>
              <h6>평균 처리 시간</h6>
              {rankData.SpeedCompanies.map((company, index) => (
                <Cell
                  key={index}
                  style={{
                    visibility: company.name ? 'visible' : 'hidden', // 이름이 없으면 보이지 않게 설정
                  }}
                >
                  {/* 회사명 */}
                  <div style={{ flex: 1, textAlign: 'left' }}>
                    {company.name}
                  </div>
                  {/* 처리 속도와 크기 */}
                  <div style={{ flex: 2, textAlign: 'right' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                      <span style={{ fontSize: '12px' }}>{company.speed.toFixed(2)}s</span>
                      <span style={{ fontSize: '10px', color: '#555' }}>{formatCountStyle3(company.size)}/s</span>
                    </div>
                  </div>
                </Cell>
              ))}
            </GridSection>


          </RateTop3Section>
        )}
      </Card.Body >
    </Card >

  );
};
const RateTop3Section = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3개의 열 생성 */
  gap: 10px; /* 셀 간 간격 설정 */
  width: 100%;
`;

const GridSection = styled.div`
  display: grid;
  gap: 10px; /* 행 간 간격 */
  font-size: 16px;
  align-items: start; /* 데이터를 위로 정렬 */
  height: 100%; /* 모든 섹션 높이를 동일하게 유지 */
`;

// const Cell = styled.div`
//   text-align: left;
//   border: 1px solid #ddd;
//   padding: 10px;
//   border-radius: 5px;
// `;
const Cell = styled.div`
  display: flex;
  flex-direction: row; /* 가로 배치 */
  justify-content: space-between; /* 양 끝 정렬 */
  align-items: center; /* 수직 정렬 */
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  white-space:nowrap;
  min-height: 60px; /* 다른 셀과 동일한 최소 높이 */
  gap: 10px;
`;
export default SiteActiveRank;
