import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import { useAdvanceTableContext } from 'providers/AdvanceTableProvider';
import { flexRender } from '@tanstack/react-table';
import classNames from 'classnames';

const AdvanceTable = ({
  headerClassName,
  bodyClassName,
  rowClassName,
  tableProps,
  onRowClick, // 추가된 부분
  showEmptyMessage
}) => {
  const table = useAdvanceTableContext();
  const { getRowModel, getFlatHeaders } = table;
  const [selectedRows, setSelectedRows] = useState([]);

  const [columnWidths, setColumnWidths] = useState({}); // 각 열 너비 상태 저장
  const cellRefs = useRef([]); // 각 셀에 대한 ref 배열

  useEffect(() => {
    // 초기 렌더링 시 셀 너비를 측정하여 상태로 설정
    const initialWidths = cellRefs.current.map((cell) => cell?.offsetWidth || 'auto');
    setColumnWidths(initialWidths);
  }, []);


  const handleRowClick = (event, row, cell) => {
    // 체크박스 클릭 시 onRowClick 호출하지 않도록 예외 처리
    // if (event.target.type !== 'checkbox' && onRowClick) {
    //   onRowClick(row);
    // }
    if (cell && cell.column && cell.column.id !== 'selection' && event.target.type !== 'checkbox' && onRowClick) {
      onRowClick(row);
    }
  };



  return (
    <div className="table-responsive scrollbar">
      <Table {...tableProps} hover responsive>
        <thead className={headerClassName}>
          <tr>
            {getFlatHeaders().map((header, index) => (

              <th
                key={header.id}
                {...header.column.columnDef.meta?.headerProps}
                className={classNames(
                  'fs-10',
                  header.column.columnDef.meta?.headerProps?.className,
                  {
                    sort: header.column.getCanSort(),
                    desc: header.column.getIsSorted() === 'desc',
                    asc: header.column.getIsSorted() === 'asc'
                  }
                )}
                onClick={header.column.getToggleSortingHandler()}
                style={{
                  width: columnWidths[index] // 초기 너비 고정
                }}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
              </th>
            ))}

          </tr>
        </thead>
        <tbody className={bodyClassName}>
          {getRowModel().rows.length > 0 ? (

            getRowModel().rows.map(row => (
              <tr
                key={row.id}
                className={classNames(rowClassName, {
                  'text-muted': row.original.status === '만료',
                  'table-info': row.getIsSelected()
                })}
                style={{
                  opacity: row.original.status === '만료' ? 0.5 : 1, // 상태에 따라 글씨 흐리게 적용
                }}
                onClick={event => handleRowClick(event, row)}
              >
                {/* {row.getVisibleCells().map((cell, index) => (
                  <td
                    title={cell.getValue()}
                    key={cell.id}
                    {...cell.column.columnDef.meta?.cellProps}
                    ref={(el) => cellRefs.current[index] = el} // 각 셀에 ref 추가
                    onClick={event => handleRowClick(event, row, cell)} // cell을 handleRowClick에 전달
                    style={{
                      width: columnWidths[index], // 초기 너비 고정
                      overflow: 'hidden',       // 넘치는 텍스트 숨기기
                      whiteSpace: 'nowrap',     // 텍스트 줄바꿈 방지
                      textOverflow: 'ellipsis'  // 넘칠 때 줄임표(...) 표시
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))} */}
                {row.getVisibleCells().map((cell, index) => {
                  // meta에서 maxlength 가져오기
                  // const maxLength = cell.column.columnDef.meta?.maxlength || Infinity;
                  const maxLength = cell.column.columnDef.meta?.maxlength;

                  const value = cell.getValue() || '';

                  const truncatedValue = maxLength !== undefined && value.length > maxLength
                    ? `${value.slice(0, maxLength)}...`
                    : flexRender(cell.column.columnDef.cell, cell.getContext());


                  return (
                    <td
                      title={value} // 전체 텍스트는 툴팁으로 표시
                      key={cell.id}
                      {...cell.column.columnDef.meta?.cellProps}
                      ref={(el) => (cellRefs.current[index] = el)} // 각 셀에 ref 추가
                      onClick={(event) => handleRowClick(event, row, cell)} // cell을 handleRowClick에 전달
                      style={{
                        width: columnWidths[index], // 초기 너비 고정
                        overflow: 'hidden', // 넘치는 텍스트 숨기기
                        whiteSpace: 'nowrap', // 텍스트 줄바꿈 방지
                        textOverflow: 'ellipsis', // 넘칠 때 줄임표(...) 표시
                      }}
                    >
                      {truncatedValue}
                    </td>
                  );
                })}
              </tr>
            ))
          ) : (
            showEmptyMessage && (
              <tr>
                <td colSpan={getFlatHeaders().length} style={{ textAlign: 'center', padding: '1rem', fontWeight: 'bold' }}>
                  적용 대상 인증키가 존재하지 않습니다.
                </td>
              </tr>
            )
          )}
        </tbody>
      </Table>
    </div>
  );
};

AdvanceTable.propTypes = {
  headerClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  tableProps: PropTypes.object,
  onRowClick: PropTypes.func, // 추가된 부분
  showEmptyMessage: PropTypes.bool
};

export default AdvanceTable;
