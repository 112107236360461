import React, { useEffect, useState } from 'react';
import { Card, Button, Form, Row, Col, Spinner } from 'react-bootstrap';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { Korean } from 'flatpickr/dist/l10n/ko.js';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter_paging';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination_paging';
import data from 'safedb/common/sampledata/sampledataLogEncDec';
import CheckOutModal from 'components/modal/CheckOutModal';
import { fetchEncDecLog } from 'hooks/CallApi';
import { formatDate_log } from 'components/common/SunnyUtils';

const columns = [
  { accessorKey: 'num', header: '순번', meta: { headerProps: { className: 'text-900' } }, enableSorting: false },
  { accessorKey: 'reqdate', header: '요청일시', meta: { headerProps: { className: 'text-900' } } },
  { accessorKey: 'resdate', header: '처리일시', meta: { headerProps: { className: 'text-900' } } },
  { accessorKey: 'policyid', header: '암호키 아이디', meta: { headerProps: { className: 'text-900' } } },
  { accessorKey: 'policyName', header: '암호키 별칭', meta: { headerProps: { className: 'text-900' } } },
  { accessorKey: 'algorithm', header: '알고리즘', meta: { headerProps: { className: 'text-900' } } },
  { accessorKey: 'kind', header: '처리유형', meta: { headerProps: { className: 'text-900' } } },
  { accessorKey: 'reqcount', header: '요청수', meta: { headerProps: { className: 'text-900' } } },
  { accessorKey: 'succ_rescount', header: '성공처리건수', meta: { headerProps: { className: 'text-900' } } },
  { accessorKey: 'fail_rescount', header: '실패처리건수', meta: { headerProps: { className: 'text-900' } } }
];

function AdvanceTableExample() {
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [requestDateRange, setRequestDateRange] = useState([]);
  const [policyId, setPolicyId] = useState('');
  const [algorithm, setAlgorithm] = useState('');
  const [controlType, setControlType] = useState('');
  const [targetId, setTargetId] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalReShow, setModalReShow] = useState(false);

  const [algorithms, setAlgorithms] = useState([]);
  const [kinds, setKinds] = useState([]);

  const [loading, setLoading] = useState(true); // 로딩 상태
  const [showSpinner, setShowSpinner] = useState(false); // 스피너 상태 추가

  const [totalElements, setTotalElements] = useState(0);
  const [currentPage, setCurrentPage] = useState(0); // 현재 페이지
  const [perPage, setPerPage] = useState(10); // 페이지당 항목 수 (size)

  const table = useAdvanceTable({
    data: filteredData,
    columns,
    sortable: true,
    selection: false,
    pagination: true,
    perPage: 10,
    selectionColumnWidth: 30
  });

  const loadData = async (page, size) => {
    console.log("Fetching data with page:", page, "and size:", size);
    const spinnerTimer = setTimeout(() => setShowSpinner(true), 300);

    try {
      const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const response = await fetchEncDecLog({
        page,
        size,
        searchStartTime: requestDateRange[0] ? requestDateRange[0].toISOString() : null, // ISO 형식,
        searchEndTime: requestDateRange[1] ? requestDateRange[1].toISOString() : null,  // ISO 형식,
        timeZone: clientTimeZone,
        policyName: policyId || null,
        algo: algorithm || null,
        cryptoMethod: controlType || null,
        startTime: 'desc',
        endTime: 'desc',
        requestTotalCnt: 'desc',
        successCnt: 'desc',
        failCnt: 'desc'

      }); // API 호출
      console.log("암복호화 response ", response);
      const logData = response.data.content.map((item, index) => ({
        // num: index + 1 + page * size, // 순번 계산
        num: response.data.page.totalElements - (page * size + index), // 역순으로 순번 계산
        reqdate: item.startTime
          ? formatDate_log(item.startTime) // 포맷 함수 호출
          : '-',
        resdate: item.endTime
          ? formatDate_log(item.endTime) // 포맷 함수 호출
          : '-',
        policyid: item.policyUuid,
        policyName: item.policyName,
        algorithm: item.algo,
        kind: item.cryptoMethod,
        reqcount: item.requestTotalCnt,
        succ_rescount: item.successCnt,
        fail_rescount: item.failCnt

      }));
      console.log("암복호화 logData ", logData);

      setTotalElements(response.data.page.totalElements);
      setTableData(logData);
      setFilteredData(logData);
      // 역할 데이터 추출 및 중복 제거
      const uniqueAlgorithm = [...new Set(response.data.content.map(item => item.algo))];
      const uniqueKind = [...new Set(response.data.content.map(item => item.cryptoMethod))];
      setAlgorithms(uniqueAlgorithm)
      setKinds(uniqueKind)

    } catch (error) {
      console.error('API 데이터 로드 에러:', error);
    } finally {
      clearTimeout(spinnerTimer); // 데이터 로딩이 끝나면 스피너 타이머 제거
      setLoading(false);
      setShowSpinner(false); // 로딩 완료 후 스피너를 숨김
    }
  };

  // 페이지 변경 시 데이터 다시 로드
  useEffect(() => {
    console.log("Current page or perPage changed:", currentPage, perPage);
    loadData(currentPage, perPage);
  }, [currentPage, perPage]);

  // 페이지네이션 이벤트 핸들러
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    table.resetRowSelection(); // 선택된 행 초기화
  };

  const handlePerPageChange = (newPerPage) => {
    console.log("Changing perPage to:", newPerPage);
    setPerPage(newPerPage); // 페이지 크기 업데이트
    setCurrentPage(0); // 첫 페이지로 이동
  };


  // const handleSearch = () => {
  //   const [startDate, endDate] = requestDateRange;
  //   const newData = data.filter(row => {
  //     const reqDate = new Date(row.reqdate);
  //     const matchesDate = requestDateRange.length === 2 ? reqDate >= startDate && reqDate <= endDate : true;
  //     // const matchesPolicyId = policyId ? row.policyid === policyId : true;
  //     const matchesPolicyId = policyId ? row.policyid.includes(policyId) : true;
  //     const matchesAlgorithm = algorithm ? row.algorithm === algorithm : true;
  //     const matchesControlType = controlType ? row.kind === controlType : true;
  //     const matchesTargetId = targetId ? row.targetid === targetId : true;
  //     return matchesDate && matchesPolicyId && matchesAlgorithm && matchesControlType && matchesTargetId;
  //   });
  //   setFilteredData(newData.reverse()); // 항상 역순으로 설정
  // };
  const handleSearch = async () => {
    const [startDate, endDate] = requestDateRange;

    if (startDate && endDate && startDate > endDate) {
      alert('시작 날짜는 종료 날짜 이전이어야 합니다.');
      return;
    }
    try {
      setLoading(true); // 로딩 상태 활성화
      setShowSpinner(true); // 스피너 활성화

      // API 호출
      const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const formatToLocalISO = (date) => {
        if (!date) return null;
        const offset = date.getTimezoneOffset() * 60000; // 분 단위 오프셋을 밀리초로 변환
        const localISO = new Date(date.getTime() - offset).toISOString().slice(0, -1); // UTC로 보정 후 'Z' 제거
        return localISO;
      };
      const response = await fetchEncDecLog({
        page: 0, // 검색 시 첫 페이지부터
        size: perPage,
        searchStartTime: startDate ? formatToLocalISO(startDate) : null,
        searchEndTime: endDate ? formatToLocalISO(endDate) : null,
        // policyUuid: policyId || null, // 정책 ID 검색 조건
        timeZone: clientTimeZone,

        policyName: policyId || null,
        algo: algorithm || null, // 알고리즘 검색 조건
        cryptoMethod: controlType || null, // 처리 유형 검색 조건
        startTime: 'desc',
        endTime: 'desc',
        requestTotalCnt: 'desc',
        successCnt: 'desc',
        failCnt: 'desc',
      });

      // API 응답 데이터를 테이블 데이터로 변환
      const logData = response.data.content.map((item, index) => ({
        num: response.data.page.totalElements - index, // 역순 순번 계산
        reqdate: item.startTime ? formatDate_log(item.startTime) : '-',
        resdate: item.endTime ? formatDate_log(item.endTime) : '-',
        policyid: item.policyUuid,
        policyName: item.policyName,
        algorithm: item.algo,
        kind: item.cryptoMethod,
        reqcount: item.requestTotalCnt,
        succ_rescount: item.successCnt,
        fail_rescount: item.failCnt,
      }));

      // 상태 업데이트
      setTotalElements(response.data.page.totalElements);
      setTableData(logData);
      setFilteredData(logData);
      setCurrentPage(0); // 검색 시 페이지를 첫 페이지로 초기화
    } catch (error) {
      console.error('검색 중 에러 발생:', error);
    } finally {
      setLoading(false); // 로딩 상태 비활성화
      setShowSpinner(false); // 스피너 비활성화
    }
  };

  const handleDefault = () => {
    setRequestDateRange([]);
    setPolicyId('');
    setAlgorithm('');
    setControlType('');
    setTargetId('');
    // setFilteredData(tableData);
    // flatpickr('#timepicker3').clear();
    const datepicker_start = document.querySelector('#timepicker_start')._flatpickr;
    if (datepicker_start) {
      datepicker_start.clear();  // 기존 날짜 선택 제거
      datepicker_start.setDate([]);  // 날짜 범위 초기화
    }
    const datepicker_end = document.querySelector('#timepicker_end')._flatpickr;
    if (datepicker_end) {
      datepicker_end.clear();  // 기존 날짜 선택 제거
      datepicker_end.setDate([]);  // 날짜 범위 초기화
    }
  };
  useEffect(() => {
    const todayStart = new Date();
    todayStart.setHours(0, 0, 0, 0); // 오늘 00시 00분 00초

    const todayEnd = new Date();
    todayEnd.setHours(23, 59, 59, 999); // 오늘 23시 59분 59초

    flatpickr('#timepicker_start', {
      mode: 'single',
      dateFormat: 'Y-m-d H:i',
      enableTime: true,
      time_24hr: true,
      disableMobile: true,
      position: 'below',
      locale: Korean,
      minDate: new Date().fp_incr(-180),
      // defaultDate: todayStart,
      // onChange: (selectedDates) => setRequestDateRange(selectedDates)
      onChange: (selectedDates) => {
        console.log("Start Date Selected:", selectedDates[0]);
        setRequestDateRange((prev) => [selectedDates[0], prev[1] || null]);
      },
    });

    flatpickr('#timepicker_end', {
      mode: 'single',
      dateFormat: 'Y-m-d H:i',
      enableTime: true,
      time_24hr: true,
      disableMobile: true,
      position: 'below',
      locale: Korean,
      minDate: new Date().fp_incr(-180),
      // defaultDate: todayEnd,
      // onChange: (selectedDates) => setRequestDateRange(selectedDates)
      onChange: (selectedDates) => {
        console.log("End Date Selected:", selectedDates[0]);
        setRequestDateRange((prev) => [prev[0] || null, selectedDates[0]]);
      },
    });
  }, []);
  return (
    <AdvanceTableProvider {...table}>
      <div className="d-flex flex-column flex-md-row align-items-center mb-1" style={{ gap: '15px' }}>
        {/* 전체 레코드 수와 페이지네이션 셀렉트 */}
        <div className="d-flex align-items-center">
          {/* <AdvanceTableFooter rowsPerPageSelection /> */}
          <AdvanceTableFooter
            rowsPerPageSelection
            onPageChange={(page, newPageSize) => {
              handlePerPageChange(newPageSize); // 페이지 크기 변경
              setCurrentPage(page); // 현재 페이지 설정
            }}
          />
        </div>

        {/* 검색 필터 */}
        <Row className="w-100 align-items-center">
          <Col xxl={2} xl={2} className="mb-4 ps-4 fs-10">
            <label htmlFor="timepicker_start" className="form-label"> &nbsp;</label>

            <input
              id="timepicker_start"
              className="form-control datetimepicker"
              type="text"
              placeholder="2024-01-01 00:00"
              title="작업일시"
              style={{ height: '29px', fontSize: '1rem' }}
            />
          </Col>
          <Col xxl={2} xl={2} className="mb-4 ps-4 fs-10">
            <label htmlFor="timepicker_end" className="form-label"> &nbsp;</label>

            <input
              id="timepicker_end"
              className="form-control datetimepicker"
              type="text"
              placeholder="2024-12-31 00:00"
              title="작업일시"
              style={{ height: '29px', fontSize: '1rem' }}
            />
          </Col>
          <Col xxl={2} xl={2} className="mb-4 fs-10">
            <Form.Label>&nbsp;</Form.Label>

            <Form.Control
              size="sm"
              type="text"
              value={policyId}
              placeholder="암호키 별칭"
              onChange={e => setPolicyId(e.target.value)}
              style={{ height: '29px' }}
            />
          </Col>
          <Col xxl={2} xl={2} className="mb-4 fs-10">
            <Form.Label>&nbsp;</Form.Label>
            <Form.Select size="sm" value={algorithm} onChange={e => setAlgorithm(e.target.value)} style={{ height: '29px' }}>
              <option value="" disabled hidden >알고리즘</option>

              {algorithms.map((algorithm, index) => (
                <option key={index} value={algorithm}>{algorithm}</option>
              ))}
            </Form.Select>
          </Col>
          <Col xxl={2} xl={2} className="mb-4 fs-10 pe-4">
            <Form.Label>&nbsp;</Form.Label>
            <Form.Select size="sm" value={controlType} onChange={e => setControlType(e.target.value)} style={{ height: '29px' }}>
              <option value="" disabled hidden >처리유형</option>
              {/* {[...new Set(data.map(item => item.kind))].map((kind, index) => (
                <option key={index} value={kind}>{kind}</option>
              ))} */}
              {kinds.map((kind, index) => (
                <option key={index} value={kind}>{kind}</option>
              ))}
            </Form.Select>
          </Col>
        </Row>

        {/* 구분선 */}


        <div className="d-flex justify-content-end align-items-center" style={{ gap: '0.2rem' }}>

          <Button variant="outline-secondary" onClick={handleDefault} className="me-2" style={{ whiteSpace: 'nowrap', width: '5rem' }} title="초기화" >초기화</Button>
          <Button variant="outline-secondary" onClick={handleSearch} className="me-2" style={{ whiteSpace: 'nowrap', width: '4rem' }}>검색</Button>
          <Button variant="outline-secondary" style={{ whiteSpace: 'nowrap', width: '4rem' }}>CSV</Button>
        </div>
      </div>
      {loading ? (
        showSpinner ? (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <p></p>
        )
      ) : (
        <AdvanceTable
          headerClassName="bg-200 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{ striped: true, className: 'fs-10 mb-0 overflow-hidden' }}
        />
      )}
      <p className="mb-0" style={{ fontWeight: 700, lineHeight: '29px' }}>총 {totalElements.toLocaleString()}건</p>
      {/* <AdvanceTablePagination /> */}
      <AdvanceTablePagination
        currentPage={currentPage}
        totalItems={totalElements}
        itemsPerPage={perPage}
        onChange={(newPage) => handlePageChange(newPage)}
      />

    </AdvanceTableProvider>
  );
}

const logEncDec = () => (
  <div className="container mt-5">
    <Card className="mypage-card">
      <Card.Header as="h4">암복호화 로그</Card.Header>
      <Card.Body>
        <AdvanceTableExample />
      </Card.Body>
    </Card>
  </div>
);

export default logEncDec;
