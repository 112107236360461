import React, { useState, useRef, useEffect } from 'react';
import Summary from './Summary';
import EncOptCompare from './EncOptCompare';
import AlgorithmCompare from './AlgorithmCompare';
import { Row, Col, Button } from 'react-bootstrap';

import TotalSales from './TotalSales';
import RecentlyProcess from './RecentlyProcess';
import StorageStatus from './StorageStatus';
import RecentlyEncDec from './RecentlyEncDec';

import ReqPolicy from './ReqPolicy';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { Korean } from 'flatpickr/dist/l10n/ko.js'; // 한국어 로케일 가져오기
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useAppContext } from 'providers/AppProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';


const Dashboard = () => {
  const [activeButton, setActiveButton] = useState('now'); // 초기값: 어제 버튼 활성화
  const [rows, setRows] = useState([
    [
      { id: 'Summary', component: <Summary />, size: { xxl: 6, xl: 12 } }, // xxl: 6, xl: 8, lg: 12, md: 12
      // { id: 'storageStatus', component: <StorageStatus />, size: { xxl: 8 } },
      { id: 'RecentlyProcess', component: <RecentlyProcess />, size: { xxl: 6, xl: 12 } }, // INITECH
    ],
    // 두 번째 행
    [
      { id: 'EncOptCompare', component: <EncOptCompare />, size: { xxl: 3, xl: 12 } },
      { id: 'AlgorithmCompare', component: <AlgorithmCompare />, size: { xxl: 3, xl: 12 } },
      // { id: 'runningProjects', component: <RunningProjects />, size: { md: 6, xxl: 8 } },
      { id: 'RecentlyEncDec', component: <RecentlyEncDec />, size: { xxl: 6, xl: 12 } }, // INITECH
    ],
    // 세 번째 행
    [
      { id: 'topProducts', component: <ReqPolicy />, size: { xxl: 6, xl: 12 } },
      { id: 'totalSales', component: <TotalSales />, size: { xxl: 6, xl: 12 } },
    ],

  ]);
  const {
    config: { dashboardDate, Chart_startdate, Chart_enddate },
    setConfig
  } = useAppContext();

  const formatDate = (date) => {
    if (!date) return '';
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const startPickerRef = useRef(null);
  const endPickerRef = useRef(null);

  useEffect(() => {
    setConfig('dashboardDateValue', 'now');
    setConfig('dashboardDate', '최근 1시간');
  }, []);

  useEffect(() => {
    let dateLabel;
    let dateValue;


    switch (activeButton) {
      case 'now':
        dateLabel = '최근 1시간';
        dateValue = 'now';
        break;
      case 'week':
        dateLabel = '최근 1주일';
        dateValue = 'week';
        break;
      case 'month':
        dateLabel = '최근 1개월';
        dateValue = 'month';
        break;
      default:
        dateLabel = '커스텀'; // 기본값 또는 처리 필요 시 추가
        dateValue = 'custom';
        break;
    }
    if (activeButton !== 'custom') {
      setConfig('Chart_startdate', null);
      setConfig('Chart_enddate', null);
    }

    setConfig('dashboardDate', dateLabel);
    setConfig('dashboardDateValue', dateValue);
  }, [activeButton]);


  const handleButtonClick = (button) => {
    // const today = new Date();
    let startDate, endDate;

    switch (button) {
      case 'now':
        endDate = new Date(); // 현재 시간
        startDate = new Date();
        // startDate.setDate(today.getDate() - 1); // 하루 전
        startDate.setHours(endDate.getHours() - 1); // 한시간 전
        break;

      case 'week':
        endDate = new Date(); // 현재 시간
        startDate = new Date();
        startDate.setDate(endDate.getDate() - 7); // 7일전
        break;
      case 'month':
        endDate = new Date(); // 현재 시간
        startDate = new Date();
        // startDate.setMonth(today.getMonth() - 1);
        // startDate.setDate(1); // 이전 월의 첫날
        // endDate = new Date(today.getFullYear(), today.getMonth(), 0); // 이전 월의 마지막 날
        // 위 주석은 달 단위인데 바꿈
        startDate.setDate(endDate.getDate() - 28); // 오늘 제외한 28일 전
        break;

      default:
        return;

    }


    // flatpickr('#timepicker_start', {
    //   mode: 'single',
    //   dateFormat: 'Y-m-d H:i',
    //   locale: Korean,
    //   enableTime: true,
    //   time_24hr: true,
    //   position: 'below',
    //   disableMobile: true,
    //   defaultDate: startDate,
    //   onChange: () => setActiveButton('custom'), // 날짜 변경 시 'custom'으로 설정

    // });

    // flatpickr('#timepicker_end', {
    //   mode: 'single',
    //   dateFormat: 'Y-m-d H:i',
    //   locale: Korean,
    //   enableTime: true,
    //   time_24hr: true,
    //   position: 'below',
    //   disableMobile: true,
    //   defaultDate: endDate,
    //   onChange: () => setActiveButton('custom'), // 날짜 변경 시 'custom'으로 설정

    // });

    setActiveButton(button);
  };

  const getButtonVariant = (button) => {
    return activeButton === button ? 'primary' : 'outline-primary'; // 활성화된 버튼에 따라 스타일 적용
  };
  useEffect(() => {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    startPickerRef.current = flatpickr('#timepicker_start', {
      mode: 'single',
      dateFormat: 'Y-m-d H:i',
      locale: Korean,
      enableTime: true,
      time_24hr: true,
      position: 'below',
      disableMobile: true,
      defaultDate: yesterday,
      onChange: (selectedDates) => {
        const startDate = formatDate(selectedDates[0]);

        setConfig('Chart_startdate', startDate);
        setActiveButton('custom');
      },
    });

    endPickerRef.current = flatpickr('#timepicker_end', {
      mode: 'single',
      dateFormat: 'Y-m-d H:i',
      locale: Korean,
      enableTime: true,
      time_24hr: true,
      position: 'below',
      disableMobile: true,
      defaultDate: today,
      onChange: (selectedDates) => {
        const endDate = formatDate(selectedDates[0]);
        setConfig('Chart_enddate', endDate);
        setActiveButton('custom');
      },
    });
    return () => {
      // Cleanup to prevent multiple instances
      startPickerRef.current?.destroy();
      endPickerRef.current?.destroy();
    };

  }, []);
  const handleOnDragEnd = (result) => {
    const { source, destination } = result;

    // 영역 외에 드롭이면 무반응
    if (!destination) return;

    const sourceRowIndex = parseInt(source.droppableId);
    const destinationRowIndex = parseInt(destination.droppableId);

    const sourceRow = Array.from(rows[sourceRowIndex]);
    const destinationRow = Array.from(rows[destinationRowIndex]);

    if (destination.index >= destinationRow.length) return;

    // 동일한 Row
    if (sourceRowIndex === destinationRowIndex) {
      // 위치 스왑
      const temp = sourceRow[source.index];
      sourceRow[source.index] = sourceRow[destination.index];
      sourceRow[destination.index] = temp;

      const newRows = [...rows];
      newRows[sourceRowIndex] = sourceRow;
      setRows(newRows);
    }
    // 서로 다른 Row
    else {
      const [movedItem] = sourceRow.splice(source.index, 1);
      destinationRow.splice(destination.index, 0, movedItem);

      const newRows = [...rows];
      newRows[sourceRowIndex] = sourceRow;
      newRows[destinationRowIndex] = destinationRow;
      setRows(newRows);
    }
  };

  return (
    <>
      <div className="my-2 d-flex justify-content-between align-items-center">

        <h4>SafeDB 대시보드</h4>
        <div className="d-flex align-items-center">

          <input
            style={{ height: 29, fontSize: 12, width: '9rem' }} // width를 100%로 설정
            className="form-control datetimepicker"
            id="timepicker_start"

          />
          {/* <FontAwesomeIcon icon={faCalendarAlt} style={{ marginRight: '5px' }} /> */}
          <input
            style={{ height: 29, fontSize: 12, width: '9rem' }} // width를 100%로 설정
            className="form-control datetimepicker"
            id="timepicker_end"
          // type="text"
          />
          <Button
            variant={getButtonVariant('now')}
            onClick={() => handleButtonClick('now')}
            style={{ height: 29, fontSize: 12, whiteSpace: 'nowrap', marginLeft: '1rem' }}>
            최근 1시간
          </Button>
          <Button
            variant={getButtonVariant('week')}
            onClick={() => handleButtonClick('week')}
            style={{ height: 29, fontSize: 12, whiteSpace: 'nowrap', marginLeft: '1rem', marginRight: '1rem' }}

          >
            최근 1주일
          </Button>
          <Button
            variant={getButtonVariant('month')}
            onClick={() => handleButtonClick('month')}
            style={{ height: 29, fontSize: 12, whiteSpace: 'nowrap' }}

          >
            최근 1개월
          </Button>
        </div>
      </div>

      <DragDropContext onDragEnd={handleOnDragEnd}>
        {rows.map((row, rowIndex) => (
          <Droppable droppableId={`${rowIndex}`} key={rowIndex} direction="horizontal">
            {(provided) => (
              <Row className="g-3 mb-3" ref={provided.innerRef} {...provided.droppableProps}>
                {row.map((comp, colIndex) => (
                  <Draggable key={comp.id} draggableId={comp.id} index={colIndex}>
                    {(provided) => (
                      <Col
                        md={comp.size.md}
                        lg={comp.size.lg}
                        xl={comp.size.xl}
                        xxl={comp.size.xxl}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {comp.component}
                      </Col>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Row>
            )}
          </Droppable>
        ))}
      </DragDropContext>

      {/* <Row className="g-3 mb-3">
        <Col md={6} xxl={3}>
          <WeeklySales data={weeklySalesData} />
        </Col>
        <Col md={6} xxl={3}>
          <TotalOrder data={totalOrder} />
        </Col>
        <Col md={6} xxl={3}>
          <MarketShare data={marketShare} radius={['100%', '87%']} />
        </Col>
        <Col md={6} xxl={3}>
          <Weather data={weather} />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col lg={6}>
          <RunningProjects data={runningProjects} />
        </Col>
        <Col lg={6}>
          <TotalSales data={totalSales} />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col lg={6} xl={7} xxl={8}>
          <StorageStatus className="h-lg-100" data={storageStatus} />
        </Col>
        <Col lg={6} xl={5} xxl={4}>
          <SpaceWarning />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col lg={7} xl={8}>
          <BestSellingProducts products={products} />
        </Col>
        <Col lg={5} xl={4}>
          <SharedFiles files={files} className="h-lg-100" />
        </Col>
      </Row>

      <Row className="g-3">
        <Col sm={6} xxl={3}>
          <ActiveUsers className="h-100" users={users} />
        </Col>
        <Col sm={6} xxl={3} className="order-xxl-1">
          <BandwidthSaved />
        </Col>
        <Col xxl={6}>
          <TopProducts data={topProducts} className="h-100" />
        </Col>
      </Row> */}

      {/* <Row className="g-3 mb-3">
        <Col xxl={4}>
          <Summary />
        </Col>
        <Col xxl={6}>
          <StorageStatus />
        </Col>

      </Row>

      <Row className="g-3 mb-3">
        <Col md={6} xxl={2}>
          <EncOptCompare />
        </Col>
        <Col md={6} xxl={2}>
          <AlgorithmCompare />
        </Col>
        <Col md={6} xxl={2}>
          <RecentlyChange />
        </Col>
        <Col md={6} xxl={6}>
          <RunningProjects />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col md={6} xxl={7}>
          <ReqPolicy />
        </Col>
        <Col md={6} xxl={5}>
          <TotalSales />
        </Col>
      </Row> */}
    </>
  );
};

export default Dashboard;
