
const handleValueDelete = (fieldName, formData, setFormData, Change) => {
    setFormData({
        ...formData,
        [fieldName]: '' // 전달된 필드명을 기준으로 해당 필드의 값을 비움
    });

};

// 포맷 함수 정의
const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작
    const day = String(d.getDate()).padStart(2, '0');
    const hours = String(d.getHours()).padStart(2, '0');
    const minutes = String(d.getMinutes()).padStart(2, '0');
    const seconds = String(d.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

// 포맷 함수 정의
const formatDate_log = (date) => {

    const [datePart, timePart] = date.split('T'); // 'T'를 기준으로 날짜와 시간을 분리
    const [year, month, day] = datePart.split('-'); // 날짜를 '-'로 분리
    const [hours, minutes, seconds] = timePart.split(':'); // 시간을 ':'로 분리
    console.log("들어온 date ", date);
    console.log("처리된 date ", `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`);
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; // 원하는 포맷으로 조합
};



const goToParentPath = () => {
    const pathSegments = location.pathname.split('/');
    // 마지막 경로 세그먼트를 제거하고 재조합
    pathSegments.pop(); // 'bc' 제거
    return pathSegments.join('/') || '/'; // '/'로 리턴 (루트로 이동)
};

/**
 * 건수 출력 방식 1
 */
const formatCountStyle1 = (count) => {
    if (count >= 100000) {
        return (count / 1000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + 'K';
    }
    return count.toLocaleString();
};

/**
 * 건수 출력 방식 2
 */
const formatCountStyle2 = (count) => {
    if (count >= 1000000) {
        return (count / 1000000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + 'M';
    }
    return count.toLocaleString();
};

/**
 * 용량 출력 방식
 */
const formatCountStyle3 = (sizeInBytes) => {
    if (sizeInBytes < 1024) {
        return `${sizeInBytes.toFixed(2)} B`;
    } else if (sizeInBytes < 1024 * 1024) {
        return `${(sizeInBytes / 1024).toFixed(2)} KB`;
    } else if (sizeInBytes < 1024 * 1024 * 1024) {
        return `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
    } else if (sizeInBytes < 1024 * 1024 * 1024 * 1024) {
        return `${(sizeInBytes / (1024 * 1024 * 1024)).toFixed(2)} GB`;
    } else {
        return `${(sizeInBytes / (1024 * 1024 * 1024 * 1024)).toFixed(2)} TB`;
    }
};


export {
    handleValueDelete,
    goToParentPath,
    formatDate,
    formatDate_log,
    formatCountStyle1,
    formatCountStyle2,
    formatCountStyle3
}