import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import { useAppContext } from 'providers/AppProvider';
import { Card, Button, ButtonGroup, } from 'react-bootstrap';
import { fetchDataSize } from 'hooks/CallApi';
import { formatCountStyle3 } from 'components/common/SunnyUtils';

const ChartOptions = () => {
  const {
    config: { dashboardDate, dashboardDateValue, Chart_startdate, Chart_enddate },
  } = useAppContext();

  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({ timeData: [], successData: [], failData: [] });
  const [view, setView] = useState('cf'); // 'top' or 'bottom'

  const [dateRange, setDateRange] = useState({
    start: Chart_startdate,
    end: Chart_enddate
  });
  useEffect(() => {
    // Chart_startdate 또는 Chart_enddate가 변경될 때 상태 업데이트
    setDateRange({
      start: Chart_startdate,
      end: Chart_enddate
    });
  }, [Chart_startdate, Chart_enddate]);



  useEffect(() => {
    const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const fetchData = async () => {
      try {
        const data = await fetchDataSize({
          period: dashboardDateValue,
          start: dateRange.start,
          end: dateRange.end,
          timeZone: clientTimeZone,
          type: view
        });
        const timeData = data.data.map((item) => item.periodKst);
        const successData = data.data.map((item) => parseInt(item.size, 10) || 0);
        const failData = data.data.map((item) => parseInt(item.failSize, 10) || 0);
        setChartData({ timeData, successData, failData });
      } catch (error) {
        //        console.error('데이터 조회 에러:', error);
        if (error.response.data.code === -9999) {
          setChartData({
            timeData: ['데이터 없음'],
            successData: [0],
            failData: [0],
          });
        }
      }
    };

    fetchData();
  }, [dashboardDate, dateRange, view]);

  useEffect(() => {
    // 실패 데이터의 최소 높이 설정
    const minFailHeight = 2; // 최소 픽셀 높이
    const failDataAdjusted = chartData.failData.map((failValue, index) => {
      const successValue = chartData.successData[index];
      const totalValue = successValue + failValue;
      const failPercentage = failValue / totalValue;

      if (failValue === 0) return null; // 실패 데이터가 없으면 표시하지 않음
      if (failPercentage <= 0.1) {
        // 실패량이 10% 이하일 경우 2px 고정
        return 2;
      }
      // 실패량이 10% 초과일 경우 비율만큼 계산
      return failValue;
    });

    const isDataEmpty = chartData.successData.every((value) => value === 0);

    const option = {
      title: isDataEmpty
        ? {
          text: '데이터 없음',
          left: 'center',
          top: 'middle',
          textStyle: {
            color: '#aaa',
            fontSize: 16,
          },
        }
        : null,
      // tooltip: {
      //   trigger: 'axis',
      //   axisPointer: { type: 'shadow' },
      //   formatter: (params) => {
      //     let tooltipText = `${params[0].name}<br/>`;
      //     params.forEach((item) => {
      //       if (item.seriesName === '성공') {
      //         tooltipText += `${item.marker} 성공: ${item.value}<br/>`;
      //       }
      //     });
      //     return tooltipText;
      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'shadow' },
        formatter: (params) => {
          let tooltipText = `${params[0].name}<br/>`;
          params.forEach((item) => {
            if (item.seriesName === '성공') {
              tooltipText += `${item.marker} 성공: ${formatCountStyle3(item.value)}<br/>`;
            } else if (item.seriesName === '실패') {
              const failValue = chartData.failData[item.dataIndex] || 0;
              tooltipText += `${item.marker} 실패: ${formatCountStyle3(failValue)}<br/>`;
            }
          });
          return tooltipText;
        },
      },
      legend: {
        data: [
          { name: '성공', icon: 'rect', itemStyle: { color: '#6abf40' } },
          { name: '실패', icon: 'rect', itemStyle: { color: '#ff4d4d' } }
        ],
        bottom: '2%',
      },

      grid: {
        left: '5%',
        right: '5%',
        bottom: '20%',
        top: '10%'
      },
      xAxis: {
        type: 'category',
        data: chartData.timeData.length > 0 ? chartData.timeData : [''],
        axisLabel: {
          formatter: (value) => (value ? new Date(value).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : ''),
          show: !isDataEmpty, // 데이터가 없으면 axisLabel 숨김
        },
        axisLine: { show: true }, // x축 선은 항상 표시
        splitLine: { show: false },
      },
      yAxis: {
        type: 'value',
        min: 0,
        splitLine: { show: true },
        axisLabel: {
          formatter: function (value) {
            if (value >= 1e9) {
              return value / 1e9 + 'GB';
            } else if (value >= 1e6) {
              return value / 1e6 + 'MB';
            } else if (value >= 1e3) {
              return value / 1e3 + 'kB';
            } else {
              return value + 'B';
            }
          }
        }
      },
      series: [
        {
          name: '성공',
          type: 'bar',
          data: isDataEmpty ? [] : chartData.successData,
          itemStyle: { color: '#6abf40' },
        },
        {
          name: '실패',
          type: 'bar',
          stack: '처리량', // 성공 위에 쌓이도록 설정
          data: isDataEmpty
            ? []
            : chartData.timeData.map((time, index) => ({
              value: failDataAdjusted[index] !== null ? failDataAdjusted[index] : 0,
              itemStyle: { color: '#ff4d4d' }

            })),
        },
        {
          name: '실패 마커',
          type: 'scatter',
          symbol: 'pin',
          symbolSize: 40,
          label: {
            show: true,
            formatter: '실패',
            position: 'inside',
            color: '#fff',
            fontSize: 10,
          },
          data: isDataEmpty
            ? []
            : chartData.timeData.map((time, index) => {
              // 실패 데이터가 0이거나 null이면 마커 추가하지 않음
              const failValue = chartData.failData[index];
              if (failValue !== null && failValue > 0) {
                return [
                  time,
                  chartData.successData[index] + failDataAdjusted[index],
                ];
              }
              return null;
            }).filter((item) => item), // null 값 필터링
          itemStyle: { color: '#ff4d4d' },
        },
      ],
    };

    const chart = echarts.init(chartRef.current);
    chart.setOption(option);

    const handleResize = () => chart.resize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.dispose();
    };
  }, [chartData]);

  return (
    <Card className="h-md-100">
      <Card.Header className="pb-0 d-flex align-items-center">
        <h5 style={{ margin: 0 }}>암복호화 처리량</h5>
        <span
          style={{
            marginLeft: '8px',
            marginTop: 0,
            marginBottom: 0,
            padding: '2px 8px',
            backgroundColor: '#A1C4E3', // 배경색 파란색
            color: 'white', // 텍스트 색상 흰색
            borderRadius: '4px',
            fontSize: '12px',
            fontWeight: 'bold',
          }}
        >
          {dashboardDate}
        </span>
      </Card.Header>
      <Card.Body alignItems="center" justifyContent="space-between">

        <ButtonGroup
          className="mb-3"
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px', // or left: '10px' to align with the left side
            zIndex: 10
          }}
        >
          <Button
            style={{ fontSize: '0.7rem' }}
            variant={view === 'cf' ? 'primary' : 'outline-primary'}
            onClick={() => setView('cf')}
          >
            CF
          </Button>
          <Button
            style={{ fontSize: '0.7rem' }}
            variant={view === 'api' ? 'primary' : 'outline-primary'}
            onClick={() => setView('api')}
          >
            API
          </Button>
          <Button
            style={{ fontSize: '0.7rem' }}
            variant={view === 'sdk' ? 'primary' : 'outline-primary'}
            onClick={() => setView('sdk')}
            disabled
          >
            SDK
          </Button>
        </ButtonGroup>
      </Card.Body>
      <div
        id="main"
        ref={chartRef}
        style={{
          width: '100%',
          maxWidth: '1200px',
          height: '15rem',
          margin: '0 auto',
        }}
      />
    </Card>
  );
};

export default ChartOptions;
