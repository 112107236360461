import React, { useState, useEffect } from 'react';
import { Form, Button, Card, Row, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';

import { AiFillCloseCircle } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { useAppContext } from 'providers/AppProvider';
import CheckOutModal from 'components/modal/CheckOutModal';
import paths from 'routes/paths';
import "safedb/common/css/IniCustomCss.css";

import { handleValueDelete, goToParentPath } from 'components/common/SunnyUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretSquareLeft } from '@fortawesome/free-solid-svg-icons';

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { settingEncKey, addPubKey } from 'hooks/CallApi';

const addencryptPublicKey = () => {
    const navigate = useNavigate();
    const {
        config: { checkorg },
    } = useAppContext();

    const [modalShow, setModalShow] = useState(false);
    const [modalConfirmShow, setModalConfirmShow] = useState(false);
    const [isModified, setIsModified] = useState(false);
    const [algorithm, setAlgorithm] = useState("ARIA");
    const [keyLength, setKeyLength] = useState("128");
    const [algorithms, setAlgorithms] = useState([]);

    const [keyLengthOptions, setKeyLengthOptions] = useState([]);
    const [modeOptions, setModeOptions] = useState([]);
    const [isArrowHovered, setIsArrowHovered] = useState(false);

    // const [modeOptions, setModeOptions] = useState(["OFB", "ECB", "CFB", "CBC"]);
    const [activeButton, setActiveButton] = useState("HEXA");
    const [isToggleOn, setIsToggleOn] = useState(false); // Toggle 상태를 관리
    const [keyError, setKeyError] = useState('');
    const [ivError, setIvError] = useState('');
    const [NameError, setNameError] = useState('');
    const [buttonStyle, setButtonStyle] = useState({});
    useEffect(() => {
        const updateButtonStyle = () => {
            if (window.innerWidth <= 1536) {
                setButtonStyle({
                    ...buttonStyle,

                    // marginRight: '1rem',
                    width: '4rem',
                    fontSize: '0.7rem',
                    whiteSpace: 'nowrap',
                    marginLeft: '-0.5rem',

                });
            } else {
                setButtonStyle({
                    width: '7rem',
                });
            }
        };

        updateButtonStyle(); // 초기 실행
        window.addEventListener('resize', updateButtonStyle); // 화면 크기 변경 이벤트 리스너 추가

        return () => window.removeEventListener('resize', updateButtonStyle); // 클린업
    }, []);
    const [formData, setFormData] = useState({
        publickeyName: '',
        algorithm: '',
        keylength: '',
        mode: '',
        description: '',
    });

    const handleDeleteClick = () => setModalShow(true);

    const handleCheckOutModalConfirm = () => {
        setModalShow(false);
        navigate(-1);
    };

    const handleConfirmClick = () => setModalConfirmShow(true);

    const handleConfirm = async () => {
        try {
            const response = await addPubKey({
                algo: formData.algorithm,
                mode: formData.mode,
                keyLen: formData.keylength,
                sharedkeyName: formData.publickeyName,
                description: formData.description
            });
            console.log("add publik response : ", response);
            if (response) {
                setModalConfirmShow(false);
                //   setModalConfirmCheckShow(true);
                navigate(-1);
            }
        } catch (error) {
            console.error('공유키 생성 에러:', error);

        }

        // 등록처리

    };
    const handleInputChange = (event) => {
        const { name, value } = event.target;

        let error = ''; // 에러 메시지를 저장할 변수

        // 각 필드에 대한 유효성 검증
        if (name === 'publickeyName') {
            if (!value.trim()) {
                error = '공유키명은 필수 값입니다.'; // 빈 값인 경우 에러 메시지 설정
            }
        }

        // 에러 메시지 설정
        if (name === 'publickeyName') {
            setNameError(error); // 에러 상태 업데이트
        }

        // 상태 업데이트: 유효성 여부와 상관없이 항상 값 반영
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        // isModified 상태 업데이트
        if (!isModified && value.trim()) {
            setIsModified(true);
        }
    };


    // API 호출 후 데이터 확인
    useEffect(() => {
        const settingKeys = async () => {
            try {
                const response = await settingEncKey();
                console.log("api 등록 데이터 ", response); // API 응답 데이터 확인

                setAlgorithms(response.data.algorithms);
            } catch (error) {
                console.error('그룹 목록 불러오기 실패:', error);
            }
        };
        settingKeys();
    }, []);


    const handleComboChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: value,
            ...(name === 'algorithm' && { keylength: '', mode: '' }), // 알고리즘 선택 시 키 길이와 모드 초기화
            ...(name === 'keylength' && { mode: '' }) // 키 길이 선택 시 모드 초기화
        }));
        setIsModified(true);

        if (name === "algorithm") {
            const selectedAlgorithm = algorithms.find(alg => alg.algo === value);
            if (selectedAlgorithm) {
                setKeyLengthOptions(selectedAlgorithm.keyLen || []);
                setModeOptions([]); // 키 길이 선택되기 전까지 모드는 비활성화
            } else {
                setKeyLengthOptions([]);
                setModeOptions([]);
            }
        } else if (name === "keylength") {
            const selectedAlgorithm = algorithms.find(alg => alg.algo === formData.algorithm);
            if (selectedAlgorithm) {
                setModeOptions(selectedAlgorithm.mode || []); // 키 길이 선택 시 모드 활성화
            }
        }
    };

    const updateModeOptions = (algorithm, keyLength) => {
        const modes = algorithm === "AES" ? ["OFB", "ECB", "CBC"] : ["OFB", "ECB", "CFB", "CBC"];
        setModeOptions(modes);
    };


    const renderAlgorithmOptions = () => {
        const allAlgorithms = [
            "ARIA", "SEED", "AES", "DES", "HAS160", "HMACwithHAS160", "HMACwithMD5",
            "HMACwithMDC2", "HMACwithSHA1", "HMACwithSHA224", "HMACwithSHA256",
            "HMACwithSHA384", "HMACwithSHA512", "MD5", "MDC2", "SHA1",
            "SHA224", "SHA256", "SHA384", "SHA512", "TripleDES"
        ];

        return allAlgorithms.map(algo => (
            <option key={algo} value={algo} disabled={!algorithms.some(a => a.algo === algo)}>
                {algo}
            </option>
        ));
    };


    const handleButtonClick = (buttonType) => {
        setActiveButton(buttonType);
    };

    const handleToggleChange = () => {
        setIsToggleOn(!isToggleOn);
    };
    const validateAlphanumeric = (str, length) => {
        const regex = new RegExp(`^[0-9A-Za-z]{${length}}$`);
        return regex.test(str);
    };

    const validateHexadecimal = (str, length) => {
        const regex = new RegExp(`^[0-9A-Fa-f]{${length}}$`);
        return regex.test(str);
    };
    const handleKeyChange = (e) => {
        const value = e.target.value;

        if (activeButton === "HEXA") {
            // 16진수 이외의 문자는 무시
            const hexRegex = /^[0-9A-Fa-f]*$/;
            if (hexRegex.test(value)) {
                setFormData((prevData) => ({ ...prevData, key: value }));
                // 키 길이 검증
                if (!validateHexadecimal(value, formData.keylength / 4)) {
                    setKeyError(
                        `선택하신 키 길이에 맞는 16진수 형식 문자열 ${formData.keylength / 4}자리를 입력해 주세요`
                    );
                } else {
                    setKeyError("");
                }
            }
        } else if (activeButton === "문자열") {
            // 알파벳과 숫자로 구성된 값만 허용
            const alphanumericRegex = /^[0-9A-Za-z]*$/;
            if (alphanumericRegex.test(value)) {
                setFormData((prevData) => ({ ...prevData, key: value }));
                // 키 길이 검증
                if (!validateAlphanumeric(value, formData.keylength / 8)) {
                    setKeyError(
                        `선택하신 키 길이에 맞는 문자열 ${formData.keylength / 8}자리 숫자와 문자 조합의 값을 입력해 주세요`
                    );
                } else {
                    setKeyError("");
                }
            }
        }
    };


    const handleIvChange = (e) => {
        const value = e.target.value;
        setFormData((prevData) => ({ ...prevData, iv: value }));

        if (activeButton === "HEXA") {
            if (!validateHexadecimal(value, 32)) {
                setIvError("16진수 형식의 문자열 32자리를 입력해주세요");
            } else {
                setIvError("");
            }
        } else if (activeButton === "문자열") {
            if (!validateAlphanumeric(value, 16)) {
                setIvError("문자열 16자리를 입력해주세요");
            } else {
                setIvError("");
            }
        }
    };

    const isSubmitEnabled = () => {
        const baseConditionsMet = formData.publickeyName && formData.algorithm && formData.keylength && formData.mode;
        const additionalConditionsMet = isToggleOn ? (!keyError && !ivError && formData.key && formData.iv) : true;
        return baseConditionsMet && additionalConditionsMet;
    };

    return (
        <div className="container mt-5">
            <Card className="mypage-card">

                <Card.Header className="px-3" as="h4">
                    <Link to={goToParentPath()}
                        style={{ textDecoration: 'none', color: 'inherit', }}
                        onMouseEnter={() => setIsArrowHovered(true)}
                        onMouseLeave={() => setIsArrowHovered(false)} >
                        <FontAwesomeIcon
                            icon={faCaretSquareLeft}
                            className="me-2"
                            style={
                                isArrowHovered
                                    ? {} // Hover 시 스타일 제거
                                    : { color: "#869fca" } // 기본 스타일
                            }
                        />
                    </Link>
                    공유키</Card.Header>

                <Card.Title className="border border-bottom-0 px-3 pt-3">등록</Card.Title>

                <Card.Body className="border border-top-0">
                    <Form>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>공유키명 <span className='text-danger'>*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="publickeyName"
                                        value={formData.publickeyName}
                                        onChange={handleInputChange}
                                        className="input-hover"

                                    />
                                    {NameError &&
                                        <div style={{ color: 'red' }}>
                                            {NameError}
                                        </div>} {/* 에러 메시지 표시 */}
                                    {formData.publickeyName && (
                                        <span
                                            onClick={() => setFormData((prevData) => ({ ...prevData, publickeyName: '' }))}
                                            style={{
                                                cursor: 'pointer',
                                                position: 'absolute',
                                                right: '53%',
                                                top: '25.2%',
                                                zIndex: 9
                                            }}
                                        >
                                            <AiFillCloseCircle />
                                        </span>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={2}>
                                <Form.Group className="mb-3">
                                    <Form.Label>알고리즘  <span className='text-danger'>*</span></Form.Label>
                                    <Form.Select
                                        name="algorithm"
                                        onChange={handleComboChange}
                                        value={formData.algorithm}
                                        className="input-hover"

                                    >
                                        <option disabled value="">선택</option>
                                        {renderAlgorithmOptions()}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={2}>
                                <Form.Group className="mb-3">
                                    <Form.Label>키 길이(bit)  <span className='text-danger'>*</span></Form.Label>
                                    <Form.Select
                                        name="keylength"
                                        onChange={handleComboChange}
                                        value={formData.keylength}
                                        className="input-hover"

                                    >
                                        <option disabled value="">선택</option>
                                        {keyLengthOptions.map((length, index) => (
                                            <option key={index} value={length}>{length}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={2}>
                                <Form.Group className="mb-3">
                                    <Form.Label>운영모드  <span className='text-danger'>*</span></Form.Label>
                                    <Form.Select
                                        name="mode"
                                        onChange={handleComboChange}
                                        value={formData.mode}
                                        className="input-hover"

                                    >
                                        <option disabled value="">선택</option>
                                        {modeOptions.map((mode, index) => (
                                            <option key={index} value={mode}>{mode}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="d-flex align-items-center  mb-2">
                            <div>
                                <span>주입키(DIK) 설정</span>
                                <OverlayTrigger
                                    placement="top" // 툴팁 위치 (top, bottom, left, right)
                                    overlay={
                                        <Tooltip id="tooltip-info">
                                            주입키를 설정하면 향상된 보안성과 유연한 키 관리가 가능합니다.<br />주입키를 설정하지 않으면 기본 키 형식이 사용됩니다. 보안 강화를 위해 주입키 설정을 권장드립니다

                                        </Tooltip>
                                    }
                                >
                                    <span className="info-icon mx-2" style={{ cursor: 'pointer' }}>
                                        <FontAwesomeIcon icon={faInfoCircle} />
                                    </span>
                                </OverlayTrigger>
                            </div>
                            <div className="d-flex fs-10">

                                <Form.Check
                                    type="switch"
                                    id="dik-toggle-switch"
                                    className="my-2 mx-1"
                                    style={{ transform: 'scale(1.5)' }}
                                    checked={isToggleOn}
                                    onChange={handleToggleChange}
                                />

                            </div>
                        </div>

                        {/* Toggle 상태에 따라 배경색과 Form 컨트롤 활성화 상태 변경 */}
                        <div className={`dik p-3 border rounded ${isToggleOn ? '' : 'bg-400'}`}>
                            <Row>
                                <Col md={2}>
                                    <div className="d-flex align-items-center mb-3">
                                        <Button
                                            variant={activeButton === "HEXA" ? "primary" : "outline-secondary"}
                                            className="me-2"
                                            style={buttonStyle}
                                            active={activeButton === "HEXA"}
                                            onClick={() => handleButtonClick("HEXA")}
                                            disabled={!isToggleOn}
                                        >
                                            HEXA
                                        </Button>
                                        <Button
                                            variant={activeButton === "문자열" ? "primary" : "outline-secondary"}
                                            style={buttonStyle}
                                            active={activeButton === "문자열"}
                                            onClick={() => handleButtonClick("문자열")}
                                            disabled={!isToggleOn}
                                        >
                                            문자열
                                        </Button>
                                    </div>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>KEY <span className="text-danger">*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            // placeholder={isToggleOn ? "16진수 형식의 문자열 " + formData.keylength / 4 + "자리를 입력해주세요" : ""}
                                            placeholder={
                                                !isToggleOn
                                                    ? "" // 토글이 비활성화된 경우
                                                    : activeButton === "HEXA"
                                                        ? `16진수 형식의 문자열 ${formData.keylength / 4}자리를 입력해주세요`
                                                        : activeButton === "문자열"
                                                            ? `문자열 ${formData.keylength / 8}자리를 입력해주세요`
                                                            : ""
                                            }
                                            disabled={!isToggleOn}
                                            value={formData.key}
                                            onChange={handleKeyChange}
                                            maxLength={
                                                !isToggleOn
                                                    ? 0
                                                    : activeButton === "HEXA"
                                                        ? formData.keylength / 4
                                                        : activeButton === "문자열"
                                                            ? formData.keylength / 8
                                                            : undefined
                                            }
                                        />
                                        {keyError && <span className="text-danger">{keyError}</span>}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={2}></Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>IV (Initial Vector) <span className="text-danger">*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            // placeholder={isToggleOn ? "16진수 형식의 문자열 32자리를 입력해주세요" : ""}
                                            placeholder={
                                                !isToggleOn
                                                    ? "" // 토글이 비활성화된 경우
                                                    : activeButton === "HEXA"
                                                        ? `16진수 형식의 문자열 32자리를 입력해주세요`
                                                        : activeButton === "문자열"
                                                            ? `문자열 16자리를 입력해주세요`
                                                            : ""
                                            }
                                            disabled={!isToggleOn}
                                            value={formData.iv}
                                            onChange={handleIvChange}
                                            maxLength={
                                                !isToggleOn
                                                    ? 0
                                                    : activeButton === "HEXA"
                                                        ? 32
                                                        : activeButton === "문자열"
                                                            ? 16
                                                            : undefined
                                            }
                                        />
                                        {ivError && <span className="text-danger">{ivError}</span>}
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>

                        <Form.Group className="mb-3" style={{ position: 'relative' }}>
                            <Form.Label>설명</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="description"
                                rows={2}
                                value={formData.description}
                                onChange={handleInputChange}
                                className="input-hover"
                            />
                            {formData.description && (
                                <span
                                    onClick={() => setFormData((prevData) => ({ ...prevData, description: '' }))}
                                    style={{
                                        cursor: 'pointer',
                                        position: 'absolute',
                                        right: '10px',
                                        top: '50%',
                                        zIndex: 9
                                    }}
                                >
                                    <AiFillCloseCircle />
                                </span>
                            )}
                        </Form.Group>
                        <div className="d-flex justify-content-end my-3">
                            <Button className="mx-2" variant="outline-danger" style={{ width: '7rem' }} onClick={handleDeleteClick}>
                                취소
                            </Button>
                            <Button variant="primary" style={{ width: '7rem' }} disabled={!isSubmitEnabled()} onClick={handleConfirmClick}>
                                등록
                            </Button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>

            <CheckOutModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                onConfirm={handleCheckOutModalConfirm}
                title="취소"
                body="취소하시겠습니까?"
                // cancelText="취소"
                confirmText="취소"
                variant="warning"
                SubmitButton={true}
            />

            <CheckOutModal
                show={modalConfirmShow}
                onHide={() => setModalConfirmShow(false)}
                onConfirm={handleConfirm}
                title="확인"
                body="등록하시겠습니까?"
                cancelText="취소"
                confirmText="등록"
                variant="info"
                CancelButton={true}
                SubmitButton={true}
            />
        </div>
    );
};

export default addencryptPublicKey;
