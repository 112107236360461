import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts';
import { Card, Spinner } from 'react-bootstrap';
import { policyPerRank } from 'hooks/CallApi'; // API 함수 import

const SiteIdleRate = () => {
  const [isLoading, setIsLoading] = useState(true); // 로딩 상태
  const [chartData, setChartData] = useState({
    customerNames: [],
    idleRatios: [],
    activeRatios: [],
    rawData: [],
  });

  // 데이터 로드 함수
  const fetchData = async () => {
    try {
      setIsLoading(true); // 로딩 시작

      const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const response = await policyPerRank({ timeZone: clientTimeZone }); // API 호출
      console.log("[policyPerRank] RESPONSE", response);
      const apiData = response.data;

      // 데이터 포맷팅
      const idlePolicyData = apiData.map((item) => ({
        name: item.companyName,
        unUsedPer: item.unUsedPer,
        usedPer: item.usedPer,
        unUsedCnt: item.unUsedCnt,
        usedCnt: item.usedCnt,
      }));

      setChartData({
        customerNames: idlePolicyData.map((item) => item.name),
        idleRatios: idlePolicyData.map((item) => item.unUsedPer),
        activeRatios: idlePolicyData.map((item) => item.usedPer),
        rawData: idlePolicyData, // 원본 데이터 저장
      });
    } catch (error) {
      console.error("API 데이터 호출 실패:", error);
    } finally {
      setIsLoading(false); // 로딩 종료
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Card>
      <Card.Header className="pb-0 d-flex align-items-center">
        <h5 style={{ margin: 0 }}>고객사별 암호키 유휴율</h5>
      </Card.Header>
      <Card.Body>
        {isLoading ? (
          // 로딩 중일 때 스피너 표시
          <div
            style={{
              width: '100%',
              height: '15rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <IdlePolicyChart
            customerNames={chartData.customerNames}
            idleRatios={chartData.idleRatios}
            activeRatios={chartData.activeRatios}
            rawData={chartData.rawData}
          />
        )}
      </Card.Body>
    </Card>
  );
};

// 차트 렌더링 컴포넌트
const IdlePolicyChart = ({ customerNames, idleRatios, activeRatios, rawData }) => {
  useEffect(() => {
    const chartDom = document.getElementById('idlePolicyChart');
    if (!chartDom) {
      console.error("차트를 렌더링할 DOM 요소가 존재하지 않습니다.");
      return;
    }

    const idlePolicyChart = echarts.init(chartDom);

    // 차트 옵션
    const option = {
      title: {
        left: 'center',
        top: '5%',
        textStyle: {
          fontSize: 20,
        },
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'shadow' },
        formatter: (params) => {
          let tooltipText = `${params[0].name}<br/>`;

          params.forEach((param) => {
            const dataIndex = param.dataIndex;
            const item = rawData[dataIndex];

            // 유휴 암호키와 활성 암호키의 합 계산
            const totalKeys = item.unUsedCnt + item.usedCnt;

            // 회사명 밑에 전체 암호키 추가
            if (param.seriesName === '유휴 암호키') {
              tooltipText += `전체 암호키: ${totalKeys}개<br/>`;
              tooltipText += `${param.marker} 유휴 암호키: ${item.unUsedCnt}개 (${item.unUsedPer}%)<br/>`;
            } else if (param.seriesName === '활성 암호키') {
              tooltipText += `${param.marker} 활성 암호키: ${item.usedCnt}개 (${item.usedPer}%)<br/>`;
            }
          });

          return tooltipText;
        },
      },
      legend: {
        data: ['유휴 암호키', '활성 암호키'],
        bottom: '5%',
        textStyle: {
          fontSize: 14,
        },
      },
      grid: {
        top: '20%',
        left: '10%',
        right: '10%',
        bottom: '15%',
        containLabel: true,
      },
      xAxis: {
        type: 'value',
        name: '비율 (%)',
        min: 0,
        max: 100,
        axisLabel: {
          formatter: '{value} %',
        },
        splitLine: {
          show: true,
        },
      },
      yAxis: {
        type: 'category',
        data: customerNames,
        axisLabel: {
          fontSize: 14,
        },
      },
      series: [
        {
          name: '유휴 암호키',
          type: 'bar',
          stack: 'total',
          data: idleRatios,
          itemStyle: {
            color: 'lightgrey',
          },
          label: {
            show: true,
            position: 'insideRight',
            formatter: '{c} %',
            fontSize: 12,
            color: '#fff',
          },
        },
        {
          name: '활성 암호키',
          type: 'bar',
          stack: 'total',
          data: activeRatios,
          itemStyle: {
            color: '#5cb85c',
          },
          label: {
            show: true,
            position: 'insideLeft',
            formatter: '{c} %',
            fontSize: 12,
            color: '#fff',
          },
        },
      ],
    };

    idlePolicyChart.setOption(option);

    const handleResize = () => {
      idlePolicyChart.resize();
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      idlePolicyChart.dispose();
    };
  }, [customerNames, idleRatios, activeRatios, rawData]);

  return <div id="idlePolicyChart" style={{ width: '100%', height: '20rem' }}></div>;
};

export default SiteIdleRate;
