import React, { useState, useEffect, useRef } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { Korean } from 'flatpickr/dist/l10n/ko.js'; // 한국어 로케일 가져오기
import SummaryChart from './SummaryChart'
import SiteIssueRank from './SiteIssueRank'
import SiteActiveRank from './SiteActiveRank'
import AverageApiRank from './AverageApiRank'
import SiteApiProcessSpeed from './SiteApiProcessSpeed'
import SiteAverageApiProcess from './SiteAverageApiProcess'
import SiteCfProcessSpeed from './SiteCfProcessSpeed'
import AverageCfRank from './AverageCfRank'
import SiteAverageCfProcess from './SiteAverageCfProcess'
import SiteSDKProcessSpeed from './SiteSDKProcessSpeed'
import AverageSDKRank from './AverageSDKRank'
import SiteAverageSDKProcess from './SiteAverageSDKProcess'
import SiteIdleRate from './SiteIdleRate'
import SiteIdleAverage from './SiteIdleAverage'
import AlgorithmCompare from './AlgorithmCompare';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useAppContext } from 'providers/AppProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

const Crm = () => {
  const [activeButton, setActiveButton] = useState('day'); // 초기값: 어제 버튼 활성화
  const [rows, setRows] = useState([
    [

      { id: 'Summary', component: <SummaryChart />, size: { xl: 12, xxl: 4 } },
      { id: 'SiteIssueRank', component: <SiteIssueRank />, size: { xl: 6, xxl: 4 } },
      { id: 'SiteActiveRank', component: <SiteActiveRank />, size: { xl: 6, xxl: 4 } },
    ],
    [
      { id: 'AverageApiRank', component: <AverageApiRank />, size: { xl: 12, xxl: 2 } },
      { id: 'SiteAverageApiProcess', component: <SiteAverageApiProcess />, size: { xl: 12, xxl: 5 } },
      { id: 'SiteApiProcessSpeed', component: <SiteApiProcessSpeed />, size: { xl: 12, xxl: 5 } },

      //{ id: 'AverageApiSpeed', component: <AverageApiSpeed />, size: { sm: 12, md: 12, lg: 8, xl: 4, xxl: 5 } },//
    ],
    [
      { id: 'AverageCfRank', component: <AverageCfRank />, size: { xl: 12, xxl: 2 } },
      { id: 'SiteAverageCfProcess', component: <SiteAverageCfProcess />, size: { xl: 12, xxl: 5 } },
      { id: 'SiteCfProcessSpeed', component: <SiteCfProcessSpeed />, size: { xl: 12, xxl: 5 } },
    ],
    [
      { id: 'AverageSDKRank', component: <AverageSDKRank />, size: { xl: 12, xxl: 2 } },
      { id: 'SiteAverageSDKProcess', component: <SiteAverageSDKProcess />, size: { xl: 12, xxl: 5 } },
      { id: 'SiteSDKProcessSpeed', component: <SiteSDKProcessSpeed />, size: { xl: 12, xxl: 5 } },
    ],
    [
      { id: 'SiteIdleRate', component: <SiteIdleRate />, size: { xl: 6, xxl: 5 } },
      { id: 'SiteIdleAverage', component: <SiteIdleAverage />, size: { xl: 6, xxl: 5 } },
      { id: 'AlgorithmCompare', component: <AlgorithmCompare />, size: { xl: 12, xxl: 2 } }
    ]

  ]);
  const {
    config: { dashboardDate, Chart_startdate, Chart_enddate },
    setConfig
  } = useAppContext();

  // 날짜 포맷팅 함수
  const formatDate = (date) => {
    if (!date) return '';
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const startPickerRef = useRef(null);
  const endPickerRef = useRef(null);

  useEffect(() => {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    // Start Date Flatpickr
    startPickerRef.current = flatpickr('#timepicker_start', {
      mode: 'single',
      dateFormat: 'Y-m-d',
      locale: Korean,
      // enableTime: true,
      // time_24hr: true,
      disableMobile: true, // 모바일 환경에서도 Flatpickr 강제
      defaultDate: yesterday,
      // onOpen: () => console.log('Start Date Picker Opened'), // 디버깅용
      onChange: (selectedDates) => {
        const startDate = formatDate(selectedDates[0]);

        setConfig('Chart_startdate', startDate);
        setActiveButton('custom');
      },
    });

    // End Date Flatpickr
    endPickerRef.current = flatpickr('#timepicker_end', {
      mode: 'single',
      dateFormat: 'Y-m-d',
      locale: Korean,
      // enableTime: true,
      // time_24hr: true,
      disableMobile: true, // 모바일 환경에서도 Flatpickr 강제
      defaultDate: today,
      // onOpen: () => console.log('End Date Picker Opened'), // 디버깅용
      onChange: (selectedDates) => {
        const endDate = formatDate(selectedDates[0]);
        setConfig('Chart_enddate', endDate);
        setActiveButton('custom');
      },
    });

    return () => {
      // Cleanup to prevent multiple instances
      startPickerRef.current?.destroy();
      endPickerRef.current?.destroy();
    };
  }, []);

  useEffect(() => {


    // day, week, month 버튼 클릭 시 startdate와 enddate 초기화

    let dateLabel;
    let dateValue;

    switch (activeButton) {
      case 'day':
        dateLabel = '최근 1일';
        dateValue = 'day';
        break;
      case 'week':
        dateLabel = '최근 1주일';
        dateValue = 'week';
        break;
      case 'month':
        dateLabel = '최근 1개월';
        dateValue = 'month';
        break;
      default:
        dateLabel = '커스텀';
        dateValue = 'custom';
        break;
    }
    if (activeButton !== 'custom') {
      setConfig('Chart_startdate', null);
      setConfig('Chart_enddate', null);
    }
    setConfig('dashboardDate', dateLabel);
    setConfig('dashboardDateValue', dateValue);
  }, [activeButton, setConfig]);



  const handleButtonClick = (button) => {
    // const today = new Date();
    // const now = new Date(); // 현재 시간
    let startDate, endDate;

    switch (button) {
      case 'day':
        endDate = new Date(); // 현재 시간
        startDate = new Date();
        // startDate.setDate(today.getDate() - 1); // 하루 전
        startDate.setHours(endDate.getHours() - 1); // 한시간 전
        break;

      case 'week':
        endDate = new Date(); // 현재 시간
        startDate = new Date();
        startDate.setDate(endDate.getDate() - 7); // 7일전
        break;
      case 'month':
        endDate = new Date(); // 현재 시간
        startDate = new Date();
        // startDate.setMonth(today.getMonth() - 1);
        // startDate.setDate(1); // 이전 월의 첫날
        // endDate = new Date(today.getFullYear(), today.getMonth(), 0); // 이전 월의 마지막 날
        // 위 주석은 달 단위인데 바꿈
        startDate.setDate(endDate.getDate() - 28); // 오늘 제외한 28일 전
        break;

      default:
        return;

    }

    // flatpickr('#timepicker_start', {
    //   mode: 'single',
    //   dateFormat: 'Y-m-d H:i',
    //   locale: Korean,
    //   enableTime: true,
    //   time_24hr: true,
    //   position: 'below',
    //   disableMobile: true,
    //   defaultDate: startDate,
    //   onChange: () => setActiveButton('custom'), // 날짜 변경 시 'custom'으로 설정
    // });

    // flatpickr('#timepicker_end', {
    //   mode: 'single',
    //   dateFormat: 'Y-m-d H:i',
    //   locale: Korean,
    //   enableTime: true,
    //   time_24hr: true,
    //   position: 'below',
    //   disableMobile: true,
    //   defaultDate: endDate,
    //   onChange: () => setActiveButton('custom'), // 날짜 변경 시 'custom'으로 설정

    // });

    setActiveButton(button);
  };

  const getButtonVariant = (button) => {
    return activeButton === button ? 'primary' : 'outline-primary'; // 활성화된 버튼에 따라 스타일 적용
  };

  const handleOnDragEnd = (result) => {
    const { source, destination } = result;

    // 영역 외에 드롭이면 무반응
    if (!destination) return;

    const sourceRowIndex = parseInt(source.droppableId);
    const destinationRowIndex = parseInt(destination.droppableId);

    const sourceRow = Array.from(rows[sourceRowIndex]);
    const destinationRow = Array.from(rows[destinationRowIndex]);

    if (destination.index >= destinationRow.length) return;

    // 동일한 Row
    if (sourceRowIndex === destinationRowIndex) {
      // 위치 스왑
      const temp = sourceRow[source.index];
      sourceRow[source.index] = sourceRow[destination.index];
      sourceRow[destination.index] = temp;

      const newRows = [...rows];
      newRows[sourceRowIndex] = sourceRow;
      setRows(newRows);
    }
    // 서로 다른 Row
    else {
      const [movedItem] = sourceRow.splice(source.index, 1);
      destinationRow.splice(destination.index, 0, movedItem);

      // 위치 스왑
      // const temp = sourceRow[source.index];
      // sourceRow[source.index] = destinationRow[destination.index];
      // destinationRow[destination.index] = temp;

      const newRows = [...rows];
      newRows[sourceRowIndex] = sourceRow;
      newRows[destinationRowIndex] = destinationRow;
      setRows(newRows);
    }
  };

  return (
    <>
      <div className="my-2 d-flex justify-content-between align-items-center">

        <h4>SafeDB 대시보드</h4>
        <div className="d-flex align-items-center">

          <input
            style={{ height: 29, fontSize: 12, width: '9rem' }} // width를 100%로 설정
            className="form-control datetimepicker"
            id="timepicker_start"
            data-input
          />

          <input
            style={{ height: 29, fontSize: 12, width: '9rem' }} // width를 100%로 설정
            className="form-control datetimepicker"
            id="timepicker_end"
          // type="text"
          />

          <Button
            variant={getButtonVariant('day')}
            onClick={() => handleButtonClick('day')}
            style={{ height: 29, fontSize: 12, whiteSpace: 'nowrap', marginLeft: '1rem' }}>
            최근 1일
          </Button>
          <Button
            variant={getButtonVariant('week')}
            onClick={() => handleButtonClick('week')}
            style={{ height: 29, fontSize: 12, whiteSpace: 'nowrap', marginLeft: '1rem', marginRight: '1rem' }}

          >
            최근 1주일
          </Button>
          <Button
            variant={getButtonVariant('month')}
            onClick={() => handleButtonClick('month')}
            style={{ height: 29, fontSize: 12, whiteSpace: 'nowrap' }}

          >
            최근 1개월
          </Button>
        </div>
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        {rows.map((row, rowIndex) => (
          <Droppable droppableId={`${rowIndex}`} key={rowIndex} direction="horizontal">
            {(provided) => (
              <Row className="g-3 mb-3" ref={provided.innerRef} {...provided.droppableProps}>
                {row.map((comp, colIndex) => (
                  <Draggable key={comp.id} draggableId={comp.id} index={colIndex}>
                    {(provided) => (
                      <Col
                        md={comp.size.md}
                        lg={comp.size.lg}
                        xl={comp.size.xl}
                        xxl={comp.size.xxl}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {comp.component}
                      </Col>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Row>
            )}
          </Droppable>
        ))}
      </DragDropContext>
      {/* <Greetings />
      <Row className="g-3 mb-3">
        <Col xxl={9}>
          <CrmStats />
          <Revenue />
        </Col>
        <Col xxl={3}>
          <MostLeads />
        </Col>
        <Col md={12} xxl={8}>
          <DealForecastBar />
        </Col>
        <Col xxl={4}>
          <DealStorageFunnel />
        </Col>
        <Col xxl={6}>
          <DealVsGoal />
        </Col>
        <Col xxl={6}>
          <DealForeCast />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col lg={7}>
          <LocationBySession />
        </Col>
        <Col lg={5}>
          <Row className="g-3">
            <Col xs={12}>
              <AvgCallDuration />
            </Col>
            <Col xs={12}>
              <LeadConversation />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="g-3">
        <Col lg={5}>
          <ToDoList />
        </Col>
        <Col lg={7}>
          <RecentLeads />
        </Col>
      </Row> */}
    </>
  );
};

export default Crm;
