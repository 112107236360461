import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  GridComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { Card } from 'react-bootstrap';
import ReactEchart from 'components/common/ReactEchart';
import { useAppContext } from 'providers/AppProvider';
import { rgbaColor } from 'helpers/utils';
import { fetchSixMonthPay } from 'hooks/CallApi';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer
]);

const ChartOptions = () => {
  const { getThemeColor } = useAppContext();
  const [chartData, setChartData] = useState([]);

  // 현재 달 기준으로 지난 6개월의 시작일과 종료일을 계산
  const now = new Date();
  const startDate = new Date(now.getFullYear(), now.getMonth() - 6, 1);
  const endDate = new Date(now.getFullYear(), now.getMonth(), 0);

  // 시작일과 종료일을 'YYYY-MM-DD' 형식으로 포맷팅
  const formatDate = date => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const dateRangeText = `${formatDate(startDate)} ~ ${formatDate(endDate)}`;

  // 데이터 로드
  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await fetchSixMonthPay();
        const data = response.data.map(item => ({
          year: `${item.year_month.split('.')[0]}`,
          month: `${item.year_month.split('.')[1]}월`,
          fee: item.total_fee
        }));

        setChartData(data);
      } catch (error) {
        console.error('데이터 조회 에러:', error);
      }
    };

    loadData();
  }, []);

  // 최근 6개월 월 이름과 요금 데이터를 차트에 적용
  const months = chartData.map(item => item.month);
  const fees = chartData.map(item => item.fee);

  const getOption = () => {
    // 각 월의 시작일과 종료일을 계산
    const monthDateRanges = chartData.map(item => {
      // '2024.6월' 형식의 데이터를 처리하도록 수정
      const month = item.month.replace('월', '').trim().split('.');
      const year = item.year.trim().split('.');


      // 시작일과 종료일 계산
      const startOfMonth = new Date(year, month - 1, 1);
      const endOfMonth = new Date(year, month, 0); // 다음 달의 0번째 날 = 현재 월의 마지막 날
      return {
        month: item.month,
        startDate: formatDate(startOfMonth),
        endDate: formatDate(endOfMonth),
      };
    });


    return {
      tooltip: {
        trigger: 'axis',
        padding: [7, 10],
        backgroundColor: getThemeColor('gray-100'),
        borderColor: getThemeColor('gray-300'),
        textStyle: { color: getThemeColor('gray-1100') },
        borderWidth: 1,
        transitionDuration: 0,
        formatter: (params) => {
          if (!params.length) return '';
          const { dataIndex } = params[0];
          const dateRange = monthDateRanges[dataIndex];
          // 단위를 만원으로 변환
          const feeInTenThousands = (params[0].value / 10000).toFixed(0); // 소수점 둘째 자리까지 표시

          return `
            ${dateRange.startDate} ~ ${dateRange.endDate} 요금청구량 : ${feeInTenThousands}만 원
          `;
        }
      },
      xAxis: {
        type: 'category',
        data: months,
        boundaryGap: false,
        axisLine: {
          lineStyle: {
            color: getThemeColor('gray-300')
          }
        },
        axisTick: { show: false },
        axisLabel: {
          color: getThemeColor('gray-400'),
          margin: 15
        },
        splitLine: { show: false }
      },
      yAxis: {
        type: 'value',
        splitLine: {
          lineStyle: {
            type: 'dashed',
            color: getThemeColor('gray-200')
          }
        },
        axisLabel: {
          show: true,
          color: getThemeColor('gray-400'),
          margin: 15
        },
        axisTick: { show: false },
        axisLine: { show: false }
      },
      series: [
        {
          type: 'line',
          data: fees,
          itemStyle: {
            color: '#A1C4E3',
            borderColor: '#3067A4',
            borderWidth: 2
          },
          lineStyle: {
            color: '#3067A4'
          },
          showSymbol: true,
          symbol: 'circle',
          symbolSize: 6,
          smooth: false,
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: rgbaColor('#3067A4', 0.8)
                },
                {
                  offset: 1,
                  color: rgbaColor('#3067A4', 0.2)
                }
              ]
            }
          }
        }
      ],
      grid: { right: '3%', left: '15%', bottom: '10%', top: '20%' }
    };
  };
  return (
    <Card>
      <Card.Header className="pb-0 d-flex align-items-center">
        <h5 style={{ margin: 0 }}>최근 6개월간 요금 청구량 &nbsp;</h5> <h6 style={{ margin: 0 }}>{dateRangeText}</h6>
      </Card.Header>
      <ReactEchart
        echarts={echarts}
        option={getOption()}
        style={{ height: '25rem' }}
      />
    </Card>
  );
};

ChartOptions.propTypes = {
  data: PropTypes.arrayOf(PropTypes.number)
};

export default ChartOptions;
