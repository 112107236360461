import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import Flex from 'components/common/Flex';
import { useAppContext } from 'providers/AppProvider';
import { Card, Spinner } from 'react-bootstrap';
import { allReqSummary } from 'hooks/CallApi';



const SummaryChart = () => {
  const chartRef = useRef(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1536);
  const [isLoading, setIsLoading] = useState(true); // 로딩 상태 추가

  // const parseDuration = (duration) => {
  //   if (typeof duration !== 'string') return 0; // 문자열이 아니면 0 반환
  //   const numericPart = duration.replace("PT", "").replace("S", "");
  //   return Math.round(parseFloat(numericPart) * 10) / 10; // 소수 첫째 자리로 반올림
  // };

  const [chartData, setChartData] = useState({
    totalRequests: 0,
    apiRequests: 0,
    cloudFunctionsRequests: 0,
    sdkRequests: 0,

    apirequestPer: 0,
    cfrequestPer: 0,
    sdkrequestPer: 0,

    avgAPICalDuration: 0,
    avgCFCalDuration: 0,
    avgSDKCalDuration: 0,

    failAPIRequestPer: 0,
    failCFRequestPer: 0,
    failSDKRequestPer: 0,
    totalCompanyCnt: 0,
  });
  const {
    config: { dashboardDate, dashboardDateValue, Chart_startdate, Chart_enddate },
    setConfig
  } = useAppContext();
  useEffect(() => {
    const handleResize = () => setIsMobileView(window.innerWidth <= 1536);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [dateRange, setDateRange] = useState({
    start: Chart_startdate,
    end: Chart_enddate
  });
  useEffect(() => {
    // Chart_startdate 또는 Chart_enddate가 변경될 때 상태 업데이트
    setDateRange({
      start: Chart_startdate,
      end: Chart_enddate
    });
  }, [Chart_startdate, Chart_enddate]);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // 데이터 로드 시작 시 로딩 상태 활성화

      try {
        const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // API 호출
        const response = await allReqSummary({
          period: dashboardDateValue,
          start: dateRange.start,
          end: dateRange.end,
          timeZone: clientTimeZone
        });
        const data = response.data; // API 응답에서 첫 번째 데이터 사용


        // API 응답 데이터를 상태에 저장
        setChartData({
          totalRequests: data.totalRequestCnt || 0,
          apiRequests: data.totalAPIRequestCnt || 0,
          cloudFunctionsRequests: data.totalCFRequestCnt || 0,
          sdkRequests: data.totalSDKRequestCnt || 0,

          apirequestPer: data.apirequestPer || 0,
          cfrequestPer: data.cfrequestPer || 0,
          sdkrequestPer: data.sdkrequestPer || 0,

          avgAPICalDuration: data.avgAPICalDuration || 0,
          avgCFCalDuration: data.avgCFCalDuration || 0,
          avgSDKCalDuration: data.avgSDKCalDuration || 0,

          failAPIRequestPer: data.failAPIRequestPer || 0,
          failCFRequestPer: data.failCFRequestPer || 0,
          failSDKRequestPer: data.failSDKRequestPer || 0,
          totalCompanyCnt: data.totalCompanyCnt || 0,
        });



      } catch (error) {
        console.error('API 데이터 조회 실패:', error);
      } finally {
        setIsLoading(false); // 데이터 로드 완료 시 로딩 상태 비활성화
      }
    };

    fetchData();
  }, [dashboardDate, dateRange]);


  useEffect(() => {
    if (isLoading) return; // 로딩 중일 때는 차트를 렌더링하지 않음

    const chartInstance = echarts.init(chartRef.current);

    const option = {
      tooltip: {
        trigger: 'item',
        formatter: (params) => {
          const { name, value } = params;
          return `${name}: ${value.toLocaleString()}건 (${params.percent}%)`;
        },
      },
      legend: {
        orient: 'horizontal',
        bottom: 2,
        textStyle: { fontSize: 10 },
        data: ['API', 'CloudFunctions', 'SDK']
      },
      series: [
        {
          name: '요청 분포',
          type: 'pie',
          radius: ['50%', '85%'],
          center: ['50%', '45%'],
          avoidLabelOverlap: false,
          label: {
            show: true,
            position: 'center',
            formatter: [
              '{total|총 요청량}',
              `{count|${chartData.totalRequests.toLocaleString()}건}`
            ].join('\n'),
            rich: {
              total: {
                fontSize: 16,
                color: '#333',
                fontWeight: 'bold',
                align: 'center',
                padding: [10, 0]
              },
              count: {
                fontSize: 14,
                color: '#666',
                align: 'center'
              }
            }
          },
          labelLine: { show: false },
          data: [
            // { value: 70, name: 'API' },
            // { value: 20, name: 'CloudFunctions' },
            // { value: 10, name: 'SDK' }
            { value: chartData.apiRequests, name: 'API' },
            { value: chartData.cloudFunctionsRequests, name: 'CloudFunctions' },
            { value: chartData.sdkRequests, name: 'SDK' }
          ],
          color: ['#FF7F50', '#27a093', '#2AA012']
        }
      ]
    };

    chartInstance.setOption(option);
    chartInstance.resize();

    return () => chartInstance.dispose();
  }, [chartData]);
  // if (isLoading) {
  //   // 로딩 상태일 때 스피너를 표시
  //   return (
  //     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
  //       <Spinner animation="border" role="status">
  //         <span className="visually-hidden">Loading...</span>
  //       </Spinner>
  //     </div>
  //   );
  // }
  return (
    <Card className="h-md-100">
      <Card.Header className="pb-0 d-flex align-items-center">
        <h5 style={{ margin: 0 }}>요약</h5>
        <span
          style={{
            marginLeft: '8px',
            marginTop: 0,
            marginBottom: 0,
            padding: '2px 8px',
            backgroundColor: '#A1C4E3',
            color: 'white',
            borderRadius: '4px',
            fontSize: '12px',
            fontWeight: 'bold'
          }}
        >
          {dashboardDate}
        </span>
      </Card.Header>



      <Card.Body as={Flex} alignItems="center" className="px-0">
        {isLoading ? (
          // 로딩 중일 때 카드 내부에 스피너 표시
          <div
            style={{
              width: '100%',
              height: '15rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div
            className="container"
            style={{
              ...containerStyle,
              flexDirection: isMobileView ? 'row' : 'row'
            }}
          >
            <div className="left" style={{ ...leftStyle, width: isMobileView ? '100%' : '10%' }}>
              <div className="label" style={{ fontSize: '0.9rem' }}><strong>전체 고객사 수</strong></div>
              <div className="total-customers" style={{ fontSize: '1rem' }}>{chartData.totalCompanyCnt}</div>
            </div>
            <div className="center-chart px-0" style={centerChartStyle} ref={chartRef}></div>
            <div className="right" style={{ ...rightStyle, width: isMobileView ? '100%' : '100%' }}>
              <div className="center-title" style={{ fontSize: '0.7rem' }}><strong>인증키 타입별 평균 처리 시간, <br />요청 중 실패 비율</strong></div>
              {/* <div className="right-content">
              <div className="metric api" style={metricAPIStyle}>API</div>
              <div className="value">0.8초 <span className="label" style={labelStyle}>0.1%</span></div>
              <div className="metric cloudfunctions" style={metricCloudFunctionsStyle}>CloudFunctions</div>
              <div className="value">0.2초 <span className="label" style={labelStyle}>0.1%</span></div>
              <div className="metric sdk" style={metricSDKStyle}>SDK</div>
              <div className="value">0.2초 <span className="label" style={labelStyle}>0.1%</span></div>
            </div> */}
              <div className="right-content">
                <div className="metric api" style={metricAPIStyle}>API</div>
                <div className="value">
                  {/* {parseDuration(chartData.avgAPICalDuration)}초{' '} */}
                  {chartData.avgAPICalDuration}초{' '}
                  <span className="label" style={labelStyle}>{chartData.failAPIRequestPer}%</span>
                </div>
                <div className="metric cloudfunctions" style={metricCloudFunctionsStyle}>CloudFunctions</div>
                <div className="value">
                  {chartData.avgCFCalDuration}초{' '}
                  <span className="label" style={labelStyle}>{chartData.failCFRequestPer}%</span>
                </div>
                <div className="metric sdk" style={metricSDKStyle}>SDK</div>
                <div className="value">
                  {chartData.avgSDKCalDuration}초{' '}
                  <span className="label" style={labelStyle}>{chartData.failSDKRequestPer}%</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

// 스타일 객체 정의
const containerStyle = {
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '100%',
  maxWidth: '800px',
  margin: '0 auto',
  padding: '10px',
  boxSizing: 'border-box'
};

const leftStyle = {
  flex: 2,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center'
};

const centerChartStyle = {
  flex: 4,
  width: '100%',
  height: '15rem'
};

const rightStyle = {
  flex: 3,
  textAlign: 'left',
  marginLeft: '1%',
  marginTop: '1%',
  marginBottom: '0%'
};

const metricAPIStyle = {
  fontSize: '1em',
  fontWeight: 'bold',
  marginBottom: '2%',
  color: '#FF7F50'
};

const metricCloudFunctionsStyle = {
  fontSize: '1em',
  fontWeight: 'bold',
  marginBottom: '2%',
  color: '#27a093',
  marginTop: '5%'
};
const metricSDKStyle = {
  fontSize: '1em',
  fontWeight: 'bold',
  marginBottom: '2%',
  color: '#2AA012',
  marginTop: '5%'
};

const labelStyle = {
  color: '#666',
  fontSize: '0.7em'
};

export default SummaryChart;
