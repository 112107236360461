import React, { useState, useEffect } from 'react';

import { Card, Button, Spinner } from 'react-bootstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
// import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
// import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter_paging';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination_paging';

// import data from 'safedb/common/sampledata/sampledataApi'; // data.js 파일에서 데이터 가져오기
import paths from 'routes/paths';
import GroupManagementModal from 'components/modal/GroupManagementModal';

import GroupAddModal from 'components/modal/GroupAddModal'; // 경로에 맞게 수정
import { fetchApiKeyList } from 'hooks/CallApi'; // API 함수 가져오기
import { formatDate } from 'components/common/SunnyUtils';

const columns = [
    { accessorKey: 'num', header: '순번', meta: { headerProps: { className: 'text-900' } } },
    { accessorKey: 'status', header: '상태', meta: { headerProps: { className: 'text-900' } } },
    { accessorKey: 'group', header: '그룹', meta: { maxlength: 20, headerProps: { className: 'text-900' } } },
    { accessorKey: 'keyname', header: '인증키 이름', meta: { maxlength: 20, headerProps: { className: 'text-900' } } },
    { accessorKey: 'role', header: '용도', meta: { headerProps: { className: 'text-900' } } },
    { accessorKey: 'desc', header: '설명', meta: { maxlength: 20, headerProps: { className: 'text-900' } } },
    { accessorKey: 'createdAt', header: '등록일시', meta: { maxlength: 19, headerProps: { className: 'text-900' } } },
    { accessorKey: 'invalidAt', header: '만료일시', meta: { maxlength: 19, headerProps: { className: 'text-900' } } }
];

function AdvanceTableExample() {
    const navigate = useNavigate(); // useNavigate 훅 사용

    const [tableData, setTableData] = useState([]); // 데이터 상태 추가
    const [showGroupModal, setShowGroupModal] = useState(false); // 모달 상태 추가
    const [showGroupAddModal, setShowGroupAddModal] = useState(false); // 모달 상태 추가

    const [formData, setFormData] = useState({ adderId: '' });
    const [totalElements, setTotalElements] = useState(0);
    const [pageSize, setPageSize] = useState(10); // 초기 페이지 사이즈 설정

    const [loading, setLoading] = useState(true); // 로딩 상태
    const [showSpinner, setShowSpinner] = useState(false); // 스피너 상태 추가
    const [currentPage, setCurrentPage] = useState(0); // 현재 페이지
    const [perPage, setPerPage] = useState(10); // 페이지당 항목 수 (size)

    const handleGroupModalShow = () => {
        setShowGroupModal(true); // 모달 열기
    };

    const handleGroupModalHide = () => {
        setShowGroupModal(false); // 모달 닫기
    };
    const handleGroupModalConfirm = () => {
        // console.log('그룹 등록:', formData.adderId);
        // setShowGroupModal(false);
        // setFormData({ adderId: '' });
        setShowGroupModal(false);
        setShowGroupAddModal(true);
    };


    const handleGroupAddModalHide = () => {
        setShowGroupAddModal(false); // 모달 닫기
    };
    const handleGroupAddModalConfirm = () => {
        setShowGroupAddModal(false);
    };

    const table = useAdvanceTable({
        // data, // 전체 데이터를 전달
        data: tableData, // 상태에서 데이터 가져오기
        columns,
        sortable: true,
        selection: false,
        pagination: true,
        perPage,
        selectionColumnWidth: 30
    });
    // API 호출 함수
    const loadData = async (page, size) => {
        // console.log("Fetching data with page:", page, "and size:", size);
        const spinnerTimer = setTimeout(() => setShowSpinner(true), 300);

        try {
            const response = await fetchApiKeyList(page, size); // API 호출 
            console.log("APIKEY LIST", response);
            const logData = response.data.content.map((item, index) => ({
                // num: index + 1 + page * size, // 순번 계산
                num: (page * size) + index + 1, // 역순으로 순번 계산
                createdAt: item.insertTimeUtc
                    ? formatDate(item.insertTimeUtc) // 포맷 함수 호출
                    : '-',
                invalidAt: item.expiredTimeUtc
                    ? formatDate(item.expiredTimeUtc) // 포맷 함수 호출
                    : '-',
                remakeAt: item.reissuanceTimeUtc
                    ? formatDate(item.reissuanceTimeUtc) // 포맷 함수 호출
                    : '-',
                status: item.apikeyStatus ? '정상' : '만료',
                apikeyStatus: item.apikeyStatus,
                group: item.apiGroupName,
                keyname: item.apikeyName,
                role: item.apikeyType,
                desc: item.apikeyDescription,
                adderId: item.issuerId,
                adderRole: item.issuerRole,
                apikey: item.apiKey,
                keyuuid: item.apikeyId.apikeyUuid,
                keycompanyId: item.apikeyId.companyId,
                apiGroupUuid: item.apiGroupUuid
            }));
            setTotalElements(response.data.page.totalElements);
            setTableData(logData);
        } catch (error) {
            console.error('API 데이터 로드 에러:', error);
        } finally {
            clearTimeout(spinnerTimer); // 데이터 로딩이 끝나면 스피너 타이머 제거
            setLoading(false);
            setShowSpinner(false); // 로딩 완료 후 스피너를 숨김
        }
    };

    // 페이지 변경 시 데이터 다시 로드
    useEffect(() => {
        console.log("Current page or perPage changed:", currentPage, perPage);
        loadData(currentPage, perPage);
    }, [currentPage, perPage]);

    // 페이지네이션 이벤트 핸들러
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        table.resetRowSelection(); // 선택된 행 초기화
    };

    const handlePerPageChange = (newPerPage) => {
        console.log("Changing perPage to:", newPerPage);
        setPerPage(newPerPage); // 페이지 크기 업데이트
        setCurrentPage(0); // 첫 페이지로 이동
    };

    const handleRowClick = row => {
        const { createdAt, invalidAt, remakeAt, lastuseAt, apiGroupUuid, status, apikeyStatus, group, keyname, role, desc, keyuuid, keycompanyId, adderId, adderRole, apikey } = row.original; // 필요한 데이터 추출
        navigate(`/safedb/common/key/apikey/edit`, {
            state: { createdAt, invalidAt, remakeAt, lastuseAt, apiGroupUuid, status, apikeyStatus, group, keyname, role, desc, keyuuid, keycompanyId, adderId, adderRole, apikey } // 전달할 데이터
        });
    };

    return (
        <>
            <AdvanceTableProvider {...table}>
                {/* <BulAction rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} /> */}
                <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }} className="mb-2">
                    {/* <AdvanceTableFooter rowsPerPageSelection /> */}
                    <AdvanceTableFooter
                        rowsPerPageSelection
                        onPageChange={(page, newPageSize) => {
                            handlePerPageChange(newPageSize); // 페이지 크기 변경
                            setCurrentPage(page); // 현재 페이지 설정
                        }}
                    />
                    <Button
                        variant="outline-success"
                        style={{
                            position: 'absolute',
                            right: 160,
                            top: -8,
                            width: '7rem'
                        }}
                        onClick={handleGroupModalShow} // 모달 열기

                    >
                        그룹 관리
                    </Button>

                    <Button
                        variant="success"
                        style={{
                            position: 'absolute',
                            right: 10,
                            top: -8,
                            width: '9rem'
                        }}
                        onClick={() => navigate(paths.addapikey, { state: { keycompanyId: tableData[0]?.keycompanyId || null } })}

                    >
                        인증키 등록
                    </Button>




                </div>
                {loading ? (
                    showSpinner ? (
                        <div className="d-flex justify-content-center align-items-center">
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    ) : (
                        <p></p>
                    )
                ) : (
                    <AdvanceTable
                        headerClassName="bg-200 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            striped: true,
                            className: 'fs-10 mb-0 overflow-hidden'
                        }}
                        onRowClick={handleRowClick} // 이벤트 핸들러 전달

                    />
                )}
                <div className="mt-3">
                    <p style={{ margin: 0, fontWeight: 700 }}>총 {totalElements.toLocaleString()}건</p>
                    {/* <AdvanceTablePagination /> */}
                    <AdvanceTablePagination
                        currentPage={currentPage}
                        totalItems={totalElements}
                        itemsPerPage={perPage}
                        onChange={(newPage) => handlePageChange(newPage)}
                    />
                </div>

            </AdvanceTableProvider >
            <GroupManagementModal
                show={showGroupModal}
                onHide={handleGroupModalHide}
                onConfirm={handleGroupModalConfirm}
            // formData={formData}
            // setFormData={setFormData}
            />
            <GroupAddModal
                show={showGroupAddModal}
                onHide={handleGroupAddModalHide}
                onConfirm={handleGroupAddModalConfirm}
            />
        </>
    );
}

const apikey = () => {
    return (
        <div className="container mt-5">
            <Card className="mypage-card">
                <Card.Header as="h4">인증키</Card.Header>
                <Card.Body>
                    <AdvanceTableExample />
                </Card.Body>
            </Card>
        </div>
    );
};

export default apikey;
