import React, { useState, useEffect } from 'react';

import { Card, Button, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter_paging';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination_paging';
import { useNavigate } from 'react-router-dom'; // useNavigate 추가
import data from 'safedb/common/sampledata/sampledataPubKey'; // data.js 파일에서 데이터 가져오기
import CheckOutModal from 'components/modal/CheckOutModal';
import paths from 'routes/paths';
import { fetchPubKeyList, deletePubKey } from 'hooks/CallApi';

const columns = [
  { accessorKey: 'id', header: '순번', meta: { headerProps: { className: 'text-900' } }, enableSorting: false },
  { accessorKey: 'publickeyName', header: '공유키', meta: { maxlength: 20, headerProps: { className: 'text-900' } } },
  { accessorKey: 'algorithm', header: '알고리즘', meta: { maxlength: 10, headerProps: { className: 'text-900' } } },
  { accessorKey: 'keylength', header: '키 길이(bit)', meta: { headerProps: { className: 'text-900' } } },
  { accessorKey: 'mode', header: '운영모드', meta: { headerProps: { className: 'text-900' } } },
  { accessorKey: 'description', header: '설명', meta: { maxlength: 50, headerProps: { className: 'text-900' } } }
];

function AdvanceTableExample() {
  const navigate = useNavigate(); // useNavigate 훅 사용

  const [tableData, setTableData] = useState([]); // 데이터 상태 추가
  const [modalShow, setModalShow] = useState(false); // 모달 상태
  const [modalReShow, setModalReShow] = useState(false); // 모달 상태

  const [loading, setLoading] = useState(true); // 로딩 상태
  const [showSpinner, setShowSpinner] = useState(false); // 스피너 상태 추가
  const [totalElements, setTotalElements] = useState(0);
  const [currentPage, setCurrentPage] = useState(0); // 현재 페이지
  const [perPage, setPerPage] = useState(10); // 페이지당 항목 수 (size)
  const [ErrorCode, setErrorCode] = useState('');
  const [ErrorMsg, setErrorMsg] = useState('');
  const [modalErrorShow, setModalErrorShow] = useState(false);

  // API 호출 함수
  const loadData = async (page, size) => {
    // console.log("Fetching data with page:", page, "and size:", size);
    const spinnerTimer = setTimeout(() => setShowSpinner(true), 300);

    try {
      const response = await fetchPubKeyList(page, size); // API 호출

      const logData = response.data.content.map((item, index) => ({
        // num: index + 1 + page * size, // 순번 계산
        id: (page * size) + index + 1,
        algorithm: item.algo,
        keylength: item.keyLen,
        mode: item.mode,
        publickeyName: item.sharedkeyName, // 공유키 이름
        sharedkeyUuid: item.sharedkeyId.sharedkeyUuid, // 공유키 UUID
        insertTime: item.insertTimeUtc, // 등록 일시
        description: item.description, // 설명
      }));
      setTotalElements(response.data.page.totalElements);
      setTableData(logData);

    } catch (error) {
      console.error('API 데이터 로드 에러:', error);
    } finally {
      clearTimeout(spinnerTimer); // 데이터 로딩이 끝나면 스피너 타이머 제거
      setLoading(false);
      setShowSpinner(false); // 로딩 완료 후 스피너를 숨김
    }
  };

  // 페이지 변경 시 데이터 다시 로드
  useEffect(() => {
    console.log("Current page or perPage changed:", currentPage, perPage);
    loadData(currentPage, perPage);
  }, [currentPage, perPage]);

  // 페이지네이션 이벤트 핸들러
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    table.resetRowSelection(); // 선택된 행 초기화
  };

  const handlePerPageChange = (newPerPage) => {
    console.log("Changing perPage to:", newPerPage);
    setPerPage(newPerPage); // 페이지 크기 업데이트
    setCurrentPage(0); // 첫 페이지로 이동
  };
  const handleRowClick = (row) => {
    const { publickeyName, algorithm, keylength, mode, sharedkeyUuid, insertTime, description } = row.original; // 필요한 데이터 추출
    // navigate(`/safedb/common/key/encryptPublicKey/edit?publickeyName=${publickeyName}&algorithm=${algorithm}&keylength=${keylength}&mode=${mode}`); // 데이터와 함께 이동
    navigate(`/safedb/common/key/encryptPublicKey/edit`, {
      state: { publickeyName, algorithm, keylength, mode, sharedkeyUuid, insertTime, description } // 전달할 데이터
    });
  };


  const handleDeleteClick = () => {
    setModalShow(true); // 관리자 비밀번호 모달 열기
  };

  const table = useAdvanceTable({
    // data, // 전체 데이터를 전달
    data: tableData, // 상태에서 데이터 가져오기
    columns,
    sortable: true,
    selection: true,
    pagination: true,
    perPage,
    selectionColumnWidth: 30
  });

  const selectedRowCount = table.getSelectedRowModel().rows.length;

  const handleDelete = async () => {
    try {
      const selectedRowIds = table.getSelectedRowModel().rows.map(row => row.original.sharedkeyUuid); // 선택된 행의 UUID 가져오기

      for (const uuid of selectedRowIds) {
        await deletePubKey(uuid); // deleteEncKey API 호출
        console.log(" 공유키 삭제 uuid ", uuid);
      }
      await loadData(currentPage, perPage);
      table.resetRowSelection(); // 선택 초기화
    } catch (error) {
      console.error('삭제 중 에러:', error);
      setErrorCode(error.response.data.code);
      setErrorMsg(error.response.data.message);
      setModalErrorShow(true);

    } finally {
      setModalShow(false); // 모달 닫기
      setModalReShow(true);
    }



  };

  const handleDeleteRe = () => {
    const selectedRowIds = table
      .getSelectedRowModel()
      .rows.map(row => row.original.id);
    const newData = tableData.filter(row => !selectedRowIds.includes(row.id)); // 선택된 행 제외
    setTableData(newData); // 데이터 업데이트
    table.resetRowSelection();
    setModalReShow(false);
  };




  return (

    <AdvanceTableProvider {...table}>
      {/* <BulAction rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} /> */}
      <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }} className="mb-2">

        {/* <AdvanceTableFooter rowsPerPageSelection /> */}
        <AdvanceTableFooter
          rowsPerPageSelection
          onPageChange={(page, newPageSize) => {
            handlePerPageChange(newPageSize); // 페이지 크기 변경
            setCurrentPage(page); // 현재 페이지 설정
          }}
        />
        {selectedRowCount > 0 ? (
          <Button
            variant="outline-danger"
            style={{
              position: 'absolute',
              right: 10,
              top: -8,
              width: '100px'
            }}
            onClick={handleDeleteClick}
          >
            삭제
          </Button>
        ) : (
          <Link to={paths.addencryptPublicKey} style={{ textDecoration: 'none' }}>
            <Button
              variant="outline-success"
              style={{
                position: 'absolute',
                right: 10,
                top: -8,
                width: '100px'
              }}
            >
              등록
            </Button>
          </Link>
        )}
      </div>
      {loading ? (
        showSpinner ? (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <p></p>
        )
      ) : (
        <AdvanceTable
          headerClassName="bg-200 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            striped: true,
            className: 'fs-10 mb-0 overflow-hidden'
          }}
          onRowClick={handleRowClick} // 이벤트 핸들러 전달

        />
      )}
      <div className="mt-3">
        <p style={{ margin: 0, fontWeight: 700 }}>총 {totalElements.toLocaleString()}건</p>
        {/* <AdvanceTablePagination /> */}
        <AdvanceTablePagination
          currentPage={currentPage}
          totalItems={totalElements}
          itemsPerPage={perPage}
          onChange={(newPage) => handlePageChange(newPage)}
        />
      </div>

      <CheckOutModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onConfirm={handleDelete}
        title="경고" // 제목
        body="선택하신 관리자에 대한 모든 설정값 및 권한 정보가 삭제됩니다. 이 작업은 취소할 수 없으며, 삭제된 데이터는 복구할 수 없습니다. 정말 삭제하시겠습니까?" // 본문 내용
        cancelText="취소" // 취소 버튼 텍스트
        confirmText="삭제" // 확인 버튼 텍스트
        variant="warning" // 원하는 variant
        CancelButton={true}
        SubmitButton={true}
      />
      <CheckOutModal
        show={modalReShow}
        onHide={() => setModalReShow(false)}
        onConfirm={handleDeleteRe}
        title="완료" // 제목
        body="삭제 되었습니다." // 본문 내용
        // cancelText="취소" // 취소 버튼 텍스트
        confirmText="확인" // 확인 버튼 텍스트
        variant="success" // 원하는 variant
        SubmitButton={true}
      />
      <CheckOutModal
        show={modalErrorShow}
        onHide={() => setModalErrorShow(false)}
        onConfirm={() => setModalErrorShow(false)}
        title="경고"
        // body={`에러코드 : ${ErrorCode}, 에러 메시지 : ${ErrorMsg}`}
        body={`${ErrorMsg}`}
        cancelText="취소"
        confirmText="확인"
        variant="warning"

        SubmitButton={true}
      />
    </AdvanceTableProvider >

  );
}

const encryptPublicKey = () => {
  return (
    <div className="container mt-5">
      <Card className="mypage-card">
        <Card.Header as="h4">공유키</Card.Header>
        <Card.Body>
          <AdvanceTableExample />
        </Card.Body>
      </Card>
    </div>
  );
};

export default encryptPublicKey;
