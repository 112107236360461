import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import { Card, Spinner } from 'react-bootstrap';
import { timeDistribution } from 'hooks/CallApi'; // API 함수 import
import { formatCountStyle3 } from 'components/common/SunnyUtils';

const SiteAverageApiProcess = () => {
  const chartRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState({ days: [], series: [], legends: [] });

  // 데이터 가져오기
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const response = await timeDistribution({ timeZone: clientTimeZone, type: 'api' });
        // console.log("[timeDistribution]데이터 확인용 ", response);
        const apiData = response.data;

        // 날짜 데이터 생성
        const days = apiData[0]?.topTwoHourlyData.map((entry) => {
          const date = new Date(entry.date);
          return `${date.getMonth() + 1}/${date.getDate()} ${date.toLocaleDateString('en-US', { weekday: 'short' })}`;
        });

        // 데이터 변환

        const twoHourGroups = Array.from({ length: 12 }, (_, i) =>
          `${String(i * 2).padStart(2, '0')}:00-${String(i * 2 + 1).padStart(2, '0')}:59`
        );
        const transformData = (company) => {
          const { companyName, topHourlyData } = company;
          const data = [];
          topHourlyData.forEach((dayData, dayIndex) => {
            dayData.hourlyData.forEach((hourEntry) => {
              const hour = parseInt(hourEntry.hourRange, 10);
              data.push([dayIndex, hour, hourEntry.processedDataSize]);
            });
          });
          return {
            name: companyName,
            type: 'scatter',
            data,
            symbolSize: (val) => Math.max(3, (val[2] / 1e9) * (20 / 50)),
            itemStyle: {
              color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
            },
          };
        };

        const series = apiData.map(transformData);
        const legends = apiData.map((company) => company.companyName);

        setChartData({ days, series, legends, rawData: apiData });
      } catch (error) {
        console.error("API 데이터 호출 실패:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // 차트 렌더링
  useEffect(() => {
    if (isLoading || !chartRef.current) return;

    const chart = echarts.init(chartRef.current);
    const { days, series, legends, rawData } = chartData;

    const twoHourGroups = Array.from({ length: 12 }, (_, i) =>
      `${String(i * 2).padStart(2, '0')}:00-${String(i * 2 + 1).padStart(2, '0')}:59`
    );
    const option = {
      tooltip: {
        trigger: 'item',
        formatter: (params) => {
          const data = params.data;
          if (!data) return '';

          const dayIndex = data[0]; // X축 인덱스
          const hour = data[1] * 2; // Y축 시간대 (2시간 그룹 기준)
          const sum = data[2]; // 데이터 값
          const companyName = params.seriesName;

          // 원본 데이터에서 해당 회사와 날짜의 topHourlyData 가져오기
          const selectedCompany = rawData.find((company) => company.companyName === companyName);
          const selectedDayHourlyData = selectedCompany?.topHourlyData[dayIndex]?.hourlyData;

          // Top 5 리스트 생성
          const top_five_list = selectedDayHourlyData
            ?.slice(0, 5)
            .map((entry, index) => {
              const hour = entry.hourRange.padStart(2, '0');
              const value = formatCountStyle3(entry.processedDataSize);
              return `${index + 1}. ${hour}:00 - ${value}`;
            })
            .join('<br/>') || '데이터 없음';

          const daySum = selectedDayHourlyData
            ?.reduce((total, entry) => total + entry.processedDataSize, 0) || 0;

          // 날짜와 시간 계산
          const day = days[dayIndex]; // 날짜
          const hourDisplay = `${hour.toString().padStart(2, '0')}:00`; // Y축 시간대 첫 번째 시간 표시

          return `
            ${companyName}<br/>
            ${day} ${hourDisplay}<br/>
            현재 시간의 데이터량: ${formatCountStyle3(sum)}<br/>
            하루의 총 데이터량: ${formatCountStyle3(daySum)}<br/>
            <strong>Top 5:</strong><br/>
            ${top_five_list}
          `;
        },
      },


      legend: {
        data: legends,
        bottom: '2%',
      },
      grid: {
        left: '5%',
        right: '5%',
        bottom: '15%',
        top: '5%',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        data: days,
        name: '날짜',
        axisLabel: {
          interval: 0,
        },
      },
      yAxis: {
        type: 'category',
        data: twoHourGroups,
        name: '시간대',
        splitLine: {
          show: true,
          lineStyle: {
            type: 'dashed',
          },
        },
      },
      series: series.map((seriesItem) => ({
        ...seriesItem,
        data: seriesItem.data.map((item) => {
          const [dayIndex, hour, value] = item;
          const hourGroupIndex = Math.floor(hour / 2); // 2시간 그룹의 인덱스 계산
          return [dayIndex, hourGroupIndex, value]; // Y축과 일치시키기 위해 hour 대신 hourGroupIndex 사용
        }),
      })),
    };

    chart.setOption(option);

    const resizeChart = () => chart.resize();
    window.addEventListener('resize', resizeChart);

    return () => {
      window.removeEventListener('resize', resizeChart);
      chart.dispose();
    };
  }, [chartData, isLoading]);

  return (
    <Card>
      <Card.Header className="pb-0 d-flex align-items-center">
        <h5 style={{ margin: 0 }}>일별 API 최대 처리량 시간 분포</h5>
        <span
          style={{
            marginLeft: '8px',
            marginTop: 0,
            marginBottom: 0,
            padding: '2px 8px',
            backgroundColor: '#A1C4E3',
            color: 'white',
            borderRadius: '4px',
            fontSize: '12px',
            fontWeight: 'bold',
          }}
        >
          최근 1주일
        </span>
      </Card.Header>
      <Card.Body>
        {isLoading ? (
          <div
            style={{
              width: '100%',
              height: '15rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div ref={chartRef} style={{ width: '100%', height: '20rem' }} />
        )}
      </Card.Body>
    </Card>
  );
};

export default SiteAverageApiProcess;
