import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from 'components/common/IconButton';
import { useState } from 'react';

const SelectForm = ({ register, errors, watch, onSelect, selectedButton, setSelectedButton, selected, setSelected }) => {
  // const [selected, setSelected] = useState(null); // 선택된 div의 id를 관리

  const handleSelect = (id) => {
    if (selected === id) {
      // 이미 선택된 버튼을 다시 클릭하면 선택 해제
      setSelected(null);
      setSelectedButton(null);
      onSelect(null); // 선택 해제 시 부모 컴포넌트에 null 전달
      return;
    }

    setSelected(id); // 선택된 div가 같지 않을 경우에만 선택 
    setSelectedButton(id);

    onSelect(id); // 선택된 버튼 ID를 부모 컴포넌트로 전달
  };

  return (
    <>
      <div
        id="test"
        style={{
          border: `2px solid ${selected === 'test' ? 'blue' : 'lightblue'}`, // 선택 시 테두리 색상 변경
          borderRadius: '5px',
          margin: '1rem',
          width: '100%',
          // backgroundColor: selected === 'test' ? '#e7f3ff' : 'transparent', // 선택 시 배경색 변경
          backgroundColor: '#c5bebe',
          transition: 'background-color 0.3s, border-color 0.3s', // 부드러운 효과 추가
          display: 'none'

        }}
      >
        <div style={{ padding: '1rem', marginBottom: '1rem' }}>
          <div style={{ textAlign: 'center', fontSize: '24px', whiteSpace: 'break-spaces' }}>
            <strong>
              <b>API</b> 서비스
              <br></br>
              <span style={{ color: 'blue' }}>체험</span> 신청하기
            </strong>
          </div>
          <div style={{ textAlign: 'center', fontSize: '16px' }}>
            고객사(이용기관)의 업무시스템을 SafeDB Cloud에 연동하여 <br />암복호화 서비스 체험 및 테스트를 진행할 수 있습니다.
          </div>
        </div>
        <div
          style={{
            // backgroundColor: '#f1eefb', 
            backgroundColor: '#c5bebe',
            padding: '1rem'
          }}>
          <div style={{ whiteSpace: 'break-spaces' }}>
            {/* <FontAwesomeIcon icon='thumbs-up' /> */}
            담당자의 신청으로 사용가능
            <br />
            - 계약과 무관하게 신청가능
            <br />
            - 심사 없이 담당자 신청으로 진행
          </div>
          <hr />
          <div style={{ whiteSpace: 'break-spaces' }}>
            2주간 무료로 이용가능
            <br />
            - 1일 100건 2주간 제공
            <br />
            - '정책 키 관리 서비스' 이용가능
            <br />
            - '암호화' 및 '복호화' 서비스 이용 가능
          </div>
          <hr />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton
              variant={selectedButton === 'test' ? 'primary' : 'secondary'}
              type="button"
              transform="down-1 shrink-4"
              onClick={() => handleSelect('test')} // test 버튼 클릭 시 handleSelect 호출
              disabled
              style={{
                backgroundColor: selectedButton === 'test' ? '#004085' : '#007bff', // 선택 시 버튼 배경색 변경
                color: 'white', // 버튼 텍스트 색상
                border: 'none', // 테두리 제거
                transition: 'background-color 0.3s' // 부드러운 효과 추가
              }}
            >
              선택
            </IconButton>
          </div>
        </div>
      </div >

      <div
        id="service"
        style={{
          border: `2px solid ${selected === 'service' ? 'blue' : 'lightblue'}`, // 선택 시 테두리 색상 변경
          borderRadius: '5px',
          margin: '1rem',
          width: '100%',
          backgroundColor: selected === 'service' ? '#e7f3ff' : 'transparent', // 선택 시 배경색 변경
          transition: 'background-color 0.3s, border-color 0.3s', // 부드러운 효과 추가
          display: ''
        }}
      >
        <div style={{ padding: '1rem', marginBottom: '1rem' }}>
          <div style={{ textAlign: 'center', fontSize: '24px', whiteSpace: 'break-spaces' }}>
            <strong>
              <b>API</b> 서비스
              <br></br>
              <span style={{ color: 'blue' }}>연동</span> 신청하기
            </strong>
          </div>
          <div style={{ textAlign: 'center', fontSize: '16px' }}>
            고객사(이용기관)가 SafeDB Cloud 서비스를 이용하기 위해 <br />계약 신청 절차가 진행됩니다.
          </div>
        </div>
        <div style={{ backgroundColor: '#f1eefb', padding: '1rem' }}>
          <div style={{ whiteSpace: 'break-spaces' }}>
            {/* <FontAwesomeIcon icon='thumbs-up' /> */}
            간편하게 신청
            <br />
            - 간단한 양식 작성으로 신청 가능
            <br />
            - 서비스 신청 후 담당자 배정
          </div>
          <hr />
          <div style={{ whiteSpace: 'break-spaces' }}>
            고객사 맞춤 멤버쉽 계약 진행
            <br />
            - 요금제 선택 가능
            <br />
            - 상세 내용은 담당자가 안내
            <br />
            - 서비스 개시까지 상담 및 컨설팅 지원
          </div>
          <hr />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton
              variant={selectedButton === 'service' ? 'primary' : 'secondary'}
              type="button"
              transform="down-1 shrink-4"

              onClick={() => handleSelect('service')} // service 버튼 클릭 시 handleSelect 호출
              style={{
                backgroundColor: selectedButton === 'service' ? '#004085' : '#007bff', // 선택 시 버튼 배경색 변경
                color: 'white', // 버튼 텍스트 색상
                border: 'none', // 테두리 제거
                transition: 'background-color 0.3s' // 부드러운 효과 추가
              }}
            >
              선택
            </IconButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectForm;
