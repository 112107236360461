import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { Button } from 'react-bootstrap';
import Flex from '../Flex';
import { useAdvanceTableContext } from 'providers/AdvanceTableProvider';
import "safedb/common/css/IniCustomCss.css";
import { faAngleLeft, faAngleRight, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

export const AdvanceTablePagination = () => {
  const {
    previousPage,
    nextPage,
    getCanNextPage,
    getCanPreviousPage,
    getState,
    getPageCount,
    setPageIndex
  } = useAdvanceTableContext();

  const {
    pagination: { pageIndex }
  } = getState();

  const totalPages = getPageCount();
  const pagesPerGroup = 5; // 한 그룹에 표시할 페이지 수
  const currentGroup = Math.floor(pageIndex / pagesPerGroup); // 현재 그룹 계산

  const renderPageNumbers = () => {
    const pageNumbers = [];
    // const pagesPerGroup = 5; // 한 그룹에 표시할 페이지 수
    const startPage = currentGroup * pagesPerGroup + 1; // 그룹의 시작 페이지
    const endPage = Math.min(startPage + pagesPerGroup - 1, totalPages); // 그룹의 끝 페이지
    // let startPage, endPage;

    // // 선택한 페이지에 따라 시작 페이지와 끝 페이지 계산
    // if (pageIndex + 1 <= 1) {
    //   startPage = 1;
    //   endPage = Math.min(pagesPerGroup, totalPages);
    // } else if (pageIndex + 1 > totalPages - 4) {
    //   startPage = totalPages - 4;
    //   if (startPage < 1) startPage = 1;
    //   endPage = totalPages;
    // } else {
    //   // 중간 페이지인 경우
    //   startPage = pageIndex + 1;
    //   endPage = pageIndex + 5;
    // }

    // if (startPage > 1) {
    //   pageNumbers.push(
    //     <li key="dots-left" className="me-2">...</li>
    //   );
    // }

    //   // 페이지 번호 렌더링
    //   for (let i = startPage; i <= endPage; i++) {
    //     if (i <= totalPages) {
    //       pageNumbers.push(
    //         <li key={i} className={classNames({ active: pageIndex + 1 === i })}>
    //           <Button
    //             size="sm"
    //             variant="light"

    //             className={classNames('page', {
    //               'me-2': i < totalPages
    //             })}
    //             onClick={() => setPageIndex(i - 1)}
    //           >

    //             {i}
    //           </Button>
    //         </li >
    //       );
    //     }
    //   }

    //   // '...' 표시 추가 (끝 부분)
    //   if (endPage < totalPages) {
    //     pageNumbers.push(
    //       <li key="dots-right" className="me-2">...</li>
    //     );
    //   }

    //   return pageNumbers;
    // };
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i} className={classNames({ active: pageIndex + 1 === i })}>
          <Button
            size="sm"
            variant="light"
            className={classNames('page', {
              'me-2': i < totalPages
            })}
            onClick={() => setPageIndex(i - 1)}
          >
            {i}
          </Button>
        </li>
      );
    }

    return pageNumbers;
  };
  return (
    <Flex alignItems="center" justifyContent="center" className="pagination-container">
      {/* 첫 페이지로 이동 */}
      {currentGroup > 0 && (
        <Button
          size="sm"
          variant="falcon-default"
          onClick={() => setPageIndex(0)}
          className="me-2"
        >
          <FontAwesomeIcon icon={faAngleDoubleLeft} />
        </Button>
      )}

      {/* 이전 그룹으로 이동 */}
      {currentGroup > 0 && (
        <Button
          size="sm"
          variant="falcon-default"
          onClick={() => setPageIndex(Math.max(0, currentGroup * pagesPerGroup - pagesPerGroup))}
          className="me-3"
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </Button>
      )}

      {/* 페이지 번호 */}
      <ul className="pagination mb-0 mx-2" style={{ minWidth: '10px', display: 'inline-flex' }}>
        {renderPageNumbers()}
      </ul>

      {/* 다음 그룹으로 이동 */}
      {currentGroup < Math.floor((totalPages - 1) / pagesPerGroup) && (
        <Button
          size="sm"
          variant="falcon-default"
          onClick={() => setPageIndex(Math.min(totalPages - 1, (currentGroup + 1) * pagesPerGroup))}
          className="ms-3"
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </Button>
      )}

      {/* 마지막 페이지로 이동 */}
      {currentGroup < Math.floor((totalPages - 1) / pagesPerGroup) && (
        <Button
          size="sm"
          variant="falcon-default"
          onClick={() => setPageIndex(totalPages - 1)}
          className="ms-2"
        >
          <FontAwesomeIcon icon={faAngleDoubleRight} />
        </Button>
      )}
    </Flex>
  );
};

export default AdvanceTablePagination;
