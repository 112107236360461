import React, { useEffect, useState } from 'react';
import { Card, Button, Form, Row, Col } from 'react-bootstrap';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { Korean } from 'flatpickr/dist/l10n/ko.js';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import data from 'safedb/SA/sampledata/sampledataLogAdmin';
import CheckOutModal from 'components/modal/CheckOutModal';

const columns = [
  { accessorKey: 'num', header: '순번', meta: { headerProps: { className: 'text-900' } } },
  { accessorKey: 'jobdate', header: '작업일시', meta: { maxlength: 19, headerProps: { className: 'text-900' } } },
  { accessorKey: 'org', header: '소속', meta: { maxlength: 15, headerProps: { className: 'text-900' } } },
  { accessorKey: 'role', header: '역할', meta: { maxlength: 7, headerProps: { className: 'text-900' } } },
  { accessorKey: 'uid', header: '아이디', meta: { maxlength: 20, headerProps: { className: 'text-900' } } },
  { accessorKey: 'job', header: '작업내역', meta: { maxlength: 20, headerProps: { className: 'text-900' } } },
  { accessorKey: 'result', header: '결과', meta: { headerProps: { className: 'text-900' } } }

];

function AdvanceTableExample() {
  const [tableData, setTableData] = useState(data);
  const [filteredData, setFilteredData] = useState(data);
  const [requestDateRange, setRequestDateRange] = useState([]);
  const [policyId, setPolicyId] = useState('');
  const [org, setOrg] = useState('');
  const [role, setRole] = useState('');
  const [uid, setUid] = useState('');
  const [job, setJob] = useState('');
  const [result, setResult] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalReShow, setModalReShow] = useState(false);

  useEffect(() => {
    const sortedData = [...data].reverse();
    setTableData(sortedData);
    setFilteredData(sortedData);
  }, []);
  const table = useAdvanceTable({
    data: filteredData,
    columns,
    sortable: true,
    selection: false,
    pagination: true,
    perPage: 10,
    selectionColumnWidth: 30
  });


  const handleSearch = () => {
    const [startDate, endDate] = requestDateRange;
    const newData = data.filter(row => {
      const jobDate = new Date(row.jobdate);
      const matchesDate = requestDateRange.length === 2 ? jobDate >= startDate && jobDate <= endDate : true;
      const matchesOrg = org ? row.org === org : true;
      const matchesRole = role ? row.role === role : true;
      const matchesUid = uid ? row.uid.includes(uid) : true;
      const matchesJob = job ? row.job.includes(job) : true;
      const matchesResult = result ? row.result === result : true;
      return matchesDate && matchesOrg && matchesRole && matchesUid && matchesJob && matchesResult;
    });
    setFilteredData(newData.reverse());
  };

  const handleDefault = () => {
    setRequestDateRange([]);
    setOrg('');
    setRole('');
    setUid('');
    setJob('');
    setResult('');
    const datepicker_start = document.querySelector('#timepicker_start')._flatpickr;
    if (datepicker_start) {
      datepicker_start.clear();  // 기존 날짜 선택 제거
      datepicker_start.setDate([]);  // 날짜 범위 초기화
    }
    const datepicker_end = document.querySelector('#timepicker_end')._flatpickr;
    if (datepicker_end) {
      datepicker_end.clear();  // 기존 날짜 선택 제거
      datepicker_end.setDate([]);  // 날짜 범위 초기화
    }
  };

  useEffect(() => {
    const todayStart = new Date();
    todayStart.setHours(0, 0, 0, 0); // 오늘 00시 00분 00초

    const todayEnd = new Date();
    todayEnd.setHours(23, 59, 59, 999); // 오늘 23시 59분 59초

    flatpickr('#timepicker_start', {
      mode: 'single',
      dateFormat: 'Y-m-d H:i',
      enableTime: true,
      time_24hr: true,
      disableMobile: true,
      position: 'below',
      locale: Korean,
      minDate: new Date().fp_incr(-180),
      // defaultDate: todayStart,
      // onChange: (selectedDates) => setRequestDateRange(selectedDates)
      onChange: (selectedDates) => {
        console.log("Start Date Selected:", selectedDates[0]);
        setRequestDateRange((prev) => [selectedDates[0], prev[1]]);
      },
    });

    flatpickr('#timepicker_end', {
      mode: 'single',
      dateFormat: 'Y-m-d H:i',
      enableTime: true,
      time_24hr: true,
      disableMobile: true,
      position: 'below',
      locale: Korean,
      minDate: new Date().fp_incr(-180),
      // defaultDate: todayEnd,
      // onChange: (selectedDates) => setRequestDateRange(selectedDates)
      onChange: (selectedDates) => {
        console.log("END Date Selected:", selectedDates[0]);
        setRequestDateRange((prev) => [selectedDates[0], prev[0]]);
      },
    });
  }, []);
  return (
    <AdvanceTableProvider {...table}>
      <div className="d-flex flex-column flex-md-row align-items-center mb-1" style={{ gap: '3px' }}>
        {/* 전체 레코드 수와 페이지네이션 셀렉트 */}
        <div className="d-flex align-items-center">

          <AdvanceTableFooter rowsPerPageSelection />
        </div>


        {/* 검색 필터 */}
        <Row className="w-100 align-items-center">
          <Col xxl={2} xl={2} className="mb-4 ps-4 fs-10">
            <label htmlFor="timepicker_start" className="form-label">&nbsp;</label>
            <input
              id="timepicker_start"
              className="form-control datetimepicker"
              type="text"
              title="작업일시"
              placeholder="2024-01-01 00:00"
              style={{ height: '29px', fontSize: '1rem' }}
            />
          </Col>
          <Col xxl={2} xl={2} className="mb-4 ps-4 fs-10">
            <label htmlFor="timepicker_end" className="form-label"> &nbsp;</label>

            <input
              id="timepicker_end"
              className="form-control datetimepicker"
              type="text"
              placeholder="2024-12-31 00:00"
              title="작업일시"
              style={{ height: '29px', fontSize: '1rem' }}
            />
          </Col>
          <Col xxl={1} xl={2} className="mb-4 fs-10">
            <Form.Label>&nbsp;</Form.Label>
            <Form.Select size="sm" value={org} title="소속" onChange={e => setOrg(e.target.value)} style={{ height: '29px' }}>
              <option value="" disabled hidden >소속</option>
              {[...new Set(data.map(item => item.org))].map((org, index) => (
                <option key={index} value={org}>{org}</option>
              ))}
            </Form.Select>
          </Col>
          <Col xxl={1} xl={2} className="mb-4 fs-10 ">
            <Form.Label>&nbsp;</Form.Label>
            <Form.Select size="sm" value={role} title="역할" onChange={e => setRole(e.target.value)} style={{ height: '29px' }}>
              <option value="" disabled hidden >역할</option>
              {[...new Set(data.map(item => item.role))].map((role, index) => (
                <option key={index} value={role}>{role}</option>
              ))}
            </Form.Select>
          </Col>
          <Col xxl={2} xl={2} className="mb-4 fs-10">
            <Form.Label>&nbsp;</Form.Label>
            {/* <Form.Select size="sm" value={uid} onChange={e => setUid(e.target.value)} style={{ height: '29px' }}>
              <option value="" ></option>
              {[...new Set(data.map(item => item.uid))].map((uid, index) => (
                <option key={index} value={uid}>{uid}</option>
              ))}
            </Form.Select> */}
            <Form.Control
              size="sm"
              type="text"
              value={uid}
              placeholder="아이디"
              title="아이디"
              onChange={e => setUid(e.target.value)}
              style={{ height: '29px' }}
            />
          </Col>
          <Col xxl={3} xl={2} className="mb-4 fs-10">
            <Form.Label>&nbsp;</Form.Label>
            {/* <Form.Select size="sm" value={job} onChange={e => setJob(e.target.value)} style={{ height: '29px' }}>
              <option value="" ></option>
              {[...new Set(data.map(item => item.job))].map((job, index) => (
                <option key={index} value={job}>{job}</option>
              ))}
            </Form.Select> */}
            <Form.Control
              size="sm"
              type="text"
              value={job}
              placeholder="작업내역"
              title="작업내역"
              onChange={e => setJob(e.target.value)}
              style={{ height: '29px' }}
            />
          </Col>
          {/* <Col xs={3} md={1} className="mb-4 fs-10">
            <Form.Label>결과</Form.Label>
            <Form.Select size="sm" value={result} onChange={e => setResult(e.target.value)} style={{ height: '29px' }}>
              <option value="" ></option>
              {[...new Set(data.map(item => item.result))].map((result, index) => (
                <option key={index} value={result}>{result}</option>
              ))}
            </Form.Select>
          </Col> */}
        </Row>

        {/* 구분선 */}



        <div className="d-flex justify-content-end align-items-center" style={{ gap: '0.2rem' }}>

          <Button variant="outline-secondary" onClick={handleDefault} className="me-2" style={{ whiteSpace: 'nowrap', width: '5rem' }} title="초기화" >초기화</Button>

          <Button variant="outline-secondary" onClick={handleSearch} className="me-2" style={{ whiteSpace: 'nowrap', width: '4rem' }}>검색</Button>
          <Button variant="outline-secondary" style={{ whiteSpace: 'nowrap', width: '4rem' }}>CSV</Button>
        </div>
      </div>

      <AdvanceTable
        headerClassName="bg-200 text-nowrap align-middle"
        rowClassName="align-middle white-space-nowrap"
        tableProps={{ striped: true, className: 'fs-10 mb-0 overflow-hidden' }}
      />
      <p className="mb-0" style={{ fontWeight: 700, lineHeight: '29px' }}>총 {data.length}건</p>
      <AdvanceTablePagination />

    </AdvanceTableProvider>
  );
}

const logAdmin = () => (
  <div className="container mt-5">
    <Card className="mypage-card">
      <Card.Header as="h4">관리활동로그</Card.Header>
      <Card.Body>
        <AdvanceTableExample />
      </Card.Body>
    </Card>
  </div>
);

export default logAdmin;
