import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { Button } from 'react-bootstrap';
import Flex from '../Flex';
import "safedb/common/css/IniCustomCss.css";
import { faAngleLeft, faAngleRight, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
export const AdvanceTablePagination = ({
  currentPage, // 현재 페이지 (0부터 시작)
  totalItems, // 전체 항목 수
  itemsPerPage, // 한 페이지당 항목 수
  onChange, // 페이지 변경 시 호출될 함수
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage); // 총 페이지 수 계산
  const pagesPerGroup = 5; // 한 그룹에 표시할 페이지 수

  // 현재 그룹 계산
  const currentGroup = Math.floor(currentPage / pagesPerGroup);

  // 페이지 번호 렌더링 함수
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const pagesPerGroup = 5; // 한 그룹에 표시할 페이지 수
    // let startPage, endPage;
    const startPage = currentGroup * pagesPerGroup + 1; // 그룹의 시작 페이지
    const endPage = Math.min(startPage + pagesPerGroup - 1, totalPages); // 그룹의 마지막 페이지


    // // 현재 페이지를 기준으로 시작 페이지와 끝 페이지 계산
    // if (currentPage + 1 <= pagesPerGroup) {
    //   startPage = 1;
    //   endPage = Math.min(pagesPerGroup, totalPages);
    // } else if (currentPage + 1 > totalPages - Math.floor(pagesPerGroup / 2)) {
    //   startPage = Math.max(1, totalPages - pagesPerGroup + 1);
    //   endPage = totalPages;
    // } else {
    //   startPage = currentPage - Math.floor(pagesPerGroup / 2) + 1;
    //   endPage = currentPage + Math.floor(pagesPerGroup / 2);
    // }

    // // 왼쪽 "..." 표시
    // if (startPage > 1) {
    //   pageNumbers.push(
    //     <li key="dots-left" className="me-2">...</li>
    //   );
    // }

    // 페이지 번호 렌더링
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={classNames({ active: currentPage + 1 === i })}

        >
          <Button
            size="sm"
            variant="light"
            className={classNames('page', {
              'me-2': i < totalPages
            })}
            onClick={() => onChange(i - 1)} // 부모에게 새로운 페이지 전달
          >
            {i}
          </Button>
        </li>
      );
    }

    // 오른쪽 "..." 표시
    // if (endPage < totalPages) {
    //   pageNumbers.push(
    //     <li key="dots-right" className="me-2">...</li>
    //   );
    // }

    return pageNumbers;
  };

  return (
    <Flex alignItems="center" justifyContent="center" className="pagination-container">
      {/* 이전 페이지 버튼 */}
      {/* <Button
        size="sm"
        variant="falcon-default"
        onClick={() => {
          currentPage > 0 && onChange(currentPage - 1);
        }} className={classNames({ disabled: currentPage <= 0 })}
      >
        <FontAwesomeIcon icon="chevron-left" />
      </Button> */}

      {currentGroup > 0 && ( // 맨 앞일 때 숨김 처리
        <Button
          size="sm"
          variant="falcon-default"
          onClick={() => onChange(0)} // 첫 페이지로 이동
          className="me-2" // 버튼 간격 추가
        >
          <FontAwesomeIcon icon={faAngleDoubleLeft} />
        </Button>
      )}

      {currentGroup > 0 && ( // 첫 그룹일 때 숨김 처리
        <Button
          size="sm"
          variant="falcon-default"
          onClick={() => {
            const newPage = Math.max(0, currentGroup * pagesPerGroup - pagesPerGroup);
            onChange(newPage);
          }}
          className="me-3" // 버튼 간격 추가
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </Button>
      )}

      {/* 페이지 번호 */}
      <ul className="pagination mb-0 mx-2" style={{ minWidth: '10px', display: 'inline-flex' }}>
        {renderPageNumbers()}
      </ul>

      {/* 다음 페이지 버튼 */}
      {/* <Button
        size="sm"
        variant="falcon-default"
        onClick={() => {
          currentPage < totalPages - 1 && onChange(currentPage + 1);
        }}
        className={classNames({ disabled: currentPage >= totalPages - 1 })}
      >
        <FontAwesomeIcon icon="chevron-right" />
      </Button> */}
      {currentGroup < Math.floor((totalPages - 1) / pagesPerGroup) && ( // 마지막 그룹일 때 숨김 처리
        <Button
          size="sm"
          variant="falcon-default"
          onClick={() => {
            const newPage = Math.min(
              totalPages - 1,
              (currentGroup + 1) * pagesPerGroup
            );
            onChange(newPage);
          }}
          className="ms-3" // 버튼 간격 추가
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </Button>
      )}
      {currentGroup < Math.floor((totalPages - 1) / pagesPerGroup) && ( // 맨 뒤일 때 숨김 처리
        <Button
          size="sm"
          variant="falcon-default"
          onClick={() => onChange(totalPages - 1)} // 마지막 페이지로 이동
          className="ms-2" // 버튼 간격 추가
        >
          <FontAwesomeIcon icon={faAngleDoubleRight} />
        </Button>
      )}
    </Flex >
  );
};

export default AdvanceTablePagination;
