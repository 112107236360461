import React, { useState, useEffect } from 'react';
import { Form, Button, InputGroup, FormControl, Card, Row, Col, Modal } from 'react-bootstrap';
import IconAlert from 'components/common/IconAlert';
import MiniIconAlert from 'components/common/MiniIconAlert';
import AdminPasswordAlertModal from 'components/modal/AdminPasswordAlertModal';
import NewPasswordModal from 'components/modal/NewPasswordModal';
import ChangePasswordSuccessModal from 'components/modal/ChangePasswordSuccessModal';
import MainTenancePasswordModal from 'components/modal/MainTenancePasswordModal';
import CheckOutModal from 'components/modal/CheckOutModal';
import { useAppContext } from 'providers/AppProvider';
import { changePassword, changePassword_forAdmin } from 'hooks/CallApi';
import { toast } from 'react-toastify';

const ChangePasswordModal = ({ show, onHide, id, name, message, flag, uuid }) => {
  const {
    config: { firstLogin, userId, userAuth }
  } = useAppContext();

  const [formData, setFormData] = useState({
    id: id || userId, // 부모로 전달된 id가 있으면 사용, 없으면 userId 사용
    password: '',
    newPassword: '',
    confirmPassword: '',
    name: name || '-',
    lastPwChange: ''
  });

  useEffect(() => {
    if (id) {
      setFormData((prevData) => ({
        ...prevData,
        id, // 전달된 id로 업데이트
      }));
    }
  }, [id]);

  useEffect(() => {
    console.log("자식 컴포넌트에서 받은 show 값:", show);
    console.log("부모로부터 받은 id : ", id);

  }, [show]);
  useEffect(() => {
    console.log("부모로부터 받은 id : ", id);
    if (show) {
      setFormData({
        id: id || userId, // 전달된 id로 설정, 없으면 빈 문자열
        password: '',
        newPassword: '',
        confirmPassword: '',
        name: name || '-', // 전달된 name으로 설정, 없으면 기본값
        lastPwChange: ''
      });
      setPasswordError(''); // 비밀번호 에러 초기화
      setShowError(false); // 에러 메시지 표시 상태 초기화
      setErrorMsg(''); // 에러 메시지 내용 초기화
    }
  }, [show, id, name]);

  const [modalShow, setModalShow] = useState(false); // 관리자 비밀번호 모달 상태
  const [CancelmodalShow, setCancelModalShow] = useState(false); // 관리자 비밀번호 모달 상태
  const [newPasswordModalShow, setNewPasswordModalShow] = useState(false); // 새로운 비밀번호 확인 모달 상태
  const [successModalShow, setSuccessModalShow] = useState(false); // 비밀번호 변경 성공 모달 상태
  const [maintenanceModalShow, setMaintenanceModalShow] = useState(false); // 유지 모달 상태
  const [passwordError, setPasswordError] = useState(''); // 비번관련 에러 처리용
  const [showError, setShowError] = useState(false); // 에러 메시지 표시 상태
  const [errorMsg, setErrorMsg] = useState(''); // 에러 메시지 내용

  const targetDate = new Date('2024-10-14T00:00:00');
  const currentDate = new Date();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });

    // const passwordPattern = /^(?=.*[a-z]{1,})(?=.*[A-Z]{1,})(?=.*\d{1,})(?=.*[!@#$%^_\-\+=\{\}\[\]:;"'?.,/~]{1,})[a-zA-Z\d!@#$%^_\-\+=\{\}\[\]:;"'?.,/~]+$/;
    const passwordPattern = /^(?=(?:[^a-zA-Z]*[a-zA-Z]){3})(?=.*\d)(?=.*[!@#$%^_\-\+=\{\}\[\]:;"'?.,/~])[a-zA-Z\d!@#$%^_\-\+=\{\}\[\]:;"'?.,/~]{9,16}$/;
    // 영문 최소 3개 숫자 , 특문 최소 1개, 길이는 9~16자, 특수문자 가능 문자 -> (! @ # $ % ^ _ - + = { } [ ] : ; " ' ? , . / ~)

    if (name === 'newPassword') {
      const allowedSpecialChars = "! @ # $ % ^ _ - + = { } [ ] : ; \" ' ? , . / ~";
      if (!passwordPattern.test(value)) {
        setPasswordError(
          `비밀번호는 영문 대문자, 소문자, 숫자, 다음 허용된 특수문자 중 하나 이상을 포함해야 합니다: ${allowedSpecialChars}`

        );
      } else {
        setPasswordError('');
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowError(false); // 이전 에러 메시지 초기화

    if (passwordError) {
      setErrorMsg('신규 비밀번호가 비밀번호 정책에 위반됩니다.');
      setShowError(true);
      return;
    }

    if (formData.newPassword !== formData.confirmPassword) {
      setErrorMsg('신규 비밀번호가 일치하지 않습니다.');
      setShowError(true);
      return;
    }
    setNewPasswordModalShow(true);
    // 10.30 보류요청으로 바로 변경 처리 진행으로
    // if (userAuth === 'SA') {
    //   setModalShow(true); // 관리자 비밀번호 확인 모달을 띄움
    // } else if (formData.id === 'user') {
    //   setNewPasswordModalShow(true); // 일반 사용자일 경우 바로 새로운 비밀번호 확인 모달 띄움
    // }
  };

  const handleAdminPasswordConfirm = () => {
    setModalShow(false); // 관리자 모달 닫기
    setNewPasswordModalShow(true); // 새로운 비밀번호 확인 모달 띄우기
  };

  const handleNewPasswordConfirm = async () => {

    // 비밀번호 변경 처리 
    try {
      // changePassword API 호출을 위한 데이터 준비
      const data = {
        newPassword: formData.newPassword,
      };
      let response;

      if (flag) {
        response = await changePassword_forAdmin(uuid, data);
      } else {
        response = await changePassword(data);
      }

      if (response === 204) { // 응답 성공 여부 확인
        setNewPasswordModalShow(false); // 새로운 비밀번호 확인 모달 닫기
        setSuccessModalShow(true); // 비밀번호 변경 성공 모달 띄우기
      } else {
        throw new Error(response.message || "비밀번호 변경 실패");
      }
    } catch (error) {
      // 비밀번호 변경 실패 시 에러 메시지 표시
      setShowError(true);
      setErrorMsg(response.message || "비밀번호 변경 중 서버에 오류가 발생했습니다.");


    }
  };

  const handleMaintenanceConfirm = () => {
    setMaintenanceModalShow(false); // 유지 모달 닫기
    setSuccessModalShow(true); // 유지 모달 닫은 후 성공 모달 띄우기
  };

  const handleCancelConfirm = () => {
    setCancelModalShow(false); // 취소 확인 모달 닫기
    onHide(); // ChangePasswordModal도 닫기
  };

  const handleClearConfirm = () => {
    setSuccessModalShow(false);
    onHide();
  }
  const isFormValid = formData.password && formData.newPassword && formData.confirmPassword && !passwordError && !errorMsg;

  return (
    <Modal show={show} onHide={onHide} size="xl" centered>
      <Modal.Header closeButton>
        <Modal.Title>비밀번호 변경</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message && ( // message가 존재할 때만 렌더링
          <IconAlert variant="info">
            <p>{message}</p>
          </IconAlert>
        )}
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>아이디</Form.Label>
                <Form.Control
                  type="text"
                  name="id"
                  value={formData.id}
                  readOnly
                  disabled
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>이름</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  readOnly
                  disabled
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  현재 비밀번호<span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <InputGroup>
                  <FormControl
                    placeholder="현재 비밀번호를 입력해 주세요."
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  신규 비밀번호 <span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <InputGroup>
                  <FormControl
                    placeholder="새로운 비밀번호를 입력해 주세요."
                    type="password"
                    name="newPassword"
                    value={formData.newPassword}
                    onChange={handleChange}
                  />
                </InputGroup>
                {passwordError && (
                  <p style={{ color: 'red', fontSize: '0.8rem' }}>{passwordError}</p>
                )}
                <p style={{ fontSize: '0.8rem' }}>
                  비밀번호는 최소 [9]자 이상 ~ [16]자 이하의 영문 문자 [3]개와
                  숫자[1], 다음 특수문자 중 [1]자 이상을 포함해야 합니다.
                  <br />
                  ! @ # $ % ^ _ - + = { } [ ] : ; " ' ? , . / ~
                </p>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  비밀번호 확인 <span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <InputGroup>
                  <FormControl
                    placeholder="확인을 위해 비밀번호를 다시 입력해 주세요."
                    type="password"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                  />
                </InputGroup>
              </Form.Group>

              {showError && (
                <MiniIconAlert
                  dismissible
                  variant="warning"
                  onClose={() => setShowError(false)}
                >
                  {errorMsg}
                </MiniIconAlert>
              )}
            </Col>
          </Row>
          <div className="d-flex justify-content-between mt-3">
            <Button

              variant="secondary"
              style={{

                backgroundColor: '#b1b1b1',
                borderRadius: '10px',
                padding: '5px 10px',
                fontWeight: 'bold',
                fontSize: '0.9rem',
                // visibility: currentDate > targetDate ? 'visible' : 'hidden'
                visibility: 'hidden' // 해당 버튼 보류로 안보이게 처리 24.11.01
              }}
              onClick={() => setMaintenanceModalShow(true)}
            >
              N 일간 이전 비밀번호 유지
            </Button>
            <div className="d-flex align-items-center">
              <Button variant="outline-secondary" className="me-2" onClick={() => setCancelModalShow(true)} disabled={firstLogin === 'first'}>
                취소
              </Button>
              <Button variant="primary" type="submit" disabled={!isFormValid}>
                변경
              </Button>
            </div>
          </div>
        </Form>
      </Modal.Body>

      {/* 취소 모달 */}
      <CheckOutModal
        show={CancelmodalShow}
        onHide={() => setCancelModalShow(false)}
        onConfirm={handleCancelConfirm} // 취소 모달 확인 시 ChangePasswordModal도 닫기
        title="확인"

        body="변경을 취소하시겠습니까?"
        // cancelText="취소"
        variant="info"
        confirmText="취소"
        // variant="info"
        // CancelButton={true}
        SubmitButton={true}
      />

      {/* 관리자 비밀번호 모달 */}
      <AdminPasswordAlertModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onConfirm={handleAdminPasswordConfirm}
      />

      {/* 새로운 비밀번호 확인 모달 */}
      <NewPasswordModal
        show={newPasswordModalShow}
        onHide={() => setNewPasswordModalShow(false)}
        onConfirm={handleNewPasswordConfirm}
      />

      {/* 비밀번호 변경 성공 모달 */}
      {/* <ChangePasswordSuccessModal
        show={successModalShow}
        onHide={handleClearConfirm}
      /> */}
      <CheckOutModal
        show={successModalShow}
        onHide={() => setSuccessModalShow(false)}
        onConfirm={() => {
          setSuccessModalShow(false);
          onHide();
        }}
        title="완료"
        body="비밀번호 변경이 완료되었습니다."
        // cancelText="취소"
        variant="success"
        confirmText="확인"
        // variant="info"
        // CancelButton={true}
        SubmitButton={true}
      />
      {/* N일간 유지 모달 */}
      <MainTenancePasswordModal
        show={maintenanceModalShow}
        onHide={() => setMaintenanceModalShow(false)}
        onConfirm={handleMaintenanceConfirm}
      />
    </Modal>
  );
};

export default ChangePasswordModal;


