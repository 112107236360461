import React, { useState, useEffect } from 'react';
import { Form, Button, Card, Row, Col } from 'react-bootstrap';
import avatarImg from 'assets/img/team/avatar.png';
import { useAuthWizardContext } from 'providers/AuthWizardProvider';
import Flex from 'components/common/Flex';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import "safedb/common/css/IniCustomCss.css"
import CheckOutModal from 'components/modal/CheckOutModal';
import { AiFillCloseCircle } from 'react-icons/ai';
import { handleValueDelete, goToParentPath } from 'components/common/SunnyUtils';
import { useAppContext } from 'providers/AppProvider';
import { toast } from 'react-toastify';
import GroupAddModal from 'components/modal/GroupAddModal'; // 경로에 맞게 수정
import { SelectGroupList, addApiKey, fetchCompanyInfo } from 'hooks/CallApi'; // API 함수 임포트
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretSquareLeft } from '@fortawesome/free-solid-svg-icons';


const addapikey = ({ setValue, handleClose }) => {
    const navigate = useNavigate();
    const {
        config: { userAuth_K, userId, checkorg },
        setConfig
    } = useAppContext();
    const [modalShow, setModalShow] = useState(false); // 관리자 비밀번호 모달 상태
    const [showGroupModal, setShowGroupModal] = useState(false); // 모달 상태 추가
    const [showGroupAddModal, setShowGroupAddModal] = useState(false); // 모달 상태 추가
    const [groups, setGroups] = useState([]);
    const [isArrowHovered, setIsArrowHovered] = useState(false);

    const location = useLocation();
    const { keycompanyId } = location.state || {}; // 전달된 state에서 keycompanyId 읽기



    // API 호출 후 데이터 확인
    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const response = await SelectGroupList();
                console.log(response); // API 응답 데이터 확인
                setGroups(response.data); // 데이터가 response.data에 있을 경우
            } catch (error) {
                console.error('그룹 목록 불러오기 실패:', error);
            }
        };
        fetchGroups();
    }, []);

    const handleGroupModalShow = () => {
        setShowGroupModal(true); // 모달 열기
    };

    const handleGroupModalHide = () => {
        setShowGroupModal(false); // 모달 닫기
    };

    const handleCheckOutModal = async () => {
        // 추가 API 호출
        try {
            const response = await addApiKey({
                apikeyName: formData.keyname,
                apikeyType: formData.role,
                issuerId: formData.adderId,
                issuerRole: formData.adderRole,
                apiGroupName: formData.group,
                apiGroupUuid: formData.apiGroupUuid,
                apikeyDescription: formData.description
            });
            console.log("response : ", response);
            if (response) {

                setModalShow(false); // 관리자 모달 닫기  

                navigate(-1);
                toast.success('정상 등록되었습니다.');
            }
        } catch (error) {
            console.error('API KEY 등록 에러:', error);
            toast.error('API KEY  생성 중 오류가 발생했습니다.');
        }


    };
    const handleGroupModalConfirm = () => {
        setShowGroupModal(false);
        setShowGroupAddModal(true);
    };
    const handleGroupAddModalShow = () => {
        setShowGroupAddModal(true); // 모달 열기
    };

    const handleGroupAddModalHide = () => {
        setShowGroupAddModal(false); // 모달 닫기
    };
    const handleGroupAddModalConfirm = () => {
        setShowGroupAddModal(false);

    };

    const [formData, setFormData] = useState({
        group: '',
        invalidDate: '',
        keyname: '',
        role: 'API',
        description: '',
        adderRole: '',
        adderId: '',
        apiGroupUuid: '',
        keycompanyId: keycompanyId,
        imgUrl: ''
    });
    // 회사이미지 불러오기용 
    useEffect(() => {
        console.log('키 아이디?', formData.keycompanyId);

        const loadCompanyInfo = async () => {
            try {
                const response = await fetchCompanyInfo(formData.keycompanyId);
                const data = response.data;

                setFormData({
                    imgUrl: data.imgUrl
                });
            } catch (error) {
                console.error('회사 정보 불러오기 에러:', error);
            }
        };

        if (formData.keycompanyId) {
            loadCompanyInfo();
        } else {
            toast.error('유효하지 않은 회사 ID입니다.');
        }
    }, []);
    const [groupAddFormData, setGroupAddFormData] = useState({
        group: ''
    });

    const { user } = useAuthWizardContext();
    const [avatar, setAvatar] = useState([{ src: "" }]); // 초기값 설정
    // 초기 상태 설정
    useEffect(() => {
        const initialAvatar = formData.imgUrl && formData.imgUrl.trim() !== ""
            ? formData.imgUrl
            : avatarImg;

        // setavatarImg(initialAvatar); // 기본 이미지 설정
        setAvatar([{ src: initialAvatar }]); // Avatar 상태 업데이트
    }, [formData.imgUrl]); // imgUrl이나 userAuth 변경 시 실행


    useEffect(() => {
        // 컴포넌트가 처음 렌더링될 때 formData 초기화
        setFormData((prev) => ({
            ...prev,
            adderRole: userAuth_K, // config에서 가져온 값
            adderId: userId, // config에서 가져온 값
        }));
    }, [userAuth_K, userId]); // 의존성 배열에 userAuth와 userId 추가

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(() => {
        // formData가 업데이트될 때마다 콘솔에 출력
        console.log("Updated formData:", formData);
    }, [formData]);

    const handleComboChange = (e) => {
        const selectedGroupName = e.target.value;

        // 선택된 그룹의 UUID 찾기
        const selectedGroup = groups.find(group => group.groupName === selectedGroupName);
        const apigroupuuid = selectedGroup ? selectedGroup.apigroupId.apigroupUuid : '';

        setFormData(prevFormData => ({
            ...prevFormData,
            group: selectedGroupName,
            apiGroupUuid: apigroupuuid  // 선택된 그룹의 UUID 추가
        }));

        console.log("Selected Group Name:", selectedGroupName);
        console.log("Selected Group UUID:", apigroupuuid);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setModalShow(true);

    };

    const isFormValid = () => {
        const { group, keyname } = formData;
        return group && keyname;
    };


    const [buttonStyle, setButtonStyle] = useState({
        position: 'absolute',
        top: '45%',
        width: '6rem',
        fontSize: '0.8rem'
    });

    useEffect(() => {
        const updateButtonStyle = () => {
            if (window.innerWidth <= 1536) {
                setButtonStyle({
                    ...buttonStyle,
                    position: 'absolute',
                    top: '43%',
                    right: '34%',
                    width: '4rem',
                    fontSize: '0.8rem'
                });
            } else {
                setButtonStyle({
                    position: 'absolute',
                    top: '45%',
                    width: '6rem',
                    fontSize: '0.8rem'
                });
            }
        };

        updateButtonStyle(); // 초기 실행
        window.addEventListener('resize', updateButtonStyle); // 화면 크기 변경 이벤트 리스너 추가

        return () => window.removeEventListener('resize', updateButtonStyle); // 클린업
    }, []);


    return (
        <div className="container mt-5">
            <Card className="mypage-card" >
                <Card.Header className="px-3" as="h4">
                    <Link to={goToParentPath()}
                        style={{ textDecoration: 'none', color: 'inherit', }}
                        onMouseEnter={() => setIsArrowHovered(true)}
                        onMouseLeave={() => setIsArrowHovered(false)} >
                        <FontAwesomeIcon
                            icon={faCaretSquareLeft}
                            className="me-2"
                            style={
                                isArrowHovered
                                    ? {} // Hover 시 스타일 제거
                                    : { color: "#869fca" } // 기본 스타일
                            }
                        />
                    </Link>
                    인증키</Card.Header>
                <Card.Title className="border border-bottom-0 px-3 pt-3">

                    등록</Card.Title>
                <Card.Body className="border border-top-0">
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col md={4} className="text-center profile-section">
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '150px',
                                        height: '150px',
                                        position: 'relative',
                                        margin: '0 auto',
                                    }}
                                >
                                    <Flex justifyContent="center" >
                                        <img
                                            src={avatar.length > 0 ? avatar[0].src : avatar[0].src}
                                            alt=""
                                            style={{
                                                borderRadius: '50%', // 동그란 형태
                                                width: '150px', // 원하는 너비
                                                height: '150px', // 원하는 높이
                                                objectFit: 'contain', // 이미지 비율 유지 
                                            }}
                                        />
                                    </Flex>
                                </div>
                                <p className="mt-3">[{checkorg}]</p> {/* @@ 여기 @@*/}
                            </Col>



                            {/* Right Column (ID, Password, Name) */}
                            <Col md={8}>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" style={{ position: 'relative' }}>
                                            <Form.Label>발급자 역할</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="adderRole"
                                                value={formData.adderRole}
                                                disabled
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>

                                        <Form.Group className="mb-3" style={{ position: 'relative' }}>
                                            <Form.Label>발급자 아이디</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="adderId"
                                                value={formData.adderId}
                                                disabled
                                            />

                                        </Form.Group>
                                    </Col>
                                </Row>


                                <Row>
                                    <Col md={5}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>인증키 그룹<span style={{ color: 'red' }}>*</span></Form.Label>
                                            <Form.Select
                                                name="group"
                                                onChange={handleComboChange}
                                                placeholder="그룹을 선택해주세요."
                                                value={formData.group}
                                            >
                                                <option value="" >그룹 선택</option>
                                                {groups.map((group) => (
                                                    <option key={group.apigroupId.apigroupUuid} value={group.groupName}>{group.groupName}</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>

                                    <Col md={1} className="d-flex align-items-end">
                                        <Button
                                            variant="primary"
                                            style={buttonStyle}
                                            onClick={handleGroupModalShow}
                                        >그룹 등록</Button>
                                    </Col>

                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>만료일시<span style={{ color: 'red' }}>*</span></Form.Label>
                                            <Form.Select
                                                name="invalidDate"
                                                onChange={handleComboChange}
                                                disabled
                                            >
                                                <option value="nonInvalid">무제한</option>
                                                <option value="30days">30일</option>
                                                <option value="1year">1년</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" style={{ position: 'relative' }}>
                                            <Form.Label>인증키 이름<span style={{ color: 'red' }}>*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="keyname"
                                                value={formData.keyname}
                                                onChange={handleChange}
                                                className="input-hover"

                                                placeholder="인증키 이름을 입력해 주세요."
                                            />

                                            {formData.keyname && (
                                                <span
                                                    onClick={(e) => handleValueDelete('keyname', formData, setFormData)}
                                                    style={{
                                                        cursor: 'pointer',
                                                        position: 'absolute',
                                                        right: '10px',
                                                        top: '50%',
                                                        transform: 'transformY(-50%)',
                                                        zIndex: 9
                                                    }}
                                                >
                                                    <AiFillCloseCircle />
                                                </span>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>용도<span style={{ color: 'red' }}>*</span></Form.Label>
                                            <Form.Select
                                                name="role"
                                                onChange={handleComboChange}
                                                // disabled
                                                value={formData.role}

                                            >
                                                <option value="API">API</option>
                                                <option value="CF">CF</option>
                                                <option value="SDK">SDK</option>

                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Form.Group className="mb-3" style={{ position: 'relative' }}>
                                    <Form.Label>설명</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="description"
                                        rows={1}
                                        value={formData.description}
                                        onChange={handleChange}
                                        className="input-hover"
                                        placeholder="키의 용도, 사용할 부서 또는 시스템을 입력할 수 있습니다. (예: 마케팅 부서, pod-001 복호화 전용)"
                                    />

                                    {formData.description && (
                                        <span
                                            onClick={(e) => handleValueDelete('description', formData, setFormData)}
                                            style={{
                                                cursor: 'pointer',
                                                position: 'absolute',
                                                right: '10px',
                                                top: '50%',
                                                transform: 'transformY(-50%)',
                                                zIndex: 9
                                            }}
                                        >
                                            <AiFillCloseCircle />
                                        </span>
                                    )}
                                </Form.Group>

                            </Col>
                        </Row>

                        {/* Cancel and Confirm Buttons */}
                        <div className="d-flex justify-content-end mt-3">
                            <Button variant="secondary" className="me-2" style={{ width: '7rem' }} onClick={() => window.history.back()}>
                                취소
                            </Button>
                            <Button variant="primary" type="submit" style={{ width: '7rem' }} disabled={!isFormValid()}>
                                등록
                            </Button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>

            <CheckOutModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                onConfirm={handleCheckOutModal}
                title="확인" // 제목
                body="인증키를 등록하시겠습니까?" // 본문 내용
                cancelText="취소" // 취소 버튼 텍스트
                confirmText="확인" // 확인 버튼 텍스트
                variant="info" // 원하는 variant
                SubmitButton={true}
            />
            <GroupAddModal
                show={showGroupModal}
                onHide={handleGroupModalHide}
                onConfirm={handleGroupModalConfirm}
            // formData={formData}
            // setFormData={setFormData}
            />

        </div >


    );
};

export default addapikey;
