import React, { useEffect, useState } from 'react';
import Flex from 'components/common/Flex';
import { useAppContext } from 'providers/AppProvider';
import { Card, Button, ButtonGroup, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import { anomaliesRank } from 'hooks/CallApi';
import { formatCountStyle3 } from 'components/common/SunnyUtils';

const SiteIssueRank = () => {
  const {
    config: { dashboardDate, dashboardDateValue, Chart_startdate, Chart_enddate },
  } = useAppContext();

  const [view, setView] = useState('cf'); // 'cf', 'api', 'sdk'
  const [isLoading, setIsLoading] = useState(true);
  const [rankData, setRankData] = useState({
    failCompanies: [],
    idleKeys: [],
  });

  const [dateRange, setDateRange] = useState({
    start: Chart_startdate,
    end: Chart_enddate
  });
  useEffect(() => {
    // Chart_startdate 또는 Chart_enddate가 변경될 때 상태 업데이트
    setDateRange({
      start: Chart_startdate,
      end: Chart_enddate
    });
  }, [Chart_startdate, Chart_enddate]);

  useEffect(() => {
    const fetchRankData = async () => {
      setIsLoading(true);
      try {
        const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const response = await anomaliesRank({
          period: dashboardDateValue, // 기간 설정 (day, week, month)
          start: dateRange.start,
          end: dateRange.end,
          timeZone: clientTimeZone,
          type: view, // 'cf', 'api', 'sdk'
        });


        // 데이터 가공
        setRankData({
          failCompanies: [
            {
              name: response.data.top1FailCompany,
              value: response.data.top1FailPer || 0,
              dataSize: response.data.top1FailDataSize || 0 // 추가된 필드
            },
            {
              name: response.data.top2FailCompany,
              value: response.data.top2FailPer || 0,
              dataSize: response.data.top2FailDataSize || 0 // 추가된 필드
            },
            {
              name: response.data.top3FailCompany,
              value: response.data.top3FailPer || 0,
              dataSize: response.data.top3FailDataSize || 0 // 추가된 필드
            },
          ],

          idleKeys: [
            { name: response.data.top1NotUsePoliComp, value: response.data.top1NotUseCnt || 0 },
            { name: response.data.top2NotUsePoliComp, value: response.data.top2NotUseCnt || 0 },
            { name: response.data.top3NotUsePoliComp, value: response.data.top3NotUseCnt || 0 },
            // ].filter((item) => !(item.name === null && item.value === 0)), // 이름이 null이고 값이 0인 항목만 제외
          ]
        });
      } catch (error) {
        console.error('고객사 이상 순위 데이터 조회 실패:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRankData();
  }, [view, dashboardDateValue, dateRange]);

  return (
    <Card className="h-md-100">
      <Card.Header className="pb-0 d-flex align-items-center">
        <h5 style={{ margin: 0 }}>고객사 이상 순위</h5>
        <span
          style={{
            marginLeft: '8px',
            marginTop: 0,
            marginBottom: 0,
            padding: '2px 8px',
            backgroundColor: '#A1C4E3',
            color: 'white',
            borderRadius: '4px',
            fontSize: '12px',
            fontWeight: 'bold',
          }}
        >
          {dashboardDate}
        </span>
      </Card.Header>
      <Card.Body alignItems="center" justifyContent="space-between">
        <ButtonGroup
          className="mb-3"
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            zIndex: 10,
          }}
        >
          <Button
            style={{ fontSize: '0.7rem' }}
            variant={view === 'cf' ? 'primary' : 'outline-primary'}
            onClick={() => setView('cf')}
          >
            CF
          </Button>
          <Button
            style={{ fontSize: '0.7rem' }}
            variant={view === 'api' ? 'primary' : 'outline-primary'}
            onClick={() => setView('api')}
          >
            API
          </Button>
          <Button
            style={{ fontSize: '0.7rem' }}
            variant={view === 'sdk' ? 'primary' : 'outline-primary'}
            onClick={() => setView('sdk')}
            disabled
          >
            SDK
          </Button>
        </ButtonGroup>
        {isLoading ? (
          <div
            style={{
              width: '100%',
              height: '15rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <RateTop3Section>
            {/* 실패 처리량 섹션 */}
            <GridSection>
              <h6>실패 처리량</h6>
              {rankData.failCompanies.map((company, index) => (
                <Cell
                  key={index}
                  style={{
                    visibility: company.name ? 'visible' : 'hidden', // 이름이 없으면 보이지 않게 설정
                  }}
                >
                  <div>{company.name}</div> {/* 회사명 */}
                  <div>{formatCountStyle3(company.dataSize)}  ({company.value}%)</div>

                </Cell>
              ))}
            </GridSection>

            {/* 유휴 암호키 수 섹션 */}
            <GridSection>
              <h6>유휴 암호키 수</h6>
              {rankData.idleKeys.map((company, index) => (
                <Cell
                  key={index}
                  style={{
                    visibility: company.name ? 'visible' : 'hidden', // 이름이 없으면 보이지 않게 설정
                  }}
                >
                  <div>{company.name}</div> {/* 회사명 */}
                  <div>{company.value}개</div> {/* 데이터 */}
                </Cell>
              ))}
            </GridSection>
          </RateTop3Section>
        )}
      </Card.Body>
    </Card>
  );
};

const RateTop3Section = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  width: 100%;
`;

const GridSection = styled.div`
  display: grid;
  gap: 10px; /* 행 간 간격 */
  font-size: 16px;
  align-items: start; /* 데이터를 위로 정렬 */
  height: 100%; /* 모든 섹션 높이를 동일하게 유지 */
`;

// const Cell = styled.div`
//   text-align: left;
//   border: 1px solid #ddd;
//   padding: 10px;
//   border-radius: 5px;
// `;

const Cell = styled.div`
  display: flex;
  flex-direction: row; /* 가로 배치 */
  justify-content: space-between; /* 양 끝 정렬 */
  align-items: center; /* 수직 정렬 */
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  min-height: 60px; /* 다른 셀과 동일한 최소 높이 */
  gap: 10px;
`;


export default SiteIssueRank;
